<template>
  <v-container class="summary-content content-area pa-0">
    <div class="d-flex">
      <MailformSummaryCategories
        :category-infos="categoryInfos"
        :current-category-index="currentCategoryIndex"
        @change-active-category="$emit('changeActiveCategory', $event)"
      />

      <MailformSummaryReports
        :current-category-name="currentCategoryName"
        :report-list="reportList"
        :has-reports="hasReports"
        :emc-error-reports="emcErrorReports"
        :message="message"
        :message-type="messageType"
      />
    </div>
  </v-container>
</template>

<script>
import MailformSummaryCategories from "@/components/MailformSummary/organisms/MailformSummaryCategories.vue"
import MailformSummaryReports from "@/components/MailformSummary/organisms/MailformSummaryReports.vue"

export default {
  name: "MailformSummaryTemplate",
  components: {
    MailformSummaryCategories,
    MailformSummaryReports,
  },

  props: {
    categoryInfos: {
      type: Array[Object],
      default: [],
    },
    currentCategoryIndex: {
      type: String,
      default: "0",
    },
    currentCategoryName: {
      type: String,
      default: "",
    },
    reportList: {
      type: Array[Object],
      default: [],
    },
    hasReports: {
      type: Boolean,
      default: true,
    },
    emcErrorReports: {
      type: Array[Object],
      default: {},
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String,
      default: "",
    },
  },
  date() {
    return {}
  },
  emits: ["changeActiveCategory"],
  computed: {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.summary-content {
  overflow: auto;
  height: calc(100vh - 150px);
}
</style>
