<template>
  <CommonHeader hide-reload hide-login-menu />
  <ErrorContent :title="title" :message="message" :show-button="showButton" />
</template>

<script>
import CommonHeader from "@/components/common/organisms/CommonHeader.vue"
import ErrorContent from "../organisms/ErrorContent.vue"

export default {
  name: "MainError",
  components: {
    CommonHeader,
    ErrorContent,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
