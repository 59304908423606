import { defineStore } from "pinia"
import { storageKey } from "@/stores/storageKey"

const id = "common.login" // idはpinia(システム全体)で一意にする
//defineStore() を使ってストアを定義し、名前を付けてエクスポートすることで.vueから利用可能
//名前は use{Xxxx}Store が慣例 ※{Xxxx}はidと同一にすると管理が楽
export const useCommonLoginStore = defineStore(id, {
  id: id,
  // stateでデータ定義する（初期値もここで設定している）
  state: () => ({
    authorities: {
      kind: 0, // ユーザー権限種別（0：一般ユーザ、1：管理ユーザ）
      hasUpdateMailformBaseDateTime: 0, // レポーティング機能の基準日時変更権限有無（1：権限あり）
    },
  }),
  // gettersはstateの値を算出した値を取得するゲッター（算出プロパティ）
  getters: {
    isAdmin: state => state.authorities.kind == 1,
    canUpdateMailformBaseDateTime: state => state.authorities.hasUpdateMailformBaseDateTime == 1,
  },
  // actionsはstateの値にデータを設定するセッターとして利用する
  // 実態はAPIのmethods オプションに相当するためビジネスロジック的な処理を記述可能
  actions: {
    setKind(value) {
      this.authorities.kind = value
    },
    setAuthUpdateMailformBaseDateTime(value) {
      this.authorities.hasUpdateMailformBaseDateTime = value
    },
    initAuthorities() {
      this.setKind(0)
      this.setAuthUpdateMailformBaseDateTime(0)
    },
  },
  // 永続化
  persist: {
    storage: sessionStorage,
    key: `${storageKey}-${id}`,
  },
})
