<template>
  <v-chip
    :active="isShow"
    variant="outlined"
    class="ma-1 chip-group"
    :color="color"
    :closable="closable"
    :disabled="disabled"
    :small="small"
    @click:close="$emit('close', itemKey)"
    @click.stop="$emit('click', itemKey)"
  >
    {{ text }}
    <template #append>
      <slot name="append"></slot>
    </template>
  </v-chip>
</template>

<script>
export default {
  name: "BaseChip",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    text: {
      type: String,
      default: "",
    },
    itemKey: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: [Object, Boolean],
      default: false,
    },
  },
  emits: ["close", "click", "append"],
  data() {
    return {
      isShow: true,
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.isShow = value
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.chip-group {
  height: auto;
  min-height: 32px;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: pre-wrap;
  word-break: break-all;
  border-radius: 16px;
}
</style>
