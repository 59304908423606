<template>
  <v-main>
    <MainMailformReports
      v-model:current-page="paginate.page"
      :auto-reload-flag="autoReloadFlag"
      :auto-reload-interval="autoReloadInterval"
      :headers="reportHeaders"
      :dataset="reports"
      :title="reportTitle"
      :datetime="baseDatetime"
      :menus="categories"
      :page-obj="paginate"
      :damaged-count="damagedCount"
      :no-damage-count="noDamageCount"
      :no-report-count="noReportCount"
      :filtering-master-data="filteringMasterData"
      :is-loading="isLoading"
      :message="message"
      :message-type="messageType"
      :has-graph-error="hasGraphError"
      @reload="reload"
      @auto-reload="autoReload"
      @choose-report="chooseReport"
      @show-damage-condition="showDamageCondition"
      @execute-filtering="executeFiltering"
      @remove-filter="removeFilter"
      @refresh-filtering-master-data="fetchReportFilteringMaster"
      @sorting-by="sortingBy"
      @change-page="changePage"
      @change-per-page="changePerPage"
      @search-by-damage-condition="searchByDamageCondition"
      @updated-base-datetime="updatedBaseDatetime"
      @updated-before-base-datetime-renewed="updatedBeforeBaseDatetimeRenewed"
      @csv-download="csvDownload"
    />

    <!-- 基準日時更新検出確認ダイアログ  -->
    <Dialog
      ref="renewBaseDatetimeConfirmDialog"
      :title="$t('parts.dialog.title.confirmation')"
      :cancel-btn-label="$t('parts.dialog.button.cancel')"
      :okay-btn-label="$t('parts.dialog.button.ok')"
      @before-okay-event="reload(false)"
    >
      <BaseLabel>
        {{ renewBaseDatetimeConfirmDialog.message.body }}
      </BaseLabel>
      <BaseLabel>
        {{ renewBaseDatetimeConfirmDialog.message.prompt }}
      </BaseLabel>
    </Dialog>

    <!-- 基準日時更新検出通知ダイアログ  -->
    <Dialog
      ref="renewBaseDatetimeInformationDialog"
      :title="$t('parts.dialog.title.announcement')"
      :okay-btn-label="false"
    >
      <BaseLabel>
        {{ $t("form.mailformReports.dialog.renewBaseDatetimeInformation.message.body") }}
      </BaseLabel>
    </Dialog>
  </v-main>
</template>

<script>
import { defineComponent } from "vue"
import MainMailformReports from "@/components/MailformReports/templates/MainMailformReports"
import client from "@/api/client"
import { useCommonStore } from "@/stores/common"
import { useMailformReportsStore } from "@/stores/mailform/reports"
import { saveAs } from "file-saver"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import Dialog from "@/components/common/molecules/BaseDialog.vue"

export default defineComponent({
  name: "MailformReports",
  components: {
    MainMailformReports,
    BaseLabel,
    Dialog,
  },

  emits: ["reportSelection"],

  setup() {
    const commonStore = useCommonStore() // 共通storeを利用したpiniaの定義
    const reportsStore = useMailformReportsStore() // レポート画面storeを利用したpiniaの定義

    return {
      commonStore,
      reportsStore,
    }
  },
  data() {
    return {
      autoReloadFlag: false,
      autoReloadInterval: 1, // 単位(分) 更新時間はシステムで1分固定
      autoReloadTimer: 0, // ページを移動した際に削除するため更新タイマーを宣言
      baseDatetime: "",
      reportTitle: "",
      paginate: {
        page: 1,
        firstShownCount: 0,
        lastShownCount: 0,
        totalCount: 0,
        totalPage: 1,
      },
      categories: [],

      reportHeaders: [],
      reports: [],

      chooseDamageCondition: "", // 「1:被害あり,2:被害なし,3:確認中」
      filteringMasterData: [], // 絞り込み条件の選択元となるマスター情報

      damagedCount: 0,
      noDamageCount: 0,
      noReportCount: 0,

      isLoading: false,

      // 仕様上画面表示メッセージはAPI毎に別管理だが、現段階では明細取得APIの結果しか表示しないため以下の変数で管理する
      message: "",
      messageType: "",

      hasGraphError: false, // グラフの表示エラー状態
      csvDownloadMode: "csvDownload",
      renewBaseDatetimeConfirmDialog: {
        message: {
          body: "",
          prompt: "",
        },
      },
    }
  },
  computed: {
    limit: {
      get() {
        return this.commonStore.getPerPage
      },
    },
    offset: {
      get() {
        return (this.paginate.page - 1) * this.commonStore.getPerPage
      },
    },
  },
  watch: {
    $route() {
      // 同画面(同path)遷移を検出し、再読み込みを行う
      location.reload()
    },
  },
  /**
   *  初期表示
   */
  async created() {
    // レポート絞り込み条件マスター情報を取得する
    await this.fetchReportFilteringMaster(this.$route.params.reportAccountName)

    if (this.reportsStore.getParamsDamageConditionGraphPoint) {
      // 遷移元からの遷移パラメータ（検索条件）が指定されている場合、他の検索条件をクリアして指定された被害判定の値を設定する
      this.refreshFilterByDamageCondition(this.reportsStore.getParamsDamageConditionGraphPoint)
      // 利用済みの遷移元からの遷移パラメータをpiniaから削除する
      this.reportsStore.clearTransitionParams()
    } else {
      // 絞り込み条件を初期化する
      this.resetFilteringConditionIfReportSwitch()
    }

    // ソートを初期化する
    this.resetSortableIfReportSwitch()
    // ページ数を初期化する
    this.resetPage()
    // 表示件数を初期化する⇒(表示件数sessionStorage保持化のためここで初期化してはいけない)
    // this.resetPerPage()

    let hasRefreshCategories = true // カテゴリ一覧を最新化するか
    let hasRefreshReportTitle = true // タイトルを最新化するか
    let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
    let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
    let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
    let hasInitSelectedShowItems = this.reportsStore.isReportSwitched(this.$route.params.reportAccountName) // レポートが切り替わった時に、表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
    let isCheckRenewedBaseDatetime = false // 初期表示の場合、元となる基準日時が存在しないため、基準日時の変更検知は行わない

    // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
    this.fetchReportMaster(
      this.$route.params.reportAccountName,
      hasRefreshCategories,
      hasRefreshReportTitle,
      hasIfSucceededFetchDetail,
      this.fetchReportDetails,
      hasRefreshDamageCondition,
      hasRefreshShowItems,
      hasInitSelectedShowItems,
      isCheckRenewedBaseDatetime
    )
  },
  unmounted() {
    // ページを移動した際（コンポーネントインスタンスがアンマウントされた際）に更新タイマーを削除
    clearInterval(this.autoReloadTimer)
  },

  methods: {
    resetPage() {
      const defaultPage = 1 // TODO 今後定数等の仕組みにしたい
      this.paginate.page = defaultPage
    },
    resetPerPage() {
      this.commonStore.initPerPage()
    },
    resetSortableIfReportSwitch() {
      if (this.reportsStore.isReportSwitched(this.$route.params.reportAccountName)) {
        this.reportsStore.initSortable()
      }
    },
    resetSortable() {
      this.reportsStore.initSortable()
    },
    resetFilteringConditionIfReportSwitch() {
      // レポートが切り替わった時に、絞り込み条件を初期化する（絞り込み条件セッション化対応）
      if (this.reportsStore.isReportSwitched(this.$route.params.reportAccountName)) {
        this.reportsStore.initFilteringCondition()
      }
    },
    resetFilteringCondition() {
      // 絞り込み条件を初期化する（絞り込み条件セッション化対応）
      this.reportsStore.initFilteringCondition()
    },
    resetSelectedShowItemsIfReportSwitch() {
      // レポートが切り替わった時に、表示項目の選択値を初期化する（表示項目選択値セッション化対応）
      if (this.reportsStore.isReportSwitched(this.$route.params.reportAccountName)) {
        this.reportsStore.refreshSelectedShowItems(
          this.reportHeaders.map(reportHeader => {
            return reportHeader.key
          })
        )
        this.reportsStore.setShowAll(true)
      }
    },
    resetSelectedShowItems() {
      // 表示項目の選択値を初期化する（表示項目選択値セッション化対応）
      this.reportsStore.refreshSelectedShowItems(
        this.reportHeaders.map(reportHeader => {
          return reportHeader.key
        })
      )
      this.reportsStore.setShowAll(true)
    },

    /**
     * 基準日時ダイアログで変更完了後処理
     */
    updatedBaseDatetime() {
      // 絞り込み条件を初期化する
      this.resetFilteringConditionIfReportSwitch()
      // ソートを初期化する
      this.resetSortable()
      // ページ数を初期化する
      this.resetPage()
      // 表示件数を初期化しない

      let hasRefreshCategories = false // カテゴリ一覧を最新化するか
      let hasRefreshReportTitle = false // タイトルを最新化するか
      let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
      let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
      let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
      let hasInitSelectedShowItems = false // 表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
      let isCheckRenewedBaseDatetime = false // 基準日時変更直後の場合、自身の更新した値が最新となるため、基準日時の変更検知は行わない

      // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
      this.fetchReportMaster(
        this.$route.params.reportAccountName,
        hasRefreshCategories,
        hasRefreshReportTitle,
        hasIfSucceededFetchDetail,
        this.fetchReportDetails,
        hasRefreshDamageCondition,
        hasRefreshShowItems,
        hasInitSelectedShowItems,
        isCheckRenewedBaseDatetime
      )
    },
    /**
     * 基準日時変更ダイアログを開いたときに基準日時が更新されていた場合の処理
     */
    updatedBeforeBaseDatetimeRenewed() {
      this.renewBaseDatetimeConfirmDialog.message.body = this.$i18n.t(
        "form.mailformReports.dialog.renewBaseDatetimeConfirmation.message.bodyToBaseDatetime"
      )
      this.renewBaseDatetimeConfirmDialog.message.prompt = this.$i18n.t(
        `form.mailformReports.dialog.renewBaseDatetimeConfirmation.message.promptToBaseDatetime`
      )
      this.$refs.renewBaseDatetimeConfirmDialog.open()
    },
    /**
     * ページ切替時
     */
    changePage() {
      // ページ数を初期化しない（入力された値を利用する）
      // 表示件数を初期化しない
      // ソートを初期化しない

      let hasRefreshCategories = false // カテゴリ一覧を最新化するか
      let hasRefreshReportTitle = false // タイトルを最新化するか
      let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
      let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
      let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
      let hasInitSelectedShowItems = false // 表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
      let isCheckRenewedBaseDatetime = true // 基準日時の変更検知を行う

      // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
      this.fetchReportMaster(
        this.$route.params.reportAccountName,
        hasRefreshCategories,
        hasRefreshReportTitle,
        hasIfSucceededFetchDetail,
        this.fetchReportDetails,
        hasRefreshDamageCondition,
        hasRefreshShowItems,
        hasInitSelectedShowItems,
        isCheckRenewedBaseDatetime
      )
    },

    /**
     * 表示件数切替時
     */
    changePerPage() {
      // ページ数を初期化する
      this.resetPage()
      // 表示件数を初期化しない（入力された値を利用する）
      // ソート初期化しない
      // 選択された表示項目を初期化しない

      let hasRefreshCategories = false // カテゴリ一覧を最新化するか
      let hasRefreshReportTitle = false // タイトルを最新化するか
      let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
      let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
      let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
      let hasInitSelectedShowItems = false // 表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
      let isCheckRenewedBaseDatetime = true // 基準日時の変更検知を行う

      // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
      this.fetchReportMaster(
        this.$route.params.reportAccountName,
        hasRefreshCategories,
        hasRefreshReportTitle,
        hasIfSucceededFetchDetail,
        this.fetchReportDetails,
        hasRefreshDamageCondition,
        hasRefreshShowItems,
        hasInitSelectedShowItems,
        isCheckRenewedBaseDatetime
      )
    },
    /**
     * レポート画面のマスター情報を取得する.
     * （マスター情報とは明細ページングに紐づかない情報）
     *
     * @param {String} reportAccountName レポートの識別子
     * @param {Boolean} hasRefreshCategories カテゴリ一覧をAPI返却値で最新化する場合true
     * @param {Boolean} hasRefreshReportTitle レポートタイトルをAPI返却値で最新化する場合true
     * @param {Boolean} hasIfSucceededFetchDetail マスター情報の取得に成功した場合に、明細を取得する場合true
     * @param {Function} funcFetchDetail 明細情報取得API関数
     * @param {array} fetchDetailArgs 明細情報取得API関数の引数
     */
    fetchReportMaster(
      reportAccountName,
      hasRefreshCategories = true,
      hasRefreshReportTitle = true,
      hasIfSucceededFetchDetail = false,
      funcFetchDetail = null,
      ...fetchDetailArgs
    ) {
      if (hasRefreshCategories || hasRefreshReportTitle) {
        // マスター情報取得APIの戻り値を必要とする場合、マスター情報取得APIを呼び出す
        let pathParams = { report_account_name: reportAccountName }
        client
          .mailformReportMaster(pathParams)
          .then(data => {
            // 本APIの基準日時はFunctions化前の値のため正しくない、コメントアウトコードはAPIのI/Fの修正まで残しておく（APIのI/Fから削除後にこのコードも削除する）
            // 基準日時を設定
            // this.baseDatetime = data.data.baseDatetime

            // カテゴリ一覧の設定
            if (hasRefreshCategories) {
              this.categories = []
              data.data.category?.forEach(category => {
                let reports = []
                category.report?.forEach(report => {
                  reports.push({ title: report.reportTitle, account: report.reportAccountName })
                })
                this.categories.push({
                  id: category.categoryId,
                  title: category.categoryName,
                  reports: reports,
                })
              })
            }

            // レポートタイトルの設定
            if (hasRefreshReportTitle) {
              this.reportTitle = data.data.thisReportTitle
            }

            // 明細データの取得
            if (hasIfSucceededFetchDetail) {
              funcFetchDetail(reportAccountName, ...fetchDetailArgs)
            }
          })
          // APIからのエラー
          .catch(err => {
            console.log(err)
          })
          .finally(() => {})
      } else {
        // マスター情報取得APIの戻り値を必要としない場合、且つ明細情報取得APIが必要な場合、明細情報取得APIを呼び出す
        if (hasIfSucceededFetchDetail) {
          funcFetchDetail(reportAccountName, ...fetchDetailArgs)
        }
      }
    },
    /**
     * レポート画面の明細情報を取得する.
     * （明細情報とはレポートの各レコード一覧情報）
     *
     * @param {String} reportAccountName レポートの識別子
     * @param {Boolean} hasRefreshDamageCondition 被害判定項目内訳をAPI返却値で最新化する場合true
     * @param {Boolean} hasRefreshShowItems 表示項目の表示内容(母数情報)をAPI返却値で最新化する場合true
     * @param {Boolean} hasInitSelectedShowItems 表示項目の選択された値を初期化（API返却値で全選択）する場合true
     * @param {Boolean} isCheckRenewedBaseDatetime 基準日時（API返却値）が更新されていた場合に通知ダイアログを表示する場合true
     */
    fetchReportDetails(
      reportAccountName,
      hasRefreshDamageCondition = true,
      hasRefreshShowItems = true,
      hasInitSelectedShowItems = false,
      isCheckRenewedBaseDatetime = true
    ) {
      let pathParams = { report_account_name: reportAccountName }
      let queryParams = this.getReportDetailsSearchParams()

      this.isLoading = true
      client
        .mailformReportViews(pathParams, queryParams)
        .then(data => {
          // 結果メッセージを設定
          this.messageType = data.status
          // this.message = data.message
          this.message = data.status != "success" ? data.message : "" // 暫定対応 successの場合はAPIの返却メッセージを表示しない（無きものとして扱う）
          // 基準日時を設定
          let beforeBaseDatetime = this.baseDatetime // APIの結果を設定する前に、画面に表示されていた基準日時を退避する
          this.baseDatetime = data.baseDateTime
          // 被害判定項目を設定
          if (hasRefreshDamageCondition) {
            this.damagedCount = data.damageConditionBreakdown?.damagedCount
            this.noDamageCount = data.damageConditionBreakdown?.noDamageCount
            this.noReportCount = data.damageConditionBreakdown?.confirmingCount
            // 下記エラーの場合、被害判定グラフはエラー表示とする
            // EMC安否集計連携エラーフラグ「0：無、1：有」
            // 集計エラーフラグ「0：無、1：有」
            this.hasGraphError =
              data.damageConditionBreakdown?.emcAnpiSummaryErrorFlag == 1 ||
              data.damageConditionBreakdown?.summaryErrorFlag == 1
          }

          // 明細ヘッダ情報を設定
          // ここでヘッダのデータ構造を統一しておく（Array-Object形式）
          this.reportHeaders = []
          if (hasRefreshShowItems) {
            // 表示項目の表示内容を初期化する
            this.reportsStore.initShowFixedItem()
            this.reportsStore.initShowMasterItem()
          }
          Object.keys(data.fieldNames).forEach(key => {
            const fieldHeader = { key: key, title: data.fieldNames[key] }
            this.reportHeaders.push(fieldHeader)
            if (hasRefreshShowItems) {
              // 表示項目の表示内容を設定する
              if (key == "field1" || key == "field2") {
                // 最初の２項目は、固定項目として扱う
                this.reportsStore.pushShowFixedItem(fieldHeader)
              } else {
                this.reportsStore.pushShowMasterItem(fieldHeader)
              }
            }
          })
          if (hasInitSelectedShowItems || this.reportsStore.isShowAll) {
            // 選択された表示項目を初期化する（初期化指示あり、または表示項目選択状態が全表示の場合）
            this.resetSelectedShowItems()
          }

          // 明細情報を設定
          // ここでデータ構造を統一しておく（Array-Object形式）
          this.reports = []
          data.data?.forEach(row => {
            let report = []
            Object.keys(row).forEach(key => {
              report.push({
                key: key,
                titles:
                  key == "field1"
                    ? [this.getDamageStatusTitle(row[key].values?.[0])]
                    : row[key].values?.map(value => {
                        return value || ""
                      }) || [],
                // カラーコードを適用（#が含まれていたりいなかったりする。。のでここで対応する）「将来的にはAPIのI/Fを統一してほしいところ」
                characterColor: this.complementColorCode(row[key].characterColor),
                backgroundColor: this.complementColorCode(row[key].backgroundColor),
              })
            })
            this.reports.push(report)
          })
          // 明細の件数情報を設定
          this.paginate.totalPage = data.totalPages
          this.paginate.firstShownCount = data.startCount
          this.paginate.lastShownCount = data.endCount
          this.paginate.totalCount = data.allCount
          // 表示するレポートアカウント名を保持
          this.reportsStore.setCurrentReportAccountName(this.$route.params.reportAccountName)

          // 基準日時変更を検出したときに通知ダイアログを表示する
          if (isCheckRenewedBaseDatetime && !this.$dayjs(beforeBaseDatetime).isSame(this.$dayjs(data.baseDateTime))) {
            this.$refs.renewBaseDatetimeInformationDialog.open()
          }
        })
        // APIからのエラー
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    /**
     * カラーコードを補完する
     * @param {*} colorCode
     * @returns
     *     値がなければ空文字返却
     *     値の1文字目が#ならそのまま返却
     *     値の1文字目が#以外なら#を付与して返却
     */
    complementColorCode(colorCode) {
      if (colorCode == null) {
        // colorCodeが無い場合は空文字
        return ""
      } else {
        // colorCodeがある場合は頭文字の#を補完する（なければ追加）
        return colorCode.charAt() == "#" ? colorCode : "#" + colorCode
      }
    },
    /**
     * レポート画面の絞り込み条件マスター情報を取得する.
     * @param {String} reportAccountName レポートの識別子
     */
    fetchReportFilteringMaster(reportAccountName = this.$route.params.reportAccountName) {
      let pathParams = { report_account_name: reportAccountName }
      return (
        client
          .mailformSearchConditionMaster(pathParams)
          .then(data => {
            this.filteringMasterData = data.data?.map(data => {
              return {
                fieldId: data.fieldId,
                fieldName: data.fieldName,
                fieldType: data.fieldType,
                valueOperators: data.valueOperators,
                multiCheckValues: data.multiCheckValues,
              }
            })
          })
          // APIからのエラー
          .catch(err => {
            console.log(err)
          })
          .finally(() => {})
      )
    },

    /**
     * 明細取得APIのレスポンスデータ「被害状況」をラベルに変換する
     * @param {*} damageStatus 被害状況のkey
     * @returns 被害状況の表示ラベル
     */
    getDamageStatusTitle(damageStatus) {
      switch (damageStatus) {
        // case "1": // TODO：damageStatusのコード体系は定数などに定義したい「1:被害あり,2:被害なし,3:確認中」
        //   return this.$i18n.t(`parts.common.label.damageCondition.damaged`)
        // case "2":
        //   return this.$i18n.t(`parts.common.label.damageCondition.noDamage`)
        // case "3":
        //   return this.$i18n.t(`parts.common.label.damageCondition.noReport`)
        // default:
        //   return ""
        // TODO：本来ならdamageStatusは絞り込み関係の識別子と同じ（数字）が望ましいが、APIの戻り値が数値のため、APIの修正まで以下の暫定対応とする
        case 1: // TODO：damageStatusのコード体系は定数などに定義したい「1:被害あり,2:被害なし,3:確認中」
          return this.$i18n.t(`parts.common.label.damageCondition.damaged`)
        case 2:
          return this.$i18n.t(`parts.common.label.damageCondition.noDamage`)
        case 3:
          return this.$i18n.t(`parts.common.label.damageCondition.noReport`)
        case 4: // TODO:4はAPI仕様書には含まれないが、実装では返却される。4の意味（どのような文字列に変換するべきか）はAPIの仕様書に記載していただく必要あり。「APIが4を返したときの状態がフロントには判別不能なので」
          return "安否情報連携エラー" // TODO:メッセージ定義化したいが、定義名を「damageStatus:4」の意味に合わせたいので認識合わせてから定義化する
        case 5: // TODO:5はAPI仕様書には含まれないが、実装では返却される。5の意味（どのような文字列に変換するべきか）はAPIの仕様書に記載していただく必要あり。「APIが5を返したときの状態がフロントには判別不能なので」
          return "集計エラー" // TODO:メッセージ定義化したいが、定義名を「damageStatus:5」の意味に合わせたいので認識合わせてから定義化する
        default:
          return ""
      }
    },

    /**
     * 絞り込み条件を初期化して、指定された被害判定の値で新たな絞り込み条件を設定する
     * @param {*} specifiedDamageCondition 指定された被害判定
     */
    refreshFilterByDamageCondition(specifiedDamageCondition) {
      // 絞り込み条件を初期化する
      this.resetFilteringCondition()
      // 絞り込み条件に選択された被害判定の値を追加する
      this.reportsStore.pushFilteringCondition({
        id: this.filteringMasterData.find(masterData => {
          return masterData.fieldType === "DamageCondition" // TODO fieldType名は定数化したい
        })?.fieldId,
        operators: null,
        isIncludeNull: false,
        values: [specifiedDamageCondition],
      })
    },

    /**
     * 確認ダイアログの「OK」ボタン押下時に呼ばれる処理。
     * グラフ押下時の絞り込みリセットの確認ダイアログ。
     */
    searchByDamageCondition(chooseDamageCondition) {
      // 絞り込み条件を初期化して選択された被害判定の値を追加する
      this.refreshFilterByDamageCondition(chooseDamageCondition)

      // ソートを初期化する
      this.resetSortable()
      // ページ数を初期化する
      this.resetPage()

      // 絞り込みを実行する（明細APIを呼び出す）
      let hasRefreshCategories = false // カテゴリ一覧を最新化するか
      let hasRefreshReportTitle = false // タイトルを最新化するか
      let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
      let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
      let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
      let hasInitSelectedShowItems = false // 表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
      let isCheckRenewedBaseDatetime = true // 基準日時の変更検知を行う

      // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
      this.fetchReportMaster(
        this.$route.params.reportAccountName,
        hasRefreshCategories,
        hasRefreshReportTitle,
        hasIfSucceededFetchDetail,
        this.fetchReportDetails,
        hasRefreshDamageCondition,
        hasRefreshShowItems,
        hasInitSelectedShowItems,
        isCheckRenewedBaseDatetime
      )
    },

    /**
     * 絞り込みダイアログの「絞り込み」ボタン押下時
     * @param {*} value 絞り込みダイアログで選択された絞り込み条件
     */
    executeFiltering(value) {
      // 絞り込みボタン押下時にダイアログで選択された一時情報を元画面に反映する
      this.reportsStore.setFilteringCondition(JSON.parse(JSON.stringify(value)))

      // ソートを初期化する
      this.resetSortable()
      // ページ数を初期化する
      this.resetPage()

      // その後、明細の再取得を行う（明細API再呼び出し）
      let hasRefreshCategories = false // カテゴリ一覧を最新化するか
      let hasRefreshReportTitle = false // タイトルを最新化するか
      let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
      let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
      let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
      let hasInitSelectedShowItems = false // 表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
      let isCheckRenewedBaseDatetime = true // 基準日時の変更検知を行う

      // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
      this.fetchReportMaster(
        this.$route.params.reportAccountName,
        hasRefreshCategories,
        hasRefreshReportTitle,
        hasIfSucceededFetchDetail,
        this.fetchReportDetails,
        hasRefreshDamageCondition,
        hasRefreshShowItems,
        hasInitSelectedShowItems,
        isCheckRenewedBaseDatetime
      )
    },

    /**
     * 絞り込み要素の除外
     * @param {*} indexOf 除外対象の要素位置
     * @param {*} countOf 除外対象の要素数（基本は1固定）
     * @param {*} chooseData 除外対象の要素数
     * @param {*} existingChooseFilter 除外対象の要素がデータ構成上、削除か/更新か
     */
    removeFilter(indexOf, countOf = 1, chooseData, existingChooseFilter) {
      // 不要になったら(未入力も入力値も無い場合)、fieldIdをkeyに一時情報の要素自体を削除する。不要でない場合は更新する。
      this.reportsStore.spliceFilteringCondition(indexOf, countOf, chooseData, existingChooseFilter)

      // ソートを初期化する
      this.resetSortable()
      // ページ数を初期化する
      this.resetPage()

      // その後、明細の再取得を行う（明細API再呼び出し）
      let hasRefreshCategories = false // カテゴリ一覧を最新化するか
      let hasRefreshReportTitle = false // タイトルを最新化するか
      let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
      let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
      let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
      let hasInitSelectedShowItems = false // 表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
      let isCheckRenewedBaseDatetime = true // 基準日時の変更検知を行う

      // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
      this.fetchReportMaster(
        this.$route.params.reportAccountName,
        hasRefreshCategories,
        hasRefreshReportTitle,
        hasIfSucceededFetchDetail,
        this.fetchReportDetails,
        hasRefreshDamageCondition,
        hasRefreshShowItems,
        hasInitSelectedShowItems,
        isCheckRenewedBaseDatetime
      )
    },

    /**
     * 項目の「ソート」アイコン押下時
     */
    sortingBy() {
      // ページ数を初期化する
      this.resetPage()

      // ソート変更に伴いAPIを呼び出す。
      let hasRefreshCategories = false // カテゴリ一覧を最新化するか
      let hasRefreshReportTitle = false // タイトルを最新化するか
      let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
      let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
      let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
      let hasInitSelectedShowItems = false // 表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
      let isCheckRenewedBaseDatetime = true // 基準日時の変更検知を行う

      // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
      this.fetchReportMaster(
        this.$route.params.reportAccountName,
        hasRefreshCategories,
        hasRefreshReportTitle,
        hasIfSucceededFetchDetail,
        this.fetchReportDetails,
        hasRefreshDamageCondition,
        hasRefreshShowItems,
        hasInitSelectedShowItems,
        isCheckRenewedBaseDatetime
      )
    },
    /**
     * 対象のレポートデータをAPIから取得する.
     *
     * レポート情報
     *   レポート名
     *   ページング情報
     *     総件数
     *   レポート内容
     *     項目名
     *     項目値
     */
    chooseReport(account) {
      this.$router.push({
        name: "MailformReports",
        params: { reportAccountName: account },
      })
    },

    /**
     * 自動更新の切替を行う.
     * @param newFlag 新しい自動更新状態
     *
     */
    autoReload(newFlag) {
      this.autoReloadFlag = newFlag
      if (this.autoReloadFlag) {
        // 更新タイマーON
        this.autoReloadTimer = setInterval(() => {
          this.reload()
        }, this.autoReloadInterval * 60 * 1000)
      } else {
        // 更新タイマー削除
        clearInterval(this.autoReloadTimer)
      }
    },

    /**
     * 更新を行う.
     * または自動更新時
     */
    reload(isCheckRenewedBaseDatetime = true) {
      // ページ数を初期化しない
      // 表示件数を初期化しない
      // ソートを初期化しない

      let hasRefreshCategories = true // カテゴリ一覧を最新化するか
      let hasRefreshReportTitle = true // タイトルを最新化するか
      let hasIfSucceededFetchDetail = true // マスター情報の取得に成功した場合に、明細を取得するか
      let hasRefreshDamageCondition = true // 被害判定項目の表示内容を最新化するか
      let hasRefreshShowItems = true // 表示項目の表示内容を最新化するか
      let hasInitSelectedShowItems = false // 表示項目の選択された値を初期化する（明細ヘッダの最新値で初期化）
      // let isCheckRenewedBaseDatetime = true // 基準日時の変更検知を行う

      // レポートのマスター情報を取得する（API成功時に続けて明細情報を取得する）
      this.fetchReportMaster(
        this.$route.params.reportAccountName,
        hasRefreshCategories,
        hasRefreshReportTitle,
        hasIfSucceededFetchDetail,
        this.fetchReportDetails,
        hasRefreshDamageCondition,
        hasRefreshShowItems,
        hasInitSelectedShowItems,
        isCheckRenewedBaseDatetime
      )
    },

    /**
     * 明細情報取得APIおよびCSVダウンロードAPIの検索パラメータを生成する
     * @param {String} mode 明細情報取得API:""; CSVダウンロード:"csvDownload"
     * @return {array} queryParams
     */
    getReportDetailsSearchParams(mode = "") {
      let queryParams = {}

      // filteringが指定されていれば適用する
      if (this.reportsStore.getFilteringCondition?.length > 0) {
        queryParams.filteringField = this.reportsStore.getFilteringCondition?.map(filter => {
          let values = filter.values
          // 期間項目の場合、日時を検索用の形式に変換する
          if (filter.values?.fromDate || filter.values?.toDate) {
            // 開始日付または終了日付が入力されている場合、期間項目とみなせる
            values = []
            if (filter.values?.fromDate) {
              // 期間（from）を送信形式に整形する
              values[0] = this.$dayjs(filter.values?.fromDate)
                .add(filter.values?.fromHour || "0", "h")
                .add(filter.values?.fromMinute || "0", "m")
                .add("0", "s")
                .format("YYYY-MM-DDTHH:mm:ss")
            }
            if (filter.values?.toDate) {
              // 期間（to）を送信形式に整形する
              values[1] = this.$dayjs(filter.values?.toDate)
                .add(filter.values?.toHour || "23", "h")
                .add(filter.values?.toMinute || "59", "m")
                .add("59", "s")
                .format("YYYY-MM-DDTHH:mm:ss")
            }
          }
          return {
            id: filter.id,
            operator: filter.operators,
            values: values || [], // APIのI/F上、nullだとエラーになるので対応
            isIncludeNull: filter.isIncludeNull ? 1 : 0,
          }
        })
      }

      // sortが指定されていれば適用する
      if (this.reportsStore.getSortOrder) {
        queryParams.sort = this.reportsStore.getSortField
        queryParams.order = this.reportsStore.getSortOrder == "desc" ? 1 : 0
      }

      // defaultモード(画面表示)時使用するパラメータを指定
      if (mode == "") {
        queryParams.limit = this.limit
        queryParams.offset = this.offset
      }
      // csvDownloadモード時使用するパラメータを指定
      else if (mode == this.csvDownloadMode) {
        //csvダウンロードフラグをたてる
        queryParams.csvDownload = 1
      }

      return queryParams
    },

    /**
     * csvダウンロード
     */
    csvDownload() {
      let pathParams = { report_account_name: this.$route.params.reportAccountName }
      let queryParams = this.getReportDetailsSearchParams(this.csvDownloadMode)

      this.isLoading = true
      client
        .downloadMailformReportViewsCsv(pathParams, queryParams)
        .then(res => {
          // headerデータ取得
          let headers = res.headers
          let baseDateTime = headers["x-base-date-time"]

          if (!this.$dayjs(this.baseDatetime).isSame(this.$dayjs(baseDateTime))) {
            this.isLoading = false
            this.renewBaseDatetimeConfirmDialog.message.body = this.$i18n.t(
              "form.mailformReports.dialog.renewBaseDatetimeConfirmation.message.body",
              {
                processing: this.$i18n.t(`form.mailformReports.button.csvDownload`),
              }
            )
            this.renewBaseDatetimeConfirmDialog.message.prompt = this.$i18n.t(
              `form.mailformReports.dialog.renewBaseDatetimeConfirmation.message.prompt`
            )
            this.$refs.renewBaseDatetimeConfirmDialog.open()
          } else {
            let dispositionArray = headers["content-disposition"].split(";")
            // csvファイル名取得
            let fileName = ""
            dispositionArray.forEach(element => {
              let element_arr = element.trim().split("=")
              if (element_arr.length > 1 && element_arr[0] == "filename") {
                fileName = element_arr[1]
              }
            })

            // csv保存
            let blob = new Blob([res.data])
            saveAs(blob, fileName)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
})
</script>

<style lang="scss"></style>
