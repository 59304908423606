export default {
  dark: false,
  colors: {
    /** デフォルト変数名の色指定 */
    // キーアイテムの背景色[プライマリーカラー オーシャンブルー ●メインカラーサイドメニュー、ボタン、タイトル]
    primary: "#235EC1",
    // タイトルヘッダーの背景色ラ[セカンダリーカラー ライトスカイブルー タイトルヘッダー背景色]
    secondary: "#D9EBFF",
    // // 現在使用予定なし
    // accent: "#82B1FF",
    // // エラー表示の文字色(背景色は自動生成)
    // error: "#FF5252",
    // // インフォメーション表示の文字色(背景色は自動生成)
    // info: "#2196F3",
    // // 動作成功時の文字色(背景色は自動生成)
    // success: "#4CAF50",
    // // 注意喚起時の文字色(背景色は自動生成)
    // warning: "#FFC107",

    // /** 背景色 */
    // /** 背景色 */
    // // スマートガイド背景色[プライマリーカラー スカイブルー グラデーション始まり ●イメージカラーヘッダー背景色]
    // primaryGradationStart: "#2D8FF2",
    // // スマートガイド背景色[プライマリーカラー スカイブルー グラデーション終わり ●イメージカラーヘッダー背景色]
    // primaryGradationEnd: "#83C1FF",
    // // スマートガイドメッセージ背景色[グラデーション ウルトラマリンブルー グラデーション始まり BCP Smart Guideの背景での強調色(PC/スマホ兼用)]
    // secondaryGradationStart: "#00009B",
    // // スマートガイドメッセージ背景色[グラデーション ウルトラマリンブルー グラデーション終わり BCP Smart Guideの背景での強調色(PC)]
    // secondaryGradationEnd: "#2384C1",
    // // スマートガイドメッセージ背景色[グラデーション ウルトラマリンブルー グラデーション終わり BCP Smart Guideの背景での強調色(スマホ用)]
    // dummy011: "#2354C1",
    // // コンテンツの背景色[セカンダリーカラー ソフトブルー ベース背景色]
    // backgroundContent: "#F5F7FF",
    // カード、ダイアログ背景色[文字色・ブラック ホワイト 背景色]
    backgroundNormal: "#FFFFFF",
    // // ダイアログ注意喚起背景色[機能色 ソフトオレンジ 選択色 リストなどの表示]
    // attentionBase: "#FFF4DE",
    // // 非活性時背景色
    // nonActivationBase: "#F0F0F0",
    // // メニューカード背景色[機能色 ソフトグリーン ガイドエリア]
    // menuCard: "#DCF8F6",
    // // バッジの背景色[機能色 オレンジ / グラフ基本カラー 強調項目1 強調項目、未読などの表示]
    // backgroundBadge: "FF7621",
    // // primary,secondaryの次の色
    // third: "#00967F",
    // // 未回答マークの色[機能色 オレンジ / グラフ基本カラー 強調項目1 強調項目、未読などの表示]
    // markUnanswered: "#FF7621",
    // // 非活性マークの色[文字色・ブラック グレーA パーツ縁取り]
    // markNonActive: "#AFAFAF",
    // /**  連絡種別の色 */
    // // 通常
    // contactTypeNormal: "#FFCD4B",
    // // 安否
    // contactTypeSafety: "#FF2A14",
    // // 質問
    // contactTypeQuestion: "#2F90F1",
    // // ユーザアイコン
    // userIcon: "#808080",
    // // ホバー時の背景色
    // backgroundHover: "#F0F0F0",
    // // グローバルメニューホバー時の背景色
    // globalMenuHover: "#76A0E6",
    // // リスト強調背景色
    // noTarget: "#ffd8ce",
    // // リスト強調背景色ホバー時
    // noTargetHover: "#eec7bd",
    // // 音声アイコン色
    // playAudio: "#FFC107",
    // // 未使用のため使用時に識別子変更[機能色 グリーン 強調項目、未読などの表示]
    // dummy001: "#30CCAE",
    // // 未使用のため使用時に識別子変更[グラフ基本カラー 強調項目2]
    // dummy002: "#4277EC",
    // // 未使用のため使用時に識別子変更[グラフ基本カラー やや強調]
    // dummy003: "#739AF0",
    // // 未使用のため使用時に識別子変更[グラフ基本カラー 不明など]
    // dummy004: "#B4C9F7",
    // // 未使用のため使用時に識別子変更[グラフ基本カラー 未回答など]
    // dummy005: "#DAE5FA",

    // /** 文字色 */
    // キーアイテムが背景色時の文字色[文字色・ブラック ホワイト 背景色]
    fontPrimary: "#FFFFFF",
    // // タイトルヘッダーが背景色時の文字色
    // fontSecondary: "#235EC1",
    // // ベース背景色、カード、ダイアログ背景色時の文字色
    // fontBase: "#212121",
    // // 非活性時文字色[文字色・ブラック グレーA パーツ縁取り]
    // fontNonActivation: "#AFAFAF",
    // リンク文字色
    // fontLink: "#2F90F1",
    fontLink: "#4A90E2",
    fontLinkSecondary: "#5FAAF4",
    // // フォーム、カードの項目(見出し)
    // fontItemTitle: "#979797",
    // // フォーム、カードの項目内容
    // fontItemContent: "#212121",
    // // 必須マーク
    // fontRequired: "#FF0000",
    // // 強調表示
    // fontAttention: "#FF7C2F",
    // // 強調表示[文字色・ブラック グリーン ガイド文字色]
    // fontStretch: "#ff0000",
    // 暫定定義、使用時に識別子変更[文字色・ブラック ブラック 基本文字色]
    baseFontColor: "#505050",
    // 暫定定義、使用時に識別子変更[文字色・ブラック ブラック 極小文字色]
    xSmallFontColor: "#000000",
    // // 暫定定義、使用時に識別子変更[文字色・ブラック グリーン ガイド文字色]
    // dummy008: "#00967F",
    // // 暫定定義、使用時に識別子変更[文字色・ブラック グレーB エリア区切り線]
    // dummy009: "#C7C7C7",
    // // 暫定定義、使用時に識別子変更[文字色・ブラック グレーC リスト区切り線]
    // dummy010: "#E3E3E3",

    // /* 指定できないかもしれない */
    // fontError: "#FF0000",
    // /* 指定できないかもしれない[文字色・ブラック グリーン ガイド文字色]*/
    // fontSuccess: "#00967F",
    // 被害状況グラフ領域背景色
    damageConditionGraphAreaBG: "#f5f7ff",
    // 被害状況色（被害あり）
    damageConditionDamaged: "#ff7621",
    // 被害状況色（被害なし）
    damageConditionNoDamage: "#5faaf4",
    // 被害状況色（未報告）
    damageConditionNoReport: "#9b9b9b",
    // ヘッダーメニュー（グループ/ユーザ）にカーソルが重なったときの色
    headerLinkHoverColor: "#bad98e",
    headerMenuHoverColor: "#edf8c9",

    //サマリー画面設定
    // /* 左サイドカテゴリエリアの背景色 */
    summaryCategoryBG: "#D9EBFF",
    // カテゴリ名背景色
    summaryCategoryNameBG: "#FFFFFF",
    // アクティブカテゴリ背景色
    summaryActiveCategoryBG: "#FFFFFF",
    // アクティブカテゴリ -> コンテンツエリア背景色
    summaryActiveCategoryContentBG: "#D9EBFF",
    // アクティブカテゴリ -> コンテンツエリア枠線色
    summaryActiveCategoryBorder: "#235EC1",
    // サマリー画面 -> カテゴリ -> EMC連携エラーメッセージ背景色
    summaryEmcErrorMessageBG: "#FFFFFF",

    // サマリー画面 -> 右側レポート一覧被害ありレポートの背景色
    summaryReportDamagedBG: "#FFD8CE",
    summaryReportDamagedBorder: "#FF7C2F",
    // サマリー画面 -> 右側レポート一覧被害無しレポートの背景色
    summaryReportNoDamageBG: "#D9EBFF",
    summaryReportNoDamageBorder: "#5FAAF4",

    /* 基準日時ダイアログ関連設定 */
    baseDateTimeNotificationBG: "#D9EBFF",
    baseDateTimeNotificationBorder: "#235EC1",
    baseDateTimeNotificationFont: "#235EC1",
    baseDateTimeCautionWordColor: "#f4611d",

    // 非活性ボタン
    disabledButtonBG: "#E0E0E0",
    disabledButtonColor: "#a6a6a6",

    /** メッセージ領域  v6と同じ（vuetify2）のカラーコードを定義 */
    messageSuccess: "#4caf50",
    messageInfo: "#2196f3",
    messageWarning: "#ffc107",
    messageError: "#ff5252",
    messageAttention: "#ff7c2f",

    /** エラー画面背景色 */
    errorBackgroundGradationStart: "#c7c7c7",
    errorBackgroundGradationEnd: "#e8e8e8",

    /** エラー */
    errorBorderColor: "#FF0000",
    errorFontColor: "#FF0000",
  },
}
