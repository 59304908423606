<template>
  <div class="content-area">
    <v-data-table
      :headers="headers"
      :items="dataset"
      item-value="name"
      :fixed-footer="false"
      dense
      class="table report-list-font"
    >
      <template #top>
        <v-row no-gutters class="mx-1" justify="end">
          <v-col cols="auto" style="text-align: right">
            <v-btn
              variant="text"
              color="primary"
              class="link-btn x-small-button-label"
              @click.stop="$emit('csvDownload')"
              ><v-icon :icon="mdiDownload" />
              {{ $t("form.mailformReports.button.csvDownload") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters justify="start">
          <v-col>
            <v-row class="body-label" no-gutters>
              <v-col align="end" class="pb-1">
                <span class="message-large-label"> {{ paginate.firstShownCount }}-{{ paginate.lastShownCount }} </span>

                <span class="body-label">
                  {{ $t("form.mailformReports.label.totalCount.suffix", { totalCount: paginate.totalCount }) }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <!-- <template #thead> -->
      <template #headers>
        <tr ref="tableHeaderElement">
          <template v-for="(header, index) in reportHeaders" :key="index">
            <template v-if="index == 0">
              <th class="table-th pl-8 fixed01head">
                <div class="table-header py-2 px-4" :style="'width: ' + fixed01HeaderWidth(header.title) + 'em'">
                  <v-row justify="space-between" style="flex-wrap: nowrap" class="body-label">
                    <span :style="'width: ' + reportItemDataWidth(header.title) + 'em; word-break: break-all;'">{{
                      header.title
                    }}</span>
                  </v-row>
                </div>
              </th>
            </template>
            <template v-else-if="index == 1">
              <th class="table-th fixed02head" style="cursor: pointer" @click="sortingBy(header.key)">
                <div class="table-header py-2 px-4">
                  <v-row
                    justify="space-between"
                    :style="'min-width: ' + reportItemHeaderWidth(header.title) + 'em'"
                    class="body-label"
                  >
                    <span :style="'width: ' + reportItemDataWidth(header.title) + 'em; word-break: break-all;'">{{
                      header.title
                    }}</span>

                    <span class="ml-auto">
                      <template v-if="hasSortField(header.key)">
                        <template v-if="isAscendingOrder(header.key)">
                          <img alt="sortAscendingIcon" :src="sortAscendingIcon" />
                        </template>
                        <template v-else-if="isDescendingOrder(header.key)">
                          <img alt="sortDescendingIcon" :src="sortDescendingIcon" />
                        </template>
                        <template v-else>
                          <v-icon :icon="mdiSort" />
                        </template>
                      </template>
                      <template v-else>
                        <v-icon :icon="mdiSort" />
                      </template>
                    </span>
                  </v-row>
                </div>
              </th>
            </template>
            <template v-else-if="isShowItems(header.key)">
              <th class="table-th fixed03head" style="cursor: pointer" @click="sortingBy(header.key)">
                <div class="table-header py-2 px-4">
                  <v-row
                    justify="space-between"
                    class="body-label"
                    :style="'min-width: ' + reportItemHeaderWidth(header.title) + 'em'"
                  >
                    <span :style="'width: ' + reportItemDataWidth(header.title) + 'em; word-break: break-all;'">{{
                      header.title
                    }}</span>
                    <span>
                      <template v-if="hasSortField(header.key)">
                        <template v-if="isAscendingOrder(header.key)">
                          <img alt="sortAscendingIcon" :src="sortAscendingIcon" />
                        </template>
                        <template v-else-if="isDescendingOrder(header.key)">
                          <img alt="sortDescendingIcon" :src="sortDescendingIcon" />
                        </template>
                        <template v-else>
                          <v-icon :icon="mdiSort" />
                        </template>
                      </template>
                      <template v-else>
                        <v-icon :icon="mdiSort" />
                      </template>
                    </span>
                  </v-row>
                </div>
              </th>
            </template>
          </template>
        </tr>
      </template>
      <!-- <template #tbody> -->
      <template #body>
        <template v-if="reports.length <= 0">
          <tr>
            <td :colspan="reportHeaders.length">
              <div align="center">
                {{ $t("form.mailformReports.message.reports.empty") }}
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <template v-for="(row, idx) in reports" :key="idx">
            <tr>
              <template v-for="(col, i) in row" :key="i">
                <template v-if="i == 0">
                  <td class="table-td fixed01 pl-8" style="white-space: nowrap">
                    <div>
                      <v-chip
                        class="body-label ma-2 ml-n3"
                        :style="'background-color: ' + col.backgroundColor + ';' + 'color: ' + col.characterColor + ';'"
                        size="large"
                        label
                        variant="text"
                      >
                        <template v-for="(title, l) in col.titles" :key="l">
                          <span
                            class="body-label"
                            style="white-space: pre-wrap; word-break: break-all; display: block"
                            :style="'width: ' + fixed01DataWidth(title) + 'em;'"
                          >
                            {{ title }}
                          </span>
                        </template>
                      </v-chip>
                    </div>
                  </td>
                </template>
                <template v-else-if="i == 1">
                  <td class="table-td fixed02">
                    <div>
                      <template v-for="(title, l) in col.titles" :key="l">
                        <span
                          class="body-label"
                          style="white-space: pre-wrap; word-break: break-all; display: block"
                          :style="'width: ' + reportItemDataWidth(title) + 'em;'"
                        >
                          {{ title }}
                        </span>
                      </template>
                    </div>
                  </td>
                </template>
                <template v-else-if="isShowItems(col.key)">
                  <td style="z-index: 9998">
                    <div
                      style="
                        height: 85%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 10px 14px;
                        margin: 0 -14px;
                      "
                      :style="'background-color: ' + col.backgroundColor + ';' + 'color: ' + col.characterColor + ';'"
                    >
                      <template v-for="(title, l) in col.titles" :key="l">
                        <span
                          class="body-label"
                          style="white-space: pre-wrap; word-break: break-all; display: block"
                          :style="'width: ' + reportItemDataWidth(title) + 'em;'"
                        >
                          {{ title }}
                        </span>
                      </template>
                    </div>
                  </td>
                </template>
              </template>
            </tr>
          </template>
        </template>
      </template>
      <template #bottom>
        <v-row no-gutters justify="end" align="center">
          <v-divider />
          <BaseLabel class="per-page">
            <span class="body-label pr-3">{{ $t("form.mailformReports.label.perPage.prefix") }}</span>
            <v-btn
              variant="text"
              color="fontLink"
              class="link-btn small-title-label mx-1"
              :disabled="isSelectedPerPage(10)"
              @click.stop="changePerPage(10)"
            >
              {{ $t("form.mailformReports.label.perPage.10") }}
            </v-btn>
            <span class="body-label">{{ $t("form.mailformReports.label.perPage.separator") }}</span>
            <v-btn
              variant="text"
              color="fontLink"
              class="link-btn small-title-label mx-1"
              :disabled="isSelectedPerPage(20)"
              @click.stop="changePerPage(20)"
            >
              {{ $t("form.mailformReports.label.perPage.20") }}
            </v-btn>
            <span class="body-label">{{ $t("form.mailformReports.label.perPage.separator") }}</span>
            <v-btn
              variant="text"
              color="fontLink"
              class="link-btn small-title-label mx-1"
              :disabled="isSelectedPerPage(50)"
              @click.stop="changePerPage(50)"
            >
              {{ $t("form.mailformReports.label.perPage.50") }}
            </v-btn>
            <span class="body-label">{{ $t("form.mailformReports.label.perPage.separator") }}</span>

            <v-btn
              variant="text"
              color="fontLink"
              class="link-btn small-title-label mx-1"
              :disabled="isSelectedPerPage(100)"
              @click.stop="changePerPage(100)"
            >
              {{ $t("form.mailformReports.label.perPage.100") }}
            </v-btn>
          </BaseLabel>
        </v-row>
        <v-row no-gutters align-self="center">
          <v-col align-self="center">
            <v-pagination
              v-model="paginate.page"
              :length="paginate.totalPage"
              total-visible="9"
              size="x-small"
              variant="elevated"
              active-color="primary"
              class="pagination"
              @update:model-value="changePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
  <!-- <v-divider /> -->

  <div></div>
</template>

<script>
import { defineComponent } from "vue"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import { mdiSort, mdiSortAscending, mdiSortDescending, mdiArrowExpandAll, mdiDownload } from "@mdi/js"
import { useCommonStore } from "@/stores/common"
import { useMailformReportsStore } from "@/stores/mailform/reports"
import sortAscendingIcon from "@/assets/images/sort_ascending.svg"
import sortDescendingIcon from "@/assets/images/sort_descending.svg"

export default defineComponent({
  name: "MailformReportsDataTable",
  components: { BaseLabel },
  props: {
    dataset: {
      type: Array[Object],
      // required: true,
      default: [],
    },
    headers: {
      type: Array[Object],
      // required: true,
      default: [],
    },
    pageObj: {
      type: Object,
      // required: true,
      default: null,
    },
  },
  emits: [
    "update:dataset",
    "update:headers",
    "update:pageObj",
    "sortingBy",
    "changePage",
    "changePerPage",
    "csvDownload",
  ],
  setup() {
    const commonStore = useCommonStore() // 共通storeを利用したpiniaの定義
    const reportsStore = useMailformReportsStore() // レポート画面storeを利用したpiniaの定義
    return {
      commonStore,
      reportsStore,
    }
  },
  data() {
    return {
      mdiSort: mdiSort,
      mdiSortAscending: mdiSortAscending,
      mdiSortDescending: mdiSortDescending,
      mdiArrowExpandAll: mdiArrowExpandAll,
      mdiDownload: mdiDownload,
      sortAscendingIcon: sortAscendingIcon,
      sortDescendingIcon: sortDescendingIcon,
      customCSS: {
        maxHeight: "600px",
        fixed01HeadWidth: "6em",
      },
      tableHeaderElement: 0, // テーブルのヘッダ行の要素
    }
  },
  computed: {
    reports: {
      get() {
        return this.dataset
      },
      set(value) {
        this.$emit("update:dataset", value)
      },
    },
    reportHeaders: {
      get() {
        return this.headers
      },
      set(value) {
        this.$emit("update:headers", value)
      },
    },
    paginate: {
      get() {
        return this.pageObj
      },
      set(value) {
        this.$emit("update:pageObj", value)
      },
    },
  },
  mounted() {
    // DOM構築完了時にテーブルヘッダ行の要素を取得する
    this.tableHeaderElement = this.$refs.tableHeaderElement
    // DOM構築完了時にtable要素(明細行)の高さを再計算する
    this.handleResize()
    // windowサイズの変更を検知するイベントを登録する
    window.addEventListener("resize", this.handleResize)
  },
  beforeUnmount() {
    // windowサイズの変更を検知するイベントを取り除く
    window.removeEventListener("resize", this.handleResize)
  },

  methods: {
    isShowItems(key) {
      return this.reportsStore.getSelectedShowItems.includes(key)
    },
    handleResize() {
      // 当メソッドはresizeのたび発火させる想定のため、処理がもっさりするかもしれない、、、ので使ってみて改善が必要かも。
      // ⇒odash の debounce や throttleで発火間隔をあければ改善するらしいが、画面の追従がリアルタイムでなくなるため、操作感に違和感がでると思われる。
      // windowの高さに応じてtable要素(明細行)の高さを動的に算出する
      const minHeight = 150 // tableの最小の高さ
      const tableFooterHeight = 150 // footer（tableのbottomより下にある要素）の高さ
      // table要素(明細行)の高さを計算する(window高さ - table要素のtop - footerの高さ)
      let tableHeight =
        document.documentElement.clientHeight -
        (this.tableHeaderElement.getBoundingClientRect().top + window.scrollY) -
        tableFooterHeight

      this.customCSS.maxHeight = (tableHeight > minHeight ? tableHeight : minHeight) + "px"
    },
    isSelectedPerPage(value) {
      return this.commonStore.getPerPage === value
    },
    changePerPage(value) {
      this.commonStore.changePerPage(value)
      this.$emit("changePerPage")
    },
    changePage() {
      // changePageはv-paginationの値変更を検知するためthis.paginate.pageへの値設定は不要
      // this.paginate.page = value
      this.$emit("changePage")
    },
    reportItemDataWidth(str) {
      const maxWidth = 30 // 横幅の最大文字数
      const fullCharWidth = 1 // 全角文字の横幅
      const halfCharWidth = 0.61 // 半角文字の横幅
      let len = 0 // 全体の横幅の計
      let currentLineLen = 0 // 現在の行の横幅
      let longestLineLen = 0 // 横幅が最も長い行

      for (let i = 0; i < str.length; i++) {
        let additionalLen = str[i].match(/[ -~]/) ? halfCharWidth : fullCharWidth
        currentLineLen += additionalLen
        len += additionalLen

        if ((i + 1) % maxWidth === 0) {
          // 文字数が１行の最大文字数を超えたタイミングでその行の最大文字を退避
          longestLineLen = currentLineLen > longestLineLen ? currentLineLen : longestLineLen
          currentLineLen = 0
        }
      }
      longestLineLen = currentLineLen > longestLineLen ? currentLineLen : longestLineLen
      return str.length <= maxWidth ? len : longestLineLen
    },
    reportItemHeaderWidth(str) {
      return this.reportItemDataWidth(str) + 2
    },
    fixed01HeaderWidth(str) {
      this.changeFixed01Width(this.reportItemDataWidth(str) + 2 + "em")
      return this.reportItemDataWidth(str) + 2
    },
    fixed01DataWidth(str) {
      this.changeFixed01Width(this.reportItemDataWidth(str) + 1.5 + "em")
      return this.reportItemDataWidth(str)
    },
    changeFixed01Width(value) {
      // 比較して、より大きければ、新しい値を上書き
      if (parseFloat(this.customCSS.fixed01HeadWidth) < parseFloat(value)) {
        this.customCSS.fixed01HeadWidth = value
      }
    },
    sortingBy(field) {
      this.reportsStore.setSortOrder(this.getNextOrder(field))
      this.reportsStore.setSortField(field)
      this.$emit("sortingBy")
    },
    hasSortField(field) {
      return this.reportsStore.getSortField === field
    },
    getOrder(field) {
      return this.hasSortField(field) ? this.reportsStore.getSortOrder : null
    },
    isAscendingOrder(field) {
      // TODO：orderの値は定数化したい
      return "asc" === this.getOrder(field)
    },
    isDescendingOrder(field) {
      // TODO：orderの値は定数化したい
      return "desc" === this.getOrder(field)
    },
    /**
     * 次のソート順
     * 順序は次の通りとする「 → ソート指定なし → 昇順 → 降順 → ソート指定なし → ...」
     */
    getNextOrder(field) {
      // TODO：orderの値は定数化したい
      if (this.reportsStore.getSortField === field) {
        if ("asc" === this.reportsStore.getSortOrder) {
          // 現在ソート指定されていて、昇順の場合、降順となる
          return "desc"
        } else if ("desc" === this.reportsStore.getSortOrder) {
          // 現在ソート指定されていて、降順の場合、指定なしとなる
          return null
        } else {
          // 現在ソート指定されていて、指定なし場合、昇順となる
          return "asc"
        }
      } else {
        // 現在ソート指定されていない項目の場合、昇順となる
        return "asc"
      }
    },
  },
})
</script>

<style lang="scss" scoped>
img {
  vertical-align: middle; // 当componentのimgは独自ソートアイコンで利用しており、汎用アイコンとの差（位置ズレ）防止のため下部余白を取り除く
}
.link-btn {
  padding: 0 !important;
  min-width: 0;
}

.per-page {
  :deep(.v-btn--disabled) {
    opacity: 1;
    color: var(--v-theme-baseFontColor) !important;
    font-weight: $regular-font-weight;
  }
}

.pagination {
  :deep(.v-btn__content) {
    font-size: $x-small-button-size;
  }
}

.table {
  :deep(.v-table__wrapper) {
    // max-height: v-bind("customCSS.maxHeight");
    height: v-bind("customCSS.maxHeight");
    min-height: 100px;
  }
}
.table-header {
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-fontPrimary));
}
.table-th {
  background-color: rgb(var(--v-theme-primary)) !important;
}
.table-td {
  background-color: rgb(var(--v-theme-backgroundNormal));
  color: rgb(var(--v-theme-baseFontColor)) !important;
}
.report-list-font tr {
  font-family: $monospaced-font-family;
}
.fixed01 {
  // 1列目(横固定)
  position: sticky;
  left: 0;
  color: rgb(var(--v-theme-fontPrimary));
  z-index: 1610;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 1600;
  }
}
.fixed01 .v-chip {
  // 1列目 body行(被害判定項目)の表示幅を統一 => 最小幅設定で統一
  /*
  下記最小幅指定（min-width）は他動的幅指定項目を考慮していないため、下記最小幅指定（min-width）の変更時は必ず明細表示の動的スタイル指定項目をすべて確認すること
  TODO:将来的には下記横幅指定も含めて動的に動くように抽象化したい
  */
  min-width: 90px;
  justify-content: center;
}
.fixed02 {
  // 2列目(横固定) 1列目の長さ考慮
  position: sticky;
  left: calc(
    v-bind("customCSS.fixed01HeadWidth") + 32px + 16px
  ); // 第一固定項目の長さ分(項目値長さ + 左余白 + 右余白)を確保
  color: rgb(var(--v-theme-fontPrimary));
  z-index: 1610;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 1600;
  }
}
.fixed01head {
  // 1行目1列目(縦横固定)
  position: sticky;
  top: 0;
  left: 0;
  color: rgb(var(--v-theme-fontPrimary));
  z-index: 1810;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 1800;
  }
}
.fixed02head {
  // 1行目2列目(縦横固定) 1列目の長さ考慮
  position: sticky;
  top: 0;
  left: calc(
    v-bind("customCSS.fixed01HeadWidth") + 32px + 16px
  ); // 第一固定項目の長さ分(項目値長さ + 左余白 + 右余白)を確保
  color: rgb(var(--v-theme-fontPrimary));
  z-index: 1810;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 1800;
  }
}
.fixed03head {
  // 1行目(縦固定)
  position: sticky;
  top: 0;
  color: rgb(var(--v-theme-fontPrimary));
  z-index: 1710;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 1700;
  }
}
</style>
