<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseLabel",
}
</script>

<style lang="scss" scoped>
div {
  // font-size: $defalt-font-size;
  font-size: $body-label-size;
  // color: var(--v-fontBase-base);
  white-space: pre-wrap;
}
</style>
