<template>
  <v-app>
    <router-view />
    <router-view name="main" />
  </v-app>
</template>

<script>
export default {}
</script>

<!-- lang="scss" を記載することでscss記法で記述できる、基本記載しておくと良い -->
<style lang="scss">
#app {
  // vue全体で取り込み済みのため、/assets/sass/style.scssで定義した変数は以下のようにアクセス可能
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(var(--v-theme-baseFontColor));
  // text-align: center;
  // color: #2c3e50;
  // margin-top: 60px;
  // font-size: $defalt-font-size;
  font-size: $body-label-size;

  // background-color: var(--v-primary-base);
  // color: var(--v-primary-base);
}
</style>
