import { defineStore } from "pinia"
import { storageKey } from "@/stores/storageKey"

const id = "mailform.reports" // idはpinia(システム全体)で一意にする
//defineStore() を使ってストアを定義し、名前を付けてエクスポートすることで.vueから利用可能
//名前は use{Xxxx}Store が慣例 ※{Xxxx}はidと同一にすると管理が楽
export const useMailformReportsStore = defineStore(id, {
  id: id,
  // stateでデータ定義する（初期値もここで設定している）
  state: () => ({
    showItemData: {
      isShowAll: true,
      fixedItemList: [],
      itemList: [],
      selectedItems: [],
    },
    filteringCondition: [],
    sortable: { field: "", order: "" },
    currentReportAccountName: "",
    transitionParams: {
      damageConditionGraphPoint: "",
    },
  }),
  // gettersはstateの値を算出した値を取得するゲッター（算出プロパティ）
  getters: {
    isShowAll: state => state.showItemData.isShowAll,
    getShowItemData: state => state.showItemData,
    getShowFixedItemList: state => state.showItemData.fixedItemList,
    getShowMasterItemList: state => state.showItemData.itemList,
    getSelectedShowItems: state => state.showItemData.selectedItems,
    getFilteringCondition: state => state.filteringCondition,
    getSortField: state => state.sortable.field,
    getSortOrder: state => state.sortable.order,
    getParamsDamageConditionGraphPoint: state => state.transitionParams.damageConditionGraphPoint,
  },
  // actionsはstateの値にデータを設定するセッターとして利用する
  // 実態はAPIのmethods オプションに相当するためビジネスロジック的な処理を記述可能
  actions: {
    setShowAll(value) {
      this.showItemData.isShowAll = value
    },
    initShowFixedItem() {
      this.showItemData.fixedItemList = []
    },
    pushShowFixedItem(value) {
      this.showItemData.fixedItemList.push(value)
    },
    initShowMasterItem() {
      this.showItemData.itemList = []
    },
    pushShowMasterItem(value) {
      this.showItemData.itemList.push(value)
    },
    refreshSelectedShowItems(value) {
      this.showItemData.selectedItems = value
    },
    initFilteringCondition() {
      this.filteringCondition = []
    },
    pushFilteringCondition(value) {
      this.filteringCondition.push(value)
    },
    setFilteringCondition(value) {
      this.filteringCondition = value
    },
    spliceFilteringCondition(indexOf, countOf, chooseData, existingChooseFilter) {
      if (existingChooseFilter) {
        // 該当の絞り込み項目データを更新する
        this.filteringCondition.splice(indexOf, countOf, chooseData)
      } else {
        // 該当の絞り込み項目データ削除
        this.filteringCondition.splice(indexOf, countOf)
      }
    },
    setSortField(value) {
      this.sortable.field = value
    },
    setSortOrder(value) {
      this.sortable.order = value
    },
    initSortable() {
      this.setSortField("")
      this.setSortOrder("")
    },
    setCurrentReportAccountName(value) {
      this.currentReportAccountName = value
    },
    isReportSwitched(reportAccountName) {
      return reportAccountName != this.currentReportAccountName
    },
    setParamsDamageConditionGraphPoint(value) {
      this.transitionParams.damageConditionGraphPoint = value
    },
    clearTransitionParams() {
      this.setParamsDamageConditionGraphPoint("")
    },
  },
  // 永続化
  persist: {
    storage: sessionStorage,
    key: `${storageKey}-${id}`,
  },
})
