/** レポート画面で利用する共通関数ファイル **/
/**
 * 絞り込み項目情報のフィールドタイプがマルチチェック検索入力項目タイプであるか否か
 *
 * @param {*} fieldType 絞り込み項目のフィールドタイプ
 * @returns 引数のfieldTypeがマルチチェック検索入力項目タイプである場合true
 */
function isMultiCheckField(fieldType) {
  switch (fieldType) {
    case "radio":
    case "select":
    case "multi_check":
    case "pref":
    case "AggregateKind":
    case "AggregateKindAttribute":
    case "DamageCondition":
      return true
    default:
      return false
  }
}
/**
 * 絞り込み項目情報のフィールドタイプが数値範囲入力項目タイプであるか否か
 *
 * @param {*} fieldType 絞り込み項目のフィールドタイプ
 * @returns 引数fieldTypeが数値範囲入力項目タイプである場合true
 */
function isNumericRangeField(fieldType) {
  switch (fieldType) {
    case "numericText":
    case "EMCFieldOfNumber":
    case "EMCFieldOfPercentage":
      return true
    default:
      return false
  }
}
/**
 * 絞り込み項目情報のフィールドタイプが期間範囲入力項目タイプであるか否か
 *
 * @param {*} fieldType 絞り込み項目のフィールドタイプ
 * @returns 引数fieldTypeが期間範囲入力項目タイプである場合true
 */
function isPeriodRangeField(fieldType) {
  switch (fieldType) {
    case "date_time_calender":
    case "ReceiveDateTime":
      return true
    default:
      return false
  }
}

/**
 * 絞り込み項目情報のフィールドタイプが期間範囲入力項目タイプのうち時間指定まで可能であるか否か
 *
 * @param {*} fieldType 絞り込み項目のフィールドタイプ
 * @returns 引数fieldTypeが期間範囲入力項目タイプかつ時間指定可能である場合true
 */
function isPeriodRangeAndTimeAbleSpecField(fieldType) {
  switch (fieldType) {
    case "date_time_calender":
      // このcaseには時間指定不可フィールドを列挙する
      return false
    default:
      return isPeriodRangeField(fieldType)
  }
}
/**
 * 絞り込み項目情報のフィールドタイプがテキスト入力項目タイプであるか否か
 *
 * @param {*} fieldType 絞り込み項目のフィールドタイプ
 * @returns 引数fieldTypeがテキスト入力項目タイプである場合true
 */
function isTextField(fieldType) {
  switch (fieldType) {
    case "text":
    case "textarea":
    case "email":
    case "autozip":
    case "hidden":
      return true
    default:
      return false
  }
}
// 関数をエクスポートします。
export default {
  isMultiCheckField,
  isNumericRangeField,
  isPeriodRangeField,
  isPeriodRangeAndTimeAbleSpecField,
  isTextField,
}
