<template>
  <v-main>
    <MainError :title="title" :message="message" :show-button="false" />
  </v-main>
</template>

<script>
import MainError from "./templates/MainError.vue"

export default {
  name: "NotFound",
  components: {
    MainError,
  },
  computed: {
    title() {
      return this.$t("errorPage.notFound.title")
    },
    message() {
      return this.$t("errorPage.notFound.message")
    },
  },
}
</script>

<style lang="scss" scoped>
.v-main {
  background: linear-gradient(
    to bottom,
    rgb(var(--v-theme-errorBackgroundGradationStart)),
    rgb(var(--v-theme-errorBackgroundGradationEnd))
  );
}
</style>
