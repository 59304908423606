<template>
  <v-card class="content-area">
    <v-row justify="space-between" align="center">
      <v-col>
        <a :href="logoUrl">
          <img alt="BCPortal logo" src="@/assets/images/logo.png" class="logo mx-8 mt-2" />
        </a>
      </v-col>

      <v-col>
        <v-row justify="end" align="center">
          <template v-if="!hideReload">
            <v-btn
              v-if="!autoReloadFlag"
              variant="outlined"
              color="primary"
              size="small"
              class="x-small-button-label mx-1 px-5"
              @click.stop="$emit('reload')"
            >
              {{ $t("parts.common.button.refresh") }}
            </v-btn>
            <v-btn
              variant="outlined"
              color="primary"
              size="small"
              class="x-small-button-label mx-1 px-5"
              @click.stop="$emit('autoReload', !autoReloadFlag)"
            >
              {{
                autoReloadFlag
                  ? $t("parts.common.button.autoReload[1]", { count: autoReloadInterval })
                  : $t("parts.common.button.autoReload[0]")
              }}
            </v-btn>
          </template>
          <template v-if="!hideLoginMenu">
            <CommonMenu
              v-if="visibleGroup"
              :title="$t(`parts.common.title.group`)"
              :item-list="groupItemList"
              :current-item-id="currentGroupId"
              :current-item-name="currentGroupName"
              :show-current="false"
              @item-click="changeGroup"
            />
            <CommonMenu :item-list="userItemList" :current-item-name="loginUserName" @item-click="changeLogin" />
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { defineComponent } from "vue"
import CommonMenu from "@/components/common/molecules/CommonMenu.vue"
import client from "@/api/client"
import urlParser from "@/util/urlParser"
import { useCommonLoginStore } from "@/stores/common/login"

export default defineComponent({
  name: "CommonHeader",
  components: {
    CommonMenu,
  },

  props: {
    autoReloadFlag: {
      type: Boolean,
      default: false,
    },
    autoReloadInterval: {
      type: Number,
      default: 1,
    },
    hideReload: {
      type: Boolean,
      default: false,
    },
    hideLoginMenu: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["reload", "autoReload"],
  setup() {
    const commonLoginStore = useCommonLoginStore() // ログイン情報storeを利用したpiniaの定義

    return {
      commonLoginStore,
    }
  },
  data() {
    return {
      logoUrl: urlParser.v1BaseUrl,
      loginId: "",
      loginUserName: "",
      currentGroupId: 1,
      currentGroupName: "",
      groupItemList: [],
      userItemList: {
        1: { id: 1, name: this.$i18n.t(`parts.menu.label.accountSetting`), event: "gotoUserSetting" },
        2: { id: 2, name: this.$i18n.t(`parts.menu.label.logout`), event: "userLogout" },
      },
    }
  },
  computed: {
    visibleGroup() {
      // ログインユーザが管理者でない場合、グループプルダウンを表示する
      return !this.commonLoginStore.isAdmin
    },
  },
  created() {
    this.fetchHeaderData()
  },
  methods: {
    /**
     * ヘッダーのグループ一覧情報やログインユーザー情報を取得する
     */
    fetchHeaderData() {
      this.commonLoginStore.initAuthorities()
      client
        .getLoginUser()
        .then(data => {
          this.groupItemList = []

          this.loginId = data.data.id
          this.loginUserName = data.data.userName
          this.currentGroupId = data.data.currentUserGroupId // アクティブグループのグループID
          // ログインユーザーの権限
          this.commonLoginStore.setKind(data.data.authorities?.kind) // ユーザー種別（0:一般ユーザー、1:管理者ユーザー）
          this.commonLoginStore.setAuthUpdateMailformBaseDateTime(data.data.authorities?.canUpdateMailformBaseDateTime) // レポーティング機能の基準日時の更新権限有無

          data.data.userGroupList?.forEach(group => {
            if (group.groupId == this.currentGroupId) this.currentGroupName = group.groupName // アクティブグループのグループ名
            this.groupItemList.push({ id: group.groupId, name: group.groupName })
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    },

    /**
     * グループセレクトボックスで変更した際のイベント
     * @param {*} value
     */
    changeGroup(value) {
      //this.$emit("changeGroup", value)
      this.currentGroupId = value.id
      this.currentGroupName = value.name

      let queryParams = {
        userGroupId: value.id,
      }
      client
        .switchUserGroup(queryParams)
        .then(() => {
          this.currentGroupId = value.id
          this.currentGroupName = value.name
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          // console.log("finally")
          // alert("finally")
        })
    },

    changeLogin(value) {
      let nextEvent = this.userItemList[value.id]["event"]
      if (nextEvent === "gotoUserSetting") {
        this.gotoUserSetting()
      } else if (nextEvent === "userLogout") {
        this.userLogout()
      }
    },

    /**
     * v1のログイン画面へ遷移
     */
    toV1Login() {
      window.location.href = `${urlParser.v1BaseUrl}/mypage/members/login`
    },
    toV1UserSetting() {
      let v1SettingUrl = ""
      if (this.commonLoginStore.isAdmin) {
        // 管理者権限ユーザーログイン中
        v1SettingUrl = `${urlParser.v1BaseUrl}/dcpmq/users/edit/` + this.loginId
      } else {
        // 一般ユーザーログイン中
        v1SettingUrl = `${urlParser.v1BaseUrl}/mypage/members/edit`
      }
      window.location.href = v1SettingUrl
    },
    gotoUserSetting() {
      this.toV1UserSetting()
    },
    /**
     * ヘッダーメニュー"ログアウト"を押下された際のイベント処理
     */
    /*
     */
    userLogout() {
      let pathParams = { action_name: "Logout" }

      client
        .userLogout(pathParams)
        .then(() => {
          this.toV1Login()
        })
        // APIからのエラーはなし
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          // console.log("finally")
          // alert("finally")
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.logo {
  width: 220px;
  height: 55px;
}
.content-area {
  background-color: rgb(var(--v-theme-backgroundNormal));
}
</style>
