import { createApp } from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import router from "./router"
import i18n from "./plugins/i18n"
import pinia from "./stores"
import dayjs from "./plugins/dayjs"
// import '@mdi/font/css/materialdesignicons.css'
// import "./assets/sass/style.scss"

const app = createApp(App)
app.use(vuetify)
app.use(router)
app.use(i18n)
app.use(pinia)
// app.use(dayjs)
app.config.globalProperties.$dayjs = dayjs

// エラー処理
app.config.errorHandler = (err, instance, info) => {
  /* eslint-disable no-console */
  console.log(`Captured in app.config.errorHandler: ${info}`, err)
  // TODO 今後エラー時に消すべきデータ（pinia等）がある場合ここで対応
  router.replace({ name: "SystemError" }).catch(err => {
    console.log(err)
  })
}

app.mount("#app")
