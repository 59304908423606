import axios from "axios"
// import https from "https"
import router from "@/router/index"
import qs from "qs"
import urlParser from "@/util/urlParser"
import { useCommonStore } from "@/stores/common"
import { DEV_API_PORT } from "./config"
/**
 * リソースのURLに応じてリクエストAPIのBASE_URLを変更する（最終配置場所によっては変更の可能性あり）
 */
const host =
  process.env.NODE_ENV === "development" ? `${urlParser.location.hostname}:${DEV_API_PORT}` : urlParser.location.host
const ROOT_URL = `${urlParser.location.protocol}//${host}/${urlParser.tenantKey}/api`
// const ROOT_URL = `${urlParser.location.protocol}//${host}/api`
// console.log("process.env.NODE_ENV:" + process.env.NODE_ENV)

const client = axios.create({
  baseURL: ROOT_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false,
  // }),
})

const moveErrorPage = (err, reject) => {
  const status = err?.response?.status ?? 401
  switch (status) {
    case 401:
      // 未ログインはセッションタイムアウトとして扱う
      // TODO 必要に応じてpiniaの後処理
      useCommonStore().clearIsNecessaryOnError()
      router.replace({ name: "SessionTimeout" }).catch(() => {})
      break
    case 403:
      // 403は未認可として扱う
      // TODO 必要に応じてpiniaの後処理
      useCommonStore().clearIsNecessaryOnError()
      router.replace({ name: "ForbiddenError" }).catch(() => {})
      break
    case 404:
      // 404は存在しないページとして扱う
      // TODO 必要に応じてpiniaの後処理
      useCommonStore().clearIsNecessaryOnError()
      router.replace({ name: "NotFound" }).catch(() => {})
      break
    case 500:
      // case 400 <= status:
      // システムエラーは共通してシステムエラー画面に飛ばす
      // TODO 必要に応じてpiniaの後処理
      useCommonStore().clearIsNecessaryOnError()
      router.replace({ name: "SystemError" }).catch(() => {})
      break
    default:
      if (400 <= status && 600 > status) {
        // 4xx/5xxの場合はシステムエラー画面に飛ばす
        // TODO 必要に応じてpiniaの後処理
        useCommonStore().clearIsNecessaryOnError()
        router.replace({ name: "SystemError" }).catch(() => {})
      }
      // それ以外は呼び出し元に返す
      reject(err)
  }
}
client.downloadPostReq = (url, params) =>
  new Promise((resolve, reject) => {
    client
      .post(url, params, { responseType: "arraybuffer" })
      .then(res => resolve(res))
      .catch(err => moveErrorPage(err, reject))
  })

// emc連携結果ステータス
const emcResultStatus = {
  success: "0", // EMC連携成功
  inProgress: "1", // EMC連携中
  fail: "2", // EMC連携失敗
}
// 集計結果ステータス
const summaryStatus = {
  created: "0", // 作成済み
  inProgress: "1", // 作成中
  fail: "2", // 作成失敗
}

/**
 * サマリー画面カテゴリ一覧取得APIおよびレポート画面明細取得APIのリトライステータスチェック
 * APIの処理結果からリトライ有無を判定する
 * 判定は以下項目で行う
 *   EMC安否回答連携ステータスチェックにて行う（EMC連携中の場合にリトライを行う）
 *   集計結果ステータスチェックにて行う（作成中の場合にリトライを行う）
 * @param {*} apiResponse
 * @returns
 */
const checkCompletedRetry = apiResponse => {
  return (
    apiResponse.emcAnpiSummaryResult != emcResultStatus.inProgress &&
    apiResponse.summaryResult != summaryStatus.inProgress
  )
}

/**
 * レポート画面CSVダウンロードAPIのリトライステータスチェック
 * APIの処理結果からリトライ有無を判定する
 * 判定は以下項目で行う
 *   EMC安否回答連携ステータスチェックにて行う（EMC連携中の場合にリトライを行う）
 *   集計結果ステータスチェックにて行う（作成中の場合にリトライを行う）
 * @param {*} apiResponse
 * @returns
 */
const checkReportCsvCompletedRetry = apiResponse => {
  return (
    apiResponse["headers"]["x-emc-anpi-summary-result"] != emcResultStatus.inProgress &&
    apiResponse["headers"]["x-summary-result"] != summaryStatus.inProgress
  )
}

/** リトライ関数
 * @param {*} func
 * @param {*} delay リトライの時間間隔(ミリ秒)
 * @param {*} checkFunc リトライ条件チェック関数
 * @returns
 */
const retryPromise = (func, delay, checkFunc) => {
  const retry = (resolve, reject) =>
    func()
      .then(result => ({ result, isCompleted: checkFunc(result) }))
      .then(({ result, isCompleted }) => {
        if (isCompleted) {
          // 終了条件を満たしている場合レスポンスデータを返して正常終了
          return resolve(result)
        } else {
          // 終了条件を満たしていない場合、delayミリ秒後に再実行
          return setTimeout(() => retry(resolve, reject), delay)
        }
      })
      .catch(reject)

  return new Promise(retry)
}

// 必要に応じた共通処理を差し込むため、各リクエスト発行時に処理を一枚かませておく
client.postReq = (url, params) =>
  new Promise((resolve, reject) => {
    client
      .post(url, params)
      .then(res => resolve(res))
      .catch(err => moveErrorPage(err, reject))
  })

client.getReq = (url, params) =>
  new Promise((resolve, reject) => {
    client
      .get(url + (qs.stringify(params) ? "?" + qs.stringify(params) : "")) // クエリストリングのエンコードルールはqs標準（RFC3986）を適用
      .then(res => resolve(res))
      .catch(err => moveErrorPage(err, reject))
  })

client.putReq = (url, params) =>
  new Promise((resolve, reject) => {
    client
      .put(url, params)
      .then(res => resolve(res))
      .catch(err => moveErrorPage(err, reject))
  })

client.deleteReq = (url, params) =>
  new Promise((resolve, reject) => {
    client
      .delete(url, params)
      .then(res => resolve(res))
      .catch(err => moveErrorPage(err, reject))
  })

// client.showSafetyAnswerSummarySearchWord = params =>
client.mailformDamageConditionFields = pathParams =>
  new Promise((resolve, reject) => {
    client
      .getReq("/MailformDamageConditionFields/" + pathParams["report_account_name"])
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

// レポート画面 レポート明細情報取得API
client.mailformReportViews = (pathParams, queryParams) => {
  return retryPromise(
    () => {
      return new Promise((resolve, reject) => {
        client
          .postReq("/mailformreportviews/" + pathParams["report_account_name"], queryParams)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    1000,
    checkCompletedRetry
  )
}

// レポート画面 レポート表示情報取得API
client.mailformReportMaster = pathParams =>
  new Promise((resolve, reject) => {
    client
      .getReq("/mailformreportmaster/" + pathParams["report_account_name"])
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

// レポート画面 絞り込み検索条件マスター取得API
client.mailformSearchConditionMaster = pathParams =>
  new Promise((resolve, reject) => {
    client
      .getReq("/mailformsearchconditionmaster/" + pathParams["report_account_name"])
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

client.switchUserGroup = queryParams =>
  new Promise((resolve, reject) => {
    client
      .putReq("/Sessions/SwitchUserGroup", queryParams)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

client.getLoginUser = () =>
  new Promise((resolve, reject) => {
    client
      .getReq("/Sessions/GetLoginUser")
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

client.userLogout = pathParams =>
  new Promise((resolve, reject) => {
    client
      .deleteReq("/Sessions/" + pathParams["action_name"])
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

// 基準日時関連データ取得API
client.getBaseDatetime = () =>
  new Promise((resolve, reject) => {
    client
      .getReq("/MailformReportStartTimes/AggregateStartDateTime")
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

// 基準日時関連データ更新API
client.putAggregateStartDateTime = queryParams =>
  new Promise((resolve, reject) => {
    client
      .putReq("/MailformReportStartTimes/AggregateStartDateTime", queryParams)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

// サマリー画面カテゴリ一覧取得
client.getMailformSummary = () => {
  const myFunc = () => {
    return new Promise((resolve, reject) => {
      client
        .getReq("/mailformsummary")
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  }
  return retryPromise(myFunc, 1000, checkCompletedRetry)
}

// レポート画面csvダウンロード
client.downloadMailformReportViewsCsv = (pathParams, queryParams) => {
  const myFunc = () => {
    return new Promise((resolve, reject) => {
      client
        .downloadPostReq("/mailformreportviews/" + pathParams["report_account_name"], queryParams)
        .then(res => {
          resolve(res)
        })
        .catch(err => reject(err))
    })
  }
  return retryPromise(myFunc, 1000, checkReportCsvCompletedRetry)
}

export default client
