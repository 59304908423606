<template>
  <v-btn
    rounded
    :outlined="outlined"
    color="primary"
    height="40"
    min-width="200"
    :disabled="disabled"
    :class="{ 'button-outlined': outlined }"
    @click.stop="clickEvent"
  >
    <v-icon v-if="icon" left>
      {{ icon }}
    </v-icon>
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  emits: ["click-event"],
  methods: {
    clickEvent() {
      this.$emit("click-event")
    },
  },
}
</script>

<style lang="scss" scoped>
.button-outlined {
  background-color: var(--v-backgroundNormal-base);
}
</style>
