<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn v-bind="props" variant="text" class="menu-btn" :append-icon="mdiChevronDown"> {{ currentItemName }}</v-btn>
    </template>

    <v-list dense>
      <v-chip v-if="title" variant="text" alian="center">
        {{ title }}
      </v-chip>
      <template v-for="(item, idx) in itemList" :key="idx">
        <v-list-item
          v-if="item.id !== currentItemId || showCurrent"
          :value="idx"
          class="menu-item"
          @click="clickItem(item)"
        >
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { defineComponent } from "vue"
import { mdiChevronDown } from "@mdi/js"
//import * as commonMdi from "@mdi/js"
export default defineComponent({
  props: {
    title: {
      type: String,
      default: "",
    },
    itemList: {
      type: Array[Object],
      default: [],
      //required: true,
    },
    currentItemId: {
      type: String,
      default: "",
    },
    currentItemName: {
      type: String,
      default: "",
    },
    showCurrent: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["itemClick"],
  data() {
    return {
      //commonMdi: commonMdi,
      mdiChevronDown: mdiChevronDown,
      //      mdiLogout: mdiLogout,
    }
  },
  methods: {
    clickItem(item) {
      this.$emit("itemClick", item)
      //alert("item.id=" + item.id + "; item.name=" + item.name)
    },
  },
})
</script>

<style lang="scss" scoped>
.menu-btn:hover {
  color: rgb(var(--v-theme-headerLinkHoverColor));
}
/*
.menu-btn::after {
  color: gray;
}
*/
.menu-item:hover {
  background-color: rgb(var(--v-theme-headerMenuHoverColor));
}
</style>
