<template>
  <VueHighcharts
    type="chart"
    :options="chartOptions"
    :redraw-on-update="true"
    :one-to-one-update="false"
    :animate-on-update="true"
    @rendered="onRender"
  />
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "BarChart",
})
</script>
<script setup>
import { computed, toRefs, defineProps, defineEmits } from "vue"
import VueHighcharts from "vue3-highcharts"

const props = defineProps({
  chartData: Array[Object],
  graphWidth: { type: String, default: "600" },
})
const { chartData } = toRefs(props)

// グラフ生成ためのデータやオプション定義
const chartOptions = computed(() => ({
  chart: {
    type: "bar",
    height: 30,
    margin: [2, 0, 2, 0],
  },
  title: false,
  xAxis: { visible: false },
  yAxis: { visible: false },

  plotOptions: {
    series: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        align: "center",
      },
      pointWidth: 26,
      borderWidth: 0,
      borderRadius: {
        radius: "50%",
        where: "all",
        scope: "stack",
      },
      point: {
        events: {
          click: function (e) {
            emit(
              "graphClick",
              this.series.userOptions.custom.graphPointKey,
              this.series.userOptions.custom.reportAccountName
            )
          },
        },
      },
    },
    bar: {
      stacking: "percent",
    },
  },

  credits: false, // 著作権表示設定
  legend: true,
  tooltip: {
    // 背景色設定で透過率を調整
    //backgroundColor: "rgba(255,255,255,0.3)",
    //borderColor: "rgba(0,0,0,0.3)",
    /*
    positioner: function () {
      return { x: 5, y: -20 }
    },
    */
    outside: true,
    //distance: 15,
    followPointer: true,
    format: "<b>{series.name} ( {point.y} 件)</b>",
    // TODO:文言定義のcomponent呼び出しがcompositionAPIとoptionsAPIで異なるため、現時点では固定文言とする
    // formatter: function () {
    //   let count = this.point.y < 0 ? -this.point.y : this.point.y
    //   return "<b>" + this.series.name + " ( " + count + " 件)</b>"
    // },
    // format:
    //   "<b>" +
    //   this.$i18n.t(`parts.menu.label.accountSetting`, {
    //     conditionName: this.series.name,
    //     count: this.point.y,
    //   }) +
    //   "</b>",
  },
  series: chartData.value,
}))
const emit = defineEmits(["graphClick"])
</script>
