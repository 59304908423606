<template>
  <div class="text-right summary-report-area">
    <v-container fluid>
      <v-row justify="start" class="mt-0 text-left">
        <v-col class="pa-0">
          <MessageLabel :type="messageType" :message="message" class="mx-0 mt-5" />
        </v-col>
      </v-row>

      <v-row class="ml-0 mt-3 mb-3">
        <BaseLabel class="item-title-large-label">{{ currentCategoryName }}</BaseLabel>
      </v-row>

      <template v-if="!hasReports">
        <v-sheet>
          <v-row>{{ $t("form.mailformSummary.message.reportNotConfigured") }}</v-row>
        </v-sheet>
      </template>
      <template v-else>
        <v-card
          v-for="(report, index) in reportList"
          :key="index"
          class="summary-report-box mb-5"
          :class="getReportBoxClass(report.damageConditionBreakdown.damagedCount)"
          rounded
        >
          <v-row class="ma-1 ml-2 pa-1">
            <v-col cols="10" class="pl-3 graph-box" rounded>
              <ReportDamageCondition
                :report-account-name="report.reportName"
                :report-title="report.reportTitle"
                :damaged-count="report.damageConditionBreakdown?.damagedCount"
                :no-damage-count="report.damageConditionBreakdown?.noDamageCount"
                :no-report-count="report.damageConditionBreakdown?.confirmingCount"
                :has-graph-error="
                  report.damageConditionBreakdown?.emcAnpiSummaryErrorFlag == 1 ||
                  report.damageConditionBreakdown?.summaryErrorFlag == 1
                "
                class="ml-3"
                @click-damage-condition="clickDamageCondition"
              />
            </v-col>
            <v-col class="button-box pb-2 d-flex align-center">
              <!-- "詳細を見る"ボタン -->
              <v-btn color="primary" class="mx-auto mt-2 mb-2" size="large" @click="gotoReport(report.reportName)">
                {{ $t("form.mailformSummary.button.displayDetail") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import ReportDamageCondition from "@/components/common/organisms/ReportDamageCondition.vue"
import { useMailformReportsStore } from "@/stores/mailform/reports"
import MessageLabel from "@/components/common/molecules/MessageLabel.vue"

export default defineComponent({
  name: "MailformSummaryReports",
  components: {
    BaseLabel,
    ReportDamageCondition,
    MessageLabel,
  },

  props: {
    hasReports: {
      type: Boolean,
      default: true,
    },
    currentCategoryName: {
      type: String,
      default: "",
    },
    reportList: {
      type: Array[Object],
      default: [],
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String,
      default: "",
    },
  },
  emits: [],
  setup() {
    const reportsStore = useMailformReportsStore() // レポート画面storeを利用したpiniaの定義
    return {
      reportsStore,
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    getReportBoxClass(value) {
      if (value > 0) {
        return " damaged"
      } else {
        return " no-damage"
      }
    },

    /**
     * 被害判定棒グラフをクリックされた際絞り込みデータを取得する
     * @param {String} key
     */
    clickDamageCondition(graphPoint, reportName) {
      // クリックした被害判定グラフの値(graphPoint)をpiniaにセットする。
      this.reportsStore.setParamsDamageConditionGraphPoint(graphPoint)
      // レポート画面へ遷移する。
      this.gotoReport(reportName)
    },

    gotoReport(reportName) {
      this.$router.push({ name: "MailformReports", params: { reportAccountName: reportName } })
    },
  },
})
</script>

<style lang="scss" scoped>
.summary-report-area {
  width: calc(100% - 300px);
  min-width: 1020px;
  margin-left: 30px;
  margin-right: 30px;
}
.summary-report-area .summary-report-box {
  box-shadow: initial;
  border-radius: 15px !important;
}
.summary-report-area .summary-report-box.damaged {
  border: 1px solid rgb(var(--v-theme-summaryReportDamagedBorder));
  background-color: rgb(var(--v-theme-summaryReportDamagedBG));
}
.summary-report-area .summary-report-box.no-damage {
  border: 1px solid rgb(var(--v-theme-summaryReportNoDamageBorder));
  background-color: rgb(var(--v-theme-summaryReportNoDamageBG));
}
.summary-report-area .summary-report-box .graph-box {
  background-color: white;
  width: 70%;
  border-radius: 10px;
}
</style>
