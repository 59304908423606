<template>
  <div v-if="categoryInfos.length != 0" class="text-center summary-category-area">
    <v-card class="ma-0 category-card-parent">
      <div class="d-flex flex-row mt-4 mb-4" width="100%">
        <v-tabs
          v-model="activeCategory"
          direction="vertical"
          color="primary"
          :hide-slider="hideSlider"
          selected-class="active-category"
        >
          <template v-for="category in categoryInfos" :key="category.categoryId">
            <v-tab
              :value="category.categoryId"
              class="text-none"
              @click.stop="$emit('changeActiveCategory', category.categoryId)"
            >
              <div class="ma-1">
                <v-card class="tab-content" rounded="xl">
                  <div class="category-name ml-4 mr-4 mt-2">
                    <div class="text-center text-border inner">
                      {{ category.categoryName }}
                    </div>
                  </div>
                  <div class="category-detail-box">
                    <v-img contain :src="category.src" class="align-end ma-0 pa-1"></v-img>
                    <div class="text-white text-center category-damage-description">
                      <div v-for="(msg, index) in category.description" :key="index">{{ msg }}</div>
                    </div>
                    <label v-if="category.emcErrorCount > 0" class="emc-error-message"
                      >{{ $t("form.mailformSummary.message.emcErrorReportCount", { count: category.emcErrorCount }) }}
                    </label>
                  </div>
                </v-card>
                <div></div>
              </div>
            </v-tab>
          </template>
        </v-tabs>
      </div>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "MailformSummaryCategories",
  components: {},

  props: {
    categoryInfos: {
      type: Array[Object],
      default: [],
    },
    currentCategoryIndex: {
      type: String,
      default: "0",
    },
  },
  emits: ["changeActiveCategory"],
  data() {
    return {
      activeCategory: this.currentCategoryIndex,
      hideSlider: true,
    }
  },
  computed: {},
  methods: {},
})
</script>

<style lang="scss" scoped>
.summary-category-area {
  width: 228px;
}
.summary-category-area .category-card-parent {
  width: 228px;
}
.summary-category-area .v-tab.v-tab {
  height: auto !important;
  border-radius: 25px 0 0 25px;
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 0 10px 0 0;
}

.summary-category-area .category-card-parent {
  background-color: rgb(var(--v-theme-summaryCategoryBG));
}
.summary-category-area .category-card-parent .category-name {
  background-color: rgb(var(--v-theme-summaryCategoryNameBG));
  border-radius: 5px;
  white-space: break-spaces;
  word-break: break-all;
  font-size: $text-font-size-small;
  padding-left: 8px;
  padding-right: 8px;
  height: 55px;
}
.summary-category-area .category-card-parent .category-name .inner {
  height: 55px;
  width: 155px;
  display: table-cell;
  vertical-align: middle;
}

.summary-category-area .category-detail-box {
  position: relative;
}
.summary-category-area .category-detail-box .v-img {
  width: 80% !important;
  margin: 1% 10% 1% 10% !important;
}
.summary-category-area .category-detail-box .v-img .v-img__img {
  width: 80% !important;
  margin-left: 10%;
  margin-right: 10%;
}

.summary-category-area .category-detail-box .category-damage-description {
  width: 100%;
  position: absolute;
  top: 25%;
  left: 0%;
  padding-left: 18%;
  padding-right: 20%;
  white-space: break-spaces;
  font-size: $item-font-size-small;
}
.summary-category-area .category-detail-box .emc-error-message {
  position: absolute;
  top: 70%;
  left: 35px;
  font-size: $text-font-size-small;
  background-color: rgb(var(--v-theme-summaryEmcErrorMessageBG));
  border-radius: 10px;
  width: 130px;
  border: solid 1px rgb(var(--v-theme-errorBorderColor));
  color: rgb(var(--v-theme-errorFontColor));
}

.category-card-parent .active-category {
  background-color: rgb(var(--v-theme-summaryActiveCategoryBG));
}

.category-card-parent .active-category .tab-content {
  background-color: rgb(var(--v-theme-summaryActiveCategoryContentBG));
  border-width: 1px;
  border-style: solid;
  border-color: rgb(var(--v-theme-summaryActiveCategoryBorder));
}

.summary-category-area .v-tabs {
  width: 100%;
}

.v-tab__slider {
  display: none;
}
</style>
