<template>
  <div class="menu-content">
    <v-row align="center">
      <v-col cols="9">
        <!-- 基準日時 (BaseDatetimeのルートはrowタグなので記載位置はここで正) -->
        <BaseDatetime
          :base-datetime="baseDatetime"
          @process-after-saving="$emit('updatedBaseDatetime')"
          @renewed-base-datetime="$emit('updatedBeforeBaseDatetimeRenewed')"
        />
        <v-row align="center" class="flex-nowrap" style="min-height: 65px">
          <!-- レポート選択（カテゴリメニュー） -->
          <span>
            <BaseLabel class="category-label small-title-label px-10 mr-1">
              {{ $t("form.mailformReports.title.reportSelect") }}
            </BaseLabel>
          </span>

          <v-slide-group show-arrows="always" class="my-slide-group">
            <template #prev>
              <span><img alt="prevIcon" :src="prevIcon" width="22" /></span>
            </template>
            <template #next>
              <span><img alt="nextIcon" :src="nextIcon" width="22" /></span>
            </template>
            <v-slide-group-item v-for="item in menus" :key="item">
              <CategoryMenu :category="item" @choose-report="$emit('chooseReport', $event)"></CategoryMenu>
            </v-slide-group-item>
          </v-slide-group>
        </v-row>
      </v-col>
      <v-col class="d-flex ml-auto justify-end" cols="2" style="min-width: 13em">
        <!-- サマリー確認ボタン -->
        <v-row justify="end" align="center">
          <v-btn
            class="summary-btn small-button-label px-10 py-4"
            style="align-content: center"
            @click="screenMailformSummary"
          >
            {{ $t("form.mailformReports.button.summaryConfirmation") }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import BaseDatetime from "@/components/common/organisms/BaseDatetime.vue"
import CategoryMenu from "@/components/MailformReports/molecules/CategoryMenu"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import prevIcon from "@/assets/images/small_vertical_left_btn.svg"
import nextIcon from "@/assets/images/small_vertical_right_btn.svg"
export default defineComponent({
  name: "MailformReportsMenu",
  components: {
    BaseDatetime,
    CategoryMenu,
    BaseLabel,
  },

  props: {
    datetime: {
      type: String,
      default: "",
    },
    menus: {
      type: Array[Object],
      default: [],
    },
  },
  emits: ["update:datetime", "chooseReport", "updatedBaseDatetime", "updatedBeforeBaseDatetimeRenewed"],
  data() {
    return {
      prevIcon: prevIcon,
      nextIcon: nextIcon,
    }
  },
  computed: {
    baseDatetime: {
      get() {
        return this.datetime
      },
      set(value) {
        this.$emit("update:datetime", value)
      },
    },
  },
  methods: {
    screenMailformSummary() {
      this.$router.push({
        name: "MailformSummary",
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.base-time-label {
  white-space: nowrap;
  color: rgb(var(--v-theme-fontPrimary));
}
.category-label {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  background-color: rgb(var(--v-theme-backgroundNormal));
  white-space: nowrap;
}
.menu-content {
  background-color: rgb(var(--v-theme-primary));
  padding: 20px 40px 20px 40px;
  z-index: -1;
}
.summary-btn {
  color: rgb(var(--v-theme-primary));
}

.summary-btn:before {
  content: ""; /*疑似要素の中身を指定*/
  position: absolute; /*絶対位置指定*/
  left: -10%; /*横位置の指定*/
  // top: -3%; /*縦位置の指定*/
  bottom: -3%; /*縦位置の指定*/
  background: rgb(var(--v-theme-backgroundNormal)); /*線の色指定*/
  opacity: 0.22;
  width: 0.5px; /*線の幅指定*/
  height: 120%; /*線の高さ指定*/
}

.my-slide-group {
  :deep(.v-slide-group__prev) {
    align-items: center;
    display: flex;
    flex: 0 1 32px;
    justify-content: left;
    min-width: 32px;
    cursor: pointer;
  }
  :deep(.v-slide-group__next) {
    align-items: center;
    display: flex;
    flex: 0 1 32px;
    justify-content: right;
    min-width: 32px;
    cursor: pointer;
  }
  :deep(.v-slide-group__prev--disabled) {
    pointer-events: none;
    display: none;
  }
  :deep(.v-slide-group__next--disabled) {
    pointer-events: none;
    display: none;
  }
  :deep(.v-slide-group__container .v-slide-group__content button:first-child) {
    margin-left: 0 !important;
  }
  :deep(.v-slide-group__container .v-slide-group__content button:last-child) {
    margin-right: 0 !important;
  }
}
</style>
