<template>
  <v-dialog
    v-model="thisDialog"
    activator="parent"
    no-gutter
    class="base-datetime-dialog"
    :class="{ hidden: !visibleDialog }"
  >
    <v-card class="my-2 mx-5" no-gutter>
      <v-card-title class="body-label title">
        {{ $t("parts.baseDateTime.title.changeDialogTitle") }}
      </v-card-title>
      <v-card-text>
        <!-- 注意書きエラーメッセージ領域（固定メッセージ） -->
        <MessageLabel type="info" :message="$t('parts.baseDateTime.message.changeDialogNotification')"></MessageLabel>
        <!-- エラーメッセージ領域（APIからの返却メッセージ） -->
        <MessageLabel type="error" :message="message" />

        <v-sheet class="mt-10 ml-2 mr-2">
          <v-row class="h4 mb-2 message-large-label">
            {{ formattedBaseDatetime }}
          </v-row>
          <v-row class="mb-2">
            <div>
              <p>
                {{ $t("parts.baseDateTime.label.modifiedUserName") }}： <span>{{ modifiedUserName }}</span>
              </p>
              <p>
                {{ $t("parts.baseDateTime.label.modifiedDateTime") }}：<span>{{ formattedModifiedDateTime }}</span>
              </p>
            </div>
          </v-row>
        </v-sheet>

        <v-sheet class="mt-10 ml-2 mr-2">
          <v-row>
            {{ $t("parts.baseDateTime.message.saveNotification1") }}
          </v-row>
          <v-row class="caution-word">
            {{ $t("parts.baseDateTime.message.saveNotification2") }}
          </v-row>
          <v-row class="mt-4">
            <v-radio-group v-model="startTimeType" color="primary" inline hide-details="true" class="my-radio-group">
              <v-radio :label="$t(`parts.baseDateTime.button.startTimeTypeDate`)" :value="startTimeTypeDate"></v-radio>
              <v-radio :label="$t(`parts.baseDateTime.button.startTimeTypeSpan`)" :value="startTimeTypeSpan"></v-radio>
            </v-radio-group>
          </v-row>
        </v-sheet>

        <!-- 範囲指定入力エリア-->
        <v-sheet v-if="startTimeType === startTimeTypeSpan" height="90px">
          <v-row class="ml-0 span-input-area">
            <v-col cols="auto" class="label-text pr-1">
              <v-label>{{ $t("parts.baseDateTime.label.setSpan.prefix") }}</v-label>
            </v-col>
            <v-col cols="auto" class="pl-1 pr-1">
              <v-text-field
                v-model="startTimeSpan"
                density="compact"
                variant="outlined"
                hide-details="auto"
                class="pa-0 ml-1 mr-1"
                max-width="30px"
                :maxlength="3"
                :rules="[rules.span]"
              ></v-text-field>
            </v-col>
            <v-col cols="auto" class="label-text pl-1">
              <v-label>{{ $t("parts.baseDateTime.label.setSpan.suffix") }}</v-label>
            </v-col>
          </v-row>
        </v-sheet>
        <!-- 日時指定入力エリア-->
        <v-sheet v-else>
          <v-row>
            <v-col cols="4">
              <DatePicker v-model="startTimeDate" variant="outlined" />
            </v-col>
            <v-col cols="5" class="d-flex">
              <v-row>
                <v-col cols="6" class="d-flex pr-0">
                  <v-select
                    v-model="startTimeHour"
                    :items="hourItems"
                    variant="outlined"
                    hide-details="true"
                    density="compact"
                  />
                  <p class="mr-2 time-label body-label">{{ $t("parts.baseDateTime.label.hour") }}</p>
                </v-col>

                <v-col cols="6" class="d-flex pr-0">
                  <v-select
                    v-model="startTimeMinute"
                    :items="minuteItems"
                    variant="outlined"
                    hide-details="true"
                    density="compact"
                  ></v-select>
                  <p class="time-label body-label">{{ $t("parts.baseDateTime.label.minute") }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>

      <v-card-actions class="mt-5 mb-5">
        <v-btn
          variant="outlined"
          class="cancel-button small-button-label mx-auto mt-2 px-3"
          color="primary"
          rounded
          width="25%"
          style="border-width: 2px"
          @click="thisDialog = false"
          >{{ $t("parts.dialog.button.close") }}
        </v-btn>

        <v-btn
          variant="outlined"
          class="save-button small-button-label mx-auto mt-2 px-3"
          color="white"
          rounded
          width="25%"
          style="border-width: 2px"
          :disabled="disabledSaveBtn"
          @click="saveDatetimeChange"
          >{{ $t("parts.dialog.button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import client from "@/api/client"
import DatePicker from "@/components/common/organisms/DatePicker.vue"
import MessageLabel from "@/components/common/molecules/MessageLabel.vue"
import { useCommonLoginStore } from "@/stores/common/login"

export default {
  name: "DatetimeDialog",
  components: {
    DatePicker,
    MessageLabel,
  },
  props: {
    beforeBaseDatetime: {
      type: String,
      default: null,
    },
  },
  emits: ["processAfterSaving", "renewedBaseDatetime"],

  setup() {
    const commonLoginStore = useCommonLoginStore() // ログイン情報storeを利用したpiniaの定義

    return {
      commonLoginStore,
    }
  },
  data() {
    return {
      thisDialog: false,
      //  初期処理をダイアログの描画前に行うためにダイアログ描画用変数を定義（modelValueは画面操作により値が変更されるので別管理用変数を利用）
      visibleDialog: false, // vuetifyのダイアログ表示変数modelValue(thisDialog)とは別にダイアログの描画有無を管理する
      startTimeTypeSpan: "0",
      startTimeTypeDate: "1",

      baseDateTime: "",
      modifiedUserName: "",
      modifiedDateTime: "",

      startTimeType: "1",
      startTimeSpan: "",
      startTime: "",
      startTimeDate: "",
      startTimeHour: "",
      startTimeMinute: "",

      message: "",
      messageType: "",

      hourItems: [...Array(24)].map((_, i) => `${i}`),
      minuteItems: [...Array(60)].map((_, i) => `${i}`),
      rules: {
        span: value => {
          return this.checkTimeSpanRules(value) || this.$i18n.t("validation.numericBetween", { min: 1, max: 999 })
        },
      },
    }
  },
  computed: {
    disabledSaveBtn() {
      // 基準日時更新権限チェック
      if (this.commonLoginStore.isAdmin || this.commonLoginStore.canUpdateMailformBaseDateTime) {
        if (this.startTimeType == "0") {
          if (this.startTimeSpan == "") {
            return true
          } else {
            return !this.checkTimeSpanRules(this.startTimeSpan)
          }
        } else {
          if (this.startTimeDate == "" || this.startTimeHour == "" || this.startTimeMinute == "") {
            return true
          } else {
            return false
          }
        }
      } else {
        // 基準日時の更新権限がない一般ユーザは、保存ボタンを非活性にする
        return true
      }
    },

    formattedBaseDatetime() {
      return this.$dayjs(this.baseDateTime).isValid()
        ? this.$dayjs(this.baseDateTime).format("YYYY/MM/DD HH:mm")
        : this.$i18n.t("parts.baseDateTime.label.aggregating")
    },
    formattedModifiedDateTime() {
      return this.$dayjs(this.modifiedDateTime).isValid()
        ? this.$dayjs(this.modifiedDateTime).format("YYYY/MM/DD HH:mm")
        : ""
    },
  },
  watch: {
    thisDialog: function (newValue) {
      if (newValue) {
        // ダイアログが開かれた時
        this.message = ""
        this.messageType = ""
        this.fetchBaseDatetime()
      } else {
        // ダイアログが閉じられた時
        this.visibleDialog = false
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    /**
     * 基準日時関連情報をAPIから取得する.
     */
    fetchBaseDatetime() {
      this.clearInputValue()

      client
        .getBaseDatetime()
        .then(data => {
          this.baseDateTime = data.data.baseDateTime
          if (!this.$dayjs(this.beforeBaseDatetime).isSame(this.$dayjs(data.data.baseDateTime))) {
            this.thisDialog = false
            this.$emit("renewedBaseDatetime")
          } else {
            this.visibleDialog = true
            this.modifiedUserType = data.data.modifiedUserType
            this.modifiedUserName = data.data.modifiedUserName
            this.modifiedDateTime = data.data.modifiedDateTime

            this.startTimeType = String(data.data.startTimeType)
            if (data.data?.startTimeSpan) {
              this.startTimeSpan = String(data.data.startTimeSpan)
            }

            if (data.data?.startTime) {
              this.startTime = data.data.startTime

              let dateObj = new Date(data.data.startTime)
              this.startTimeDate =
                dateObj.getFullYear() + "-" + String(dateObj.getMonth() + 1) + "-" + dateObj.getDate()
              this.startTimeHour = dateObj.getHours()
              this.startTimeMinute = dateObj.getMinutes()
            }
          }
        })
        // APIからのエラーはなし
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          // console.log("finally")
        })
    },

    // "保存する"ボタン押下された場合のイベント処理
    saveDatetimeChange() {
      let queryParams = {
        startTimeType: this.startTimeType,
      }

      if (this.startTimeType == "0") {
        queryParams.startTimeSpan = this.startTimeSpan
      } else if (this.startTimeType == "1") {
        queryParams.startTime = this.getNewDateText(this.startTimeDate, this.startTimeHour, this.startTimeMinute)
      }

      client
        .putAggregateStartDateTime(queryParams)
        .then(data => {
          if (data.status != "success") {
            // if (data.status == "success") {
            // 結果メッセージを設定
            this.messageType = data.status
            this.message = data.status != "success" ? data.message : "" // 暫定対応 successの場合はAPIの返却メッセージを表示しない（無きものとして扱う）
            // this.message = data.message
            // console.log("messageType=" + this.messageType + "\n this.message" + this.message)
          } else {
            this.thisDialog = false
            this.$emit("processAfterSaving")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    },

    clearInputValue() {
      this.baseDateTime = ""
      this.modifiedUserName = ""
      this.modifiedDateTime = ""
      this.startTimeType = "1"
      this.startTimeSpan = ""
      this.startTime = ""
      this.startTimeDate = ""
      this.startTimeHour = ""
      this.startTimeMinute = ""
    },

    getNewDateText(date, hour, minute) {
      let newDateObj = new Date(date)
      let result =
        newDateObj.getFullYear().toString() +
        ("00" + (newDateObj.getMonth() + 1).toString()).slice(-2) +
        ("00" + newDateObj.getDate().toString()).slice(-2) +
        ("00" + hour.toString()).slice(-2) +
        ("00" + minute.toString()).slice(-2)
      return result
    },

    // 範囲指定に入力された文字のバリデーションチェック
    checkTimeSpanRules(value) {
      const pattern = /^[0-9]{0,3}$/
      if (value.length == 0 || (pattern.test(value) && value > 0)) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.base-datetime-dialog {
  font-size: $x-small-font-size;
  width: 900px;
  height: 750px;
}
.notification-area {
  background-color: rgb(var(--v-theme-baseDateTimeNotificationBG));
  border: 1px solid rgb(var(--v-theme-baseDateTimeNotificationBorder));
  color: rgb(var(--v-theme-baseDateTimeNotificationFont));
}
.title {
  color: rgb(var(--v-theme-primary));
  background-color: rgb(var(--v-theme-secondary));
}
.save-button {
  background-color: rgb(var(--v-theme-primary));
}
button.v-btn[disabled] {
  border: unset;
  opacity: 1;
  background-color: rgb(var(--v-theme-disabledButtonBG));
  color: rgb(var(--v-theme-disabledButtonColor)) !important;
}
.caution-word {
  color: rgb(var(--v-theme-baseDateTimeCautionWordColor));
}
.time-label {
  display: flex;
  align-items: flex-end;
  margin-left: 15px;
}
.span-input-area {
  :deep(.label-text) {
    display: inherit;
  }
  :deep(.v-label) {
    font-size: $x-small-font-size;
    opacity: 1;
  }
}

.my-radio-group {
  :deep(.v-label) {
    font-size: $x-small-font-size;
    opacity: 1 !important;
  }
}
.hidden {
  display: none;
}
</style>
