const { location } = window

// location.pathnameは「/」で始まるので urlPaths[0] は常に空文字
const urlPaths = location.pathname.split("/")
const subDomain = "i."
const v1Hostname = location.hostname.substring(subDomain.length)
const v1Host = location.host.substring(subDomain.length)
const v1BaseUrl = `${location.protocol}//${v1Host}/${urlPaths[1]}`

export default {
  location,
  tenantKey: urlPaths[1],
  v1Hostname,
  v1Host,
  v1BaseUrl,
}
