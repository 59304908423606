<template>
  <div class="text-center">
    <v-menu v-model="visibleDialog" :close-on-content-click="false" :close-on-click="false" location="bottom">
      <template #activator="{ props }">
        <v-btn v-bind="props" variant="text" color="fontLink" class="small-title-label">
          {{ $t(`form.mailformReports.button.showItems`) }}
        </v-btn>
      </template>

      <v-card v-click-outside="clickOutside" min-width="300">
        <v-card-text scrollable style="width: 380px; height: 350px" class="overflow-y-auto pa-0">
          <v-container fluid class="mx-auto mt-0 mb-0 pt-0 pb-0">
            <v-checkbox
              v-model="showAll"
              :label="$t(`form.mailformReports.button.selectAll`)"
              class="shrink mx-auto mr-0 mt-0 my-checkbox"
              hide-details
              @change="changeAllItem"
            >
            </v-checkbox>
          </v-container>
          <v-container fluid class="mt-0 mb-0 pt-0 pb-0 pl-10">
            <v-checkbox
              v-for="cel in reportsStore.getShowFixedItemList"
              :key="cel.key"
              v-model="fixedKeyList"
              dense
              :label="cel.title"
              :value="cel.key"
              hide-details
              height="unset"
              class="mx-auto mt-1 mb-1 my-checkbox fixed-checkbox"
              disabled
            >
              <template #label>
                {{ cel.title }}
              </template>
            </v-checkbox>
            <v-checkbox
              v-for="cel in itemList"
              :key="cel.key"
              v-model="selected"
              dense
              :label="cel.title"
              :value="cel.key"
              hide-details
              height="unset"
              class="mx-auto mt-1 mb-1 my-checkbox"
              @change="changeDetailItem"
            >
            </v-checkbox>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="flat"
            class="mx-auto mt-2 mb-2 px-10"
            color="primary"
            rounded
            size="small"
            @click="saveChangeShowItem"
          >
            {{ $t(`form.mailformReports.button.show`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import { useMailformReportsStore } from "@/stores/mailform/reports"
export default defineComponent({
  name: "SelectShowItemDialog",
  setup() {
    const reportsStore = useMailformReportsStore() // レポート画面storeを利用したpiniaの定義

    return {
      reportsStore,
    }
  },
  data() {
    return {
      visibleDialog: false,
      // 本ダイアログ内で利用するデータ変数を定義
      showAll: this.reportsStore.isShowAll,
      itemList: this.reportsStore.getShowMasterItemList,
      selected: this.reportsStore.getSelectedShowItems,
      fixedKeyList: Object.keys(this.reportsStore.getShowFixedItemList),
    }
  },
  watch: {
    "reportsStore.isShowAll"() {
      this.showAll = this.reportsStore.isShowAll
    },
    // 元データが更新された場合(APIデータで最新化時)、それを検知してダイアログ内の変数を更新する
    "reportsStore.getShowMasterItemList"() {
      this.itemList = this.reportsStore.getShowMasterItemList
    },
    // 元データが更新された場合(初期化時)、それを検知してダイアログ内の変数を更新する
    "reportsStore.getSelectedShowItems"() {
      this.selected = this.reportsStore.getSelectedShowItems
    },
    // 元データが更新された場合(初期化時)、それを検知してダイアログ内の変数を更新する
    "reportsStore.getShowFixedItemList"() {
      this.fixedKeyList = this.reportsStore.getShowFixedItemList.map(item => {
        return item.key
      })
      // this.fixedKeyList = Object.keys(this.selectItemData.fixedItemList)
    },
  },
  methods: {
    /**
     * ダイアログの"すべて選択"がクリックされた際のイベント
     */
    changeAllItem() {
      this.selected = []
      for (var i in this.fixedKeyList) {
        this.selected.push(this.fixedKeyList[i])
      }
      if (this.showAll) {
        for (var item in this.itemList) {
          this.selected.push(this.itemList[item].key)
        }
      }
    },

    /**
     * ダイアログの詳細項目チェックボックスをクリックされた際のイベント
     */
    changeDetailItem() {
      if (this.selected.length !== this.itemList.length + this.fixedKeyList.length) {
        this.showAll = false
      } else {
        this.showAll = true
      }
    },

    /**
     * ダイアログの"表示"ボタンを押下際のイベント
     */
    saveChangeShowItem() {
      this.visibleDialog = false
      this.reportsStore.setShowAll(this.showAll)
      this.reportsStore.refreshSelectedShowItems(this.selected)
    },

    /**
     * ダイアログエリア外をクリックした際のイベント
     */
    clickOutside() {
      // すべて選択 のチェック値をリセット
      this.showAll = this.reportsStore.isShowAll
      // 詳細項目チェックボックスのチェック値をリセット
      this.selected = this.reportsStore.getSelectedShowItems
    },
  },
})
</script>

<style lang="scss" scoped>
div.v-checkbox {
  height: 48px;
}

.fixed-checkbox {
  :deep(.v-selection-control--disabled) {
    // opacity: 0.65;
    opacity: 1;
    pointer-events: none;
  }
  :deep(.v-selection-control__wrapper) {
    // チェックボックス部分にのみdisabledスタイルを適用する
    opacity: var(--v-disabled-opacity);
    pointer-events: none;
    // チェックボックス部分は非表示とする
    visibility: hidden;
  }
}
.my-checkbox {
  :deep(.v-label) {
    color: rgb(var(--v-theme-baseFontColor));
    opacity: 1;
    width: 15.5em; // ラベルは15文字で折り返し(ジャスト15emだと14文字で折り返されてしまう)
  }
}

.pagination {
  :deep(.v-btn__content) {
    font-size: $x-small-button-size;
  }
}
</style>
