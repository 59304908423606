<template>
  <v-container class="main-container">
    <CommonHeader
      :auto-reload-flag="autoReloadFlag"
      :auto-reload-interval="autoReloadInterval"
      @reload="$emit('reload')"
      @auto-reload="$emit('autoReload', $event)"
    />

    <MailformSummaryMenu
      :base-datetime="baseDatetime"
      :is-loading="isLoading"
      @updated-base-datetime="$emit('updatedBaseDatetime')"
      @updated-before-base-datetime-renewed="$emit('updatedBeforeBaseDatetimeRenewed')"
    />

    <MailformSummaryMainContents
      :category-infos="categoryInfos"
      :current-category-index="currentCategoryIndex"
      :current-category-name="currentCategoryName"
      :report-list="reportList"
      :has-reports="hasReports"
      :message="message"
      :message-type="messageType"
      @change-active-category="$emit('changeActiveCategory', $event)"
    />
  </v-container>
</template>

<script>
import CommonHeader from "@/components/common/organisms/CommonHeader.vue"
import MailformSummaryMenu from "@/components/MailformSummary/organisms/MailformSummaryMenu.vue"
import MailformSummaryMainContents from "@/components/MailformSummary/organisms/MailformSummaryMainContents.vue"

export default {
  name: "MailformSummaryTemplate",
  components: {
    CommonHeader,
    MailformSummaryMenu,
    MailformSummaryMainContents,
  },

  props: {
    autoReloadFlag: {
      type: Boolean,
      default: false,
    },
    autoReloadInterval: {
      type: Number,
      default: 1,
    },
    baseDatetime: {
      type: String,
      default: "",
    },
    categoryInfos: {
      type: Array[Object],
      default: [],
    },
    currentCategoryIndex: {
      type: String,
      default: "0",
    },
    currentCategoryName: {
      type: String,
      default: "",
    },
    reportList: {
      type: Array[Object],
      default: [],
    },
    hasReports: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String,
      default: "",
    },
  },
  date() {
    return {}
  },
  emits: ["reload", "autoReload", "changeActiveCategory", "updatedBaseDatetime", "updatedBeforeBaseDatetimeRenewed"],
  computed: {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  min-width: 1280px; // レスポンシブ対応までの暫定
}
</style>
