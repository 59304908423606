<template>
  <v-row align="center" class="flex-nowrap" style="min-height: 1em">
    <BaseLabel class="base-time-label body-label">
      {{ $t("parts.common.label.baseDatetime.prefix") }}
      <span class="message-large-label">{{ formattedBaseDatetime }}</span>
      {{ getBaseDatetimeSuffixLabel }}
    </BaseLabel>
    <v-btn
      class="x-small-button-label ma-2 mt-2 px-3"
      style="background-color: white"
      size="x-small"
      color="primary"
      variant="outlined"
      rounded
    >
      {{ $t("parts.common.button.baseDatetimeDialog.change") }}
      <BaseDatetimeDialog
        :before-base-datetime="baseDatetime"
        @process-after-saving="$emit('processAfterSaving')"
        @renewed-base-datetime="$emit('renewedBaseDatetime')"
      />
    </v-btn>
  </v-row>
</template>

<script>
import { defineComponent } from "vue"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import BaseDatetimeDialog from "@/components/common/organisms/BaseDatetimeDialog.vue"

export default defineComponent({
  name: "MailformCommonDatetime",
  components: {
    BaseLabel,
    BaseDatetimeDialog,
  },

  props: {
    baseDatetime: {
      type: String,
      default: "",
    },
  },
  emits: ["update:datetime", "processAfterSaving", "renewedBaseDatetime"],
  data() {
    return {}
  },
  computed: {
    formattedBaseDatetime() {
      return this.$dayjs(this.baseDatetime).isValid()
        ? this.$dayjs(this.baseDatetime).format("YYYY/MM/DD HH:mm")
        : this.$i18n.t("parts.baseDateTime.label.aggregating")
    },
    getBaseDatetimeSuffixLabel() {
      return this.$dayjs(this.baseDatetime).isValid() ? this.$i18n.t("parts.common.label.baseDatetime.suffix") : ""
    },
  },
  created() {},
  methods: {},
})
</script>

<style lang="scss" scoped>
.base-time-label {
  white-space: nowrap;
  color: white;
}
</style>
