import { createI18n } from "vue-i18n"

const ja = require("@/messages/message_ja.json")
const en = require("@/messages/message_en.json")

const i18n = createI18n({
  locale: "ja",
  messages: {
    ja,
    en,
  },
})
export default i18n
