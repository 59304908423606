<template>
  <div class="menu-content">
    <v-row align="center">
      <v-col cols="9">
        <BaseDatetime
          :base-datetime="baseDatetime"
          @process-after-saving="$emit('updatedBaseDatetime')"
          @renewed-base-datetime="$emit('updatedBeforeBaseDatetimeRenewed')"
        />
      </v-col>
    </v-row>
  </div>
  <div>
    <LoadingOverlay :is-loading="isLoading" />
  </div>
</template>

<script>
import { defineComponent } from "vue"
import BaseDatetime from "@/components/common/organisms/BaseDatetime.vue"
import LoadingOverlay from "@/components/common/molecules/LoadingOverlay.vue"

export default defineComponent({
  name: "MailformReportsMenu",
  components: {
    BaseDatetime,
    LoadingOverlay,
  },

  props: {
    baseDatetime: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updatedBaseDatetime", "updatedBeforeBaseDatetimeRenewed"],
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
</script>

<style lang="scss" scoped>
.menu-content {
  background-color: rgb(var(--v-theme-primary));
  padding: 20px 40px 20px 40px;
  z-index: -1;
}
</style>
