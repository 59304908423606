<template>
  <v-row justify="start" align="center">
    <!-- レポートタイトル -->
    <v-col align="left">
      <BaseLabel class="item-title-large-label" style="min-height: 1.6em">{{ reportTitle }}</BaseLabel>
    </v-col>
  </v-row>
  <v-row justify="start" align="center" class="ma-0">
    <v-col class="pa-0">
      <MessageLabel :type="messageType" :message="message" class="mx-0 mt-0" />
    </v-col>
  </v-row>
  <v-row dense justify="start" align="center" class="mb-1">
    <!-- 被害判定ラベル -->
    <v-col cols="1" class="pl-4" style="flex-wrap: nowrap; max-width: 5em">
      <BaseLabel class="small-title-label" style="white-space: nowrap">
        {{ $t("form.mailformReports.title.damageCondition") }}
      </BaseLabel>
    </v-col>
    <v-col class="pb-2 pl-5">
      <v-row justify="start" align="center" class="damage-graph-area">
        <!-- 被害判定グラフ  -->
        <template v-if="!hasGraphError">
          <BarChart :chart-data="chartData" class="ml-1 mr-5" @graph-click="graphClick" />
        </template>
        <template v-else>
          <div class="ml-1 mr-5">
            <div class="graph-error-message">
              {{ $t("parts.common.label.damageCondition.errorGraphLabel") }}
            </div>
          </div>
        </template>

        <!-- 被害判定項目表示ダイアログ  -->
        <v-btn
          variant="outlined"
          color="primary"
          size="x-small"
          class="x-small-button-label mx-2 px-3"
          rounded
          @click="showDamageCondition"
        >
          <ConditionsDialog :damage-condition-data="damageConditionData" />
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from "vue"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import BarChart from "@/components/common/atoms/BarChart.vue"
import ConditionsDialog from "@/components/common/organisms/ReportDamageConditionsDialog"
import client from "@/api/client"
import MessageLabel from "@/components/common/molecules/MessageLabel"

export default defineComponent({
  name: "ReportDamageCondition",

  components: {
    BaseLabel,
    BarChart,
    ConditionsDialog,
    MessageLabel,
  },
  props: {
    reportAccountName: {
      type: String,
      default: "",
    },
    reportTitle: {
      type: String,
      default: "",
    },
    damagedCount: {
      //被害ありの件数
      type: Number,
      default: 0,
    },
    noDamageCount: {
      //被害なしの件数
      type: Number,
      default: 0,
    },
    noReportCount: {
      //未報告の件数
      type: Number,
      default: 0,
    },
    hasGraphError: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String,
      default: "",
    },
  },
  emits: ["clickDamageCondition", "showDamageCondition"],
  data() {
    return {
      damageConditionData: [],
      // 被害判定条件の演算子変換テーブル（API返却演算子(必ず半角)を表示用演算子に変換するテーブル）
      conditionsOperatorCodeTable: { "=": "＝", "!=": "≠", ">=": "≧", "<=": "≦" },
    }
  },
  computed: {
    /**
     * 被害判定グラフ表示用データ
     */
    chartData() {
      let adjustments = this.getGraphLabelYAdjustmentPoint(this.damagedCount, this.noDamageCount, this.noReportCount)
      return [
        {
          custom: {
            reportAccountName: this.reportAccountName,
            graphPointKey: "3", // customとして被害判定の識別子を渡す TODO:この値はAPIからの戻り値orフロントの定数定義などに変更したい「1:被害あり,2:被害なし,3:確認中」
          },
          name: this.$i18n.t(`parts.common.label.damageCondition.noReport`),
          data: [this.noReportCount == 0 ? null : this.noReportCount],
          color: "rgb(var(--v-theme-damageConditionNoReport))",
          dataLabels: {
            format: this.$i18n.t(`parts.common.label.damageCondition.noReportGraphLabel`, {
              count: this.noReportCount,
            }),
            y: adjustments[2],
          },
        },
        {
          custom: {
            reportAccountName: this.reportAccountName,
            graphPointKey: "2", // customとして被害判定の識別子を渡す TODO:この値はAPIからの戻り値orフロントの定数定義などに変更したい「1:被害あり,2:被害なし,3:確認中」
          },
          name: this.$i18n.t(`parts.common.label.damageCondition.noDamage`),
          data: [this.noDamageCount == 0 ? null : this.noDamageCount],
          color: "rgb(var(--v-theme-damageConditionNoDamage))",
          dataLabels: {
            format: this.$i18n.t(`parts.common.label.damageCondition.noDamageGraphLabel`, {
              count: this.noDamageCount,
            }),
            y: adjustments[1],
          },
        },
        {
          custom: {
            reportAccountName: this.reportAccountName,
            graphPointKey: "1", // customとして被害判定の識別子を渡す TODO:この値はAPIからの戻り値orフロントの定数定義などに変更したい「1:被害あり,2:被害なし,3:確認中」
          },
          name: this.$i18n.t(`parts.common.label.damageCondition.damaged`),
          data: [this.damagedCount == 0 ? null : this.damagedCount],
          color: "rgb(var(--v-theme-damageConditionDamaged))",
          dataLabels: {
            format: this.$i18n.t(`parts.common.label.damageCondition.damagedGraphLabel`, {
              count: this.damagedCount,
            }),
            y: adjustments[0],
          },
        },
      ]
    },
  },
  methods: {
    /**
     * 被害判定グラフ表示用ラベル位置を算出する
     * ※ラベルが重なると表示されなくなるため、重ならない様にy軸の調整値を取得する
     * @param {Number} damagedCount 被害あり件数
     * @param {Number} noDamageCount 被害なし件数
     * @param {Number} noReportCount 未報告件数
     * @returns {Array} [被害ありy軸調整値、被害なしy軸調整値、未報告y軸調整値、]
     */
    getGraphLabelYAdjustmentPoint(damagedCount, noDamageCount, noReportCount) {
      const adjustmentNonePoint = 0 // 調整なし値
      const adjustmentUpperPoint = -8 // y軸（上段へ）の調整値
      const adjustmentLowerPoint = 7 // y軸（下段へ）の調整値

      if (damagedCount == 0 || noDamageCount == 0 || noReportCount == 0) {
        // 0件(ラベル非表示)が1項目でもある場合、調整は行わない
        return [adjustmentNonePoint, adjustmentNonePoint, adjustmentNonePoint]
      }

      let totalCount = damagedCount + noDamageCount + noReportCount // 3項目の合計値
      let leftSideItemsRatio = (damagedCount + noDamageCount) / totalCount // 全項目に対する左2項目の合計値の割合
      let rightSideItemsRatio = (noDamageCount + noReportCount) / totalCount // 全項目に対する右2項目の合計値の割合
      let firstItemRatio = damagedCount / totalCount // 全項目に対する一番左の項目値の割合

      // できれば抽象的にラベル幅を抽出したいが、highchartsのラベル幅算出方法が不明確のため、実際のラベル幅で各ポイントごとにラベルの上下調整を行うように算出する。
      // ただしラベル幅はラベル表示内容により異なるため被害項目の文言に変更がある場合は、以下調整処理も見直すこと
      if (leftSideItemsRatio < 0.35) {
        // 0.345(より正確な数値)
        // 左2項目の合計幅が小さい場合、左2項目ラベル位置を上下に調整する
        return [adjustmentUpperPoint, adjustmentLowerPoint, adjustmentNonePoint]
      } else if (leftSideItemsRatio < 0.47 && firstItemRatio <= 0.06) {
        // 左2項目の合計幅が小さく、且つ1番左の項目が小さい場合、左2項目ラベル位置を上下に調整する
      } else if (leftSideItemsRatio < 0.45 && firstItemRatio <= 0.11) {
        // 左2項目の合計幅が小さく、且つ1番左の項目が小さい場合、左2項目ラベル位置を上下に調整する
        return [adjustmentUpperPoint, adjustmentLowerPoint, adjustmentNonePoint]
      } else if (leftSideItemsRatio < 0.42 && firstItemRatio <= 0.15) {
        // 左2項目の合計幅が小さく、且つ1番左の項目が小さい場合、左2項目ラベル位置を上下に調整する
        return [adjustmentUpperPoint, adjustmentLowerPoint, adjustmentNonePoint]
      } else if (leftSideItemsRatio < 0.38 && firstItemRatio <= 0.18) {
        // 左2項目の合計幅が小さく、且つ1番左の項目が小さい場合、左2項目ラベル位置を上下に調整する
        return [adjustmentUpperPoint, adjustmentLowerPoint, adjustmentNonePoint]
      } else if (rightSideItemsRatio <= 0.33) {
        // 0.324(より正確な数値)
        // 左2項目の合計幅が小さい場合、右2項目ラベル位置を上下に調整する
        return [adjustmentNonePoint, adjustmentUpperPoint, adjustmentLowerPoint]
      }

      // それ以外は上下調整を行わない
      return [adjustmentNonePoint, adjustmentNonePoint, adjustmentNonePoint]
    },
    graphClick(graphPoint, reportAccountName = "") {
      this.$emit("clickDamageCondition", graphPoint, reportAccountName)
    },
    /**
     * 条件確認ボタン押下時に被害判定項目をAPIから取得する
     */
    showDamageCondition() {
      // 一旦表示に使用する被害判定項目をクリアします。
      this.damageConditionData = []

      let pathParams = { report_account_name: this.reportAccountName }
      client
        .mailformDamageConditionFields(pathParams)
        .then(data => {
          data.data?.forEach(conditions => {
            let str = ""
            conditions?.forEach(condition => {
              str +=
                condition.field +
                " " +
                this.conditionsOperatorCodeTable[condition.operator || condition.operator2] +
                " " +
                (condition.value || condition.value2) +
                " "
            })
            this.damageConditionData.push(str)
          })
        })
        // APIからのエラー
        .catch(err => {
          console.log(err)
          this.damageConditionData = ["取得エラー"]
        })
        .finally(() => {})
    },
  },
})
</script>

<style lang="scss" scoped>
.damage-graph-area {
  border: 1px solid;
  border-color: rgb(var(--v-theme-primary));
  width: fit-content;
  border-radius: 9999px;
  flex-wrap: nowrap;
  background-color: rgb(var(--v-theme-damageConditionGraphAreaBG));
  :deep(.highcharts-background) {
    opacity: 0;
  }
}

.graph-error-message {
  text-align: center;
  width: 600px;
  border: solid 2px rgb(var(--v-theme-errorBorderColor));
  border-radius: 12px;
  color: rgb(var(--v-theme-errorFontColor));
  margin-top: 2px;
  margin-bottom: 2px;
  height: 26px;
}
</style>
