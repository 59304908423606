<template>
  <MessageLabel type="success" :message="successMessage" :hide-icon="hideIcon" :style="style"></MessageLabel>
  <MessageLabel type="info" :message="infoMessage" :hide-icon="hideIcon" :style="style"></MessageLabel>
  <MessageLabel type="warning" :message="warningMessage" :hide-icon="hideIcon" :style="style"></MessageLabel>
  <MessageLabel type="attention" :message="attentionMessage" :hide-icon="hideIcon" :style="style"></MessageLabel>
  <MessageLabel type="error" :message="errorMessage" :hide-icon="hideIcon" :style="style"></MessageLabel>
</template>

<script>
import MessageLabel from "@/components/common/molecules/MessageLabel.vue"
export default {
  name: "MessageArea",
  components: {
    MessageLabel,
  },
  props: {
    /**
     * メッセージタイプをkey持つオブジェクトが指定可能
     * valueは文字列または文字列の配列
     * ※valueに指定した文字列はhtmlとしてそのまま表示するため、安全な値のみを指定してください
     * example:
     * {success: ["successMessage1", "successMessage2"],
     * info: "infoMessage",
     * error: "errorMessage"}
     * }
     */
    messages: {
      type: Object[Array],
      default: null,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    style: {
      type: String,
      default: "",
    },
  },
  computed: {
    successMessage() {
      return this.messages?.success
    },
    infoMessage() {
      return this.messages?.info
    },
    warningMessage() {
      return this.messages?.warning
    },
    attentionMessage() {
      return this.messages?.attention
    },
    errorMessage() {
      return this.messages?.error
    },
  },
}
</script>
