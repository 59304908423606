<template>
  <v-alert v-if="message" variant="tonal" dense :type="type" :icon="icon" class="ma-2" :class="type">
    <template v-for="(mes, index) in messageList" :key="index">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="mes" />
    </template>
  </v-alert>
</template>

<script>
import { mdiCheckCircle, mdiInformation, mdiAlert, mdiAlertOctagon } from "@mdi/js"
export default {
  name: "MessageLabel",
  props: {
    type: {
      type: String,
      required: true,
    },
    /** ※messageはhtmlとしてそのまま表示するため、安全な値のみを指定してください */
    message: {
      type: [String, Array],
      default: null,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      if (this.hideIcon) return false
      switch (this.type) {
        case "success":
          return mdiCheckCircle
        case "info":
          return mdiInformation
        case "warning":
          return mdiAlert
        case "error":
          return mdiAlertOctagon
        case "attention":
          return false
        default:
          return mdiInformation
      }
    },
    messageList() {
      if (Array.isArray(this.message)) {
        return this.message
      } else {
        return [this.message]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  word-break: break-all;
  white-space: pre-wrap;
}

.success {
  /** v6（旧vuetify）と同じ見た目にするための定義 */
  color: rgb(var(--v-theme-messageSuccess)) !important;
  border: solid 1px rgb(var(--v-theme-messageSuccess)) !important;
}

.info {
  /** v6（旧vuetify）と同じ見た目にするための定義 */
  color: rgb(var(--v-theme-messageInfo)) !important;
  border: solid 1px rgb(var(--v-theme-messageInfo)) !important;
}
.warning {
  /** v6（旧vuetify）と同じ見た目にするための定義 */
  color: rgb(var(--v-theme-messageWarning)) !important;
  border: solid 1px rgb(var(--v-theme-messageWarning)) !important;
}
.error {
  /** v6（旧vuetify）と同じ見た目にするための定義 */
  color: rgb(var(--v-theme-messageError)) !important;
  border: solid 1px rgb(var(--v-theme-messageError)) !important;
}
.attention {
  /** v6（旧vuetify）と同じ見た目にするための定義 */
  color: rgb(var(--v-theme-messageAttention)) !important;
  border: solid 1px rgb(var(--v-theme-messageAttention)) !important;
}
</style>
