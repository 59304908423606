<template>
  <v-container class="main-container">
    <CommonHeader
      :auto-reload-flag="autoReloadFlag"
      :auto-reload-interval="autoReloadInterval"
      @reload="$emit('reload')"
      @auto-reload="$emit('autoReload', $event)"
    />
    <MailformReportsMenu
      :datetime="baseDatetime"
      :menus="menus"
      @choose-report="$emit('chooseReport', $event)"
      @updated-base-datetime="$emit('updatedBaseDatetime')"
      @updated-before-base-datetime-renewed="$emit('updatedBeforeBaseDatetimeRenewed')"
    />

    <LoadingOverlay :is-loading="isLoading" />

    <MailformReportsSearchArea
      :title="title"
      :damaged-count="damagedCount"
      :no-damage-count="noDamageCount"
      :no-report-count="noReportCount"
      :report-headers="reportHeaders"
      :filtering-master-data="filteringMasterData"
      :message="message"
      :message-type="messageType"
      :has-graph-error="hasGraphError"
      @show-damage-condition="showDamageCondition"
      @execute-filtering="executeFiltering"
      @remove-filter="removeFilter"
      @refresh-filtering-master-data="$emit('refreshFilteringMasterData')"
      @search-by-damage-condition="$emit('searchByDamageCondition', $event)"
    />
    <MailformReportsDataTable
      :dataset="reports"
      :headers="reportHeaders"
      :page-obj="pageObj"
      @sorting-by="$emit('sortingBy')"
      @change-page="$emit('changePage')"
      @change-per-page="$emit('changePerPage')"
      @csv-download="$emit('csvDownload')"
    />
  </v-container>
</template>

<script>
import CommonHeader from "@/components/common/organisms/CommonHeader.vue"
import MailformReportsMenu from "@/components/MailformReports/organisms/MailformReportsMenu"
import MailformReportsSearchArea from "@/components/MailformReports/organisms/MailformReportsSearchArea"
import MailformReportsDataTable from "@/components/MailformReports/organisms/MailformReportsDataTable"
import LoadingOverlay from "@/components/common/molecules/LoadingOverlay.vue"

export default {
  name: "MainMailformReports",
  components: {
    CommonHeader,
    MailformReportsMenu,
    MailformReportsSearchArea,
    MailformReportsDataTable,
    LoadingOverlay,
  },

  props: {
    dataset: {
      type: Array[Object],
      default: [],
    },
    headers: {
      type: Array[Object],
      default: [],
    },
    datetime: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    pageObj: {
      type: Object,
      default: null,
    },
    menus: {
      type: Array[Object],
      default: [],
    },
    autoReloadFlag: {
      type: Boolean,
      default: false,
    },
    autoReloadInterval: {
      type: Number,
      default: 1,
    },
    damagedCount: {
      //被害ありの件数
      type: Number,
      default: 0,
    },
    noDamageCount: {
      //被害なしの件数
      type: Number,
      default: 0,
    },
    noReportCount: {
      //未報告の件数
      type: Number,
      default: 0,
    },
    filteringMasterData: {
      type: Array[Object],
      default: [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String,
      default: "",
    },
    hasGraphError: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update:dataset",
    "update:headers",
    "update:datetime",
    "reload",
    "autoReload",
    "chooseReport",
    "showDamageCondition",
    "executeFiltering",
    "removeFilter",
    "refreshFilteringMasterData",
    "sortingBy",
    "changePage",
    "changePerPage",
    "searchByDamageCondition",
    "updatedBaseDatetime",
    "updatedBeforeBaseDatetimeRenewed",
    "csvDownload",
  ],
  computed: {
    reports: {
      get() {
        return this.dataset
      },
      set(value) {
        this.$emit("update:dataset", value)
      },
    },
    reportHeaders: {
      get() {
        return this.headers
      },
      set(value) {
        this.$emit("update:headers", value)
      },
    },
    baseDatetime: {
      get() {
        return this.datetime
      },
      set(value) {
        this.$emit("update:datetime", value)
      },
    },
  },
  methods: {
    showDamageCondition() {
      this.$emit("showDamageCondition")
    },

    // 絞り込み実施イベント
    executeFiltering(value) {
      this.$emit("executeFiltering", value)
    },
    removeFilter(indexOf, countOf, chooseData, existingChooseFilter) {
      this.$emit("removeFilter", indexOf, countOf, chooseData, existingChooseFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  min-width: 800px; // レスポンシブ対応までの暫定（これ以上小さいと一部ボタンが見えなくなるため）
}
</style>
