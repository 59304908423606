<template>
  <!-- TODO：ダイアログはデザイン統一のため共通部品（common/molecules/BaseDialog）を使いたい -->
  {{ $t("parts.common.button.reportDamageConditionsDialog") }}
  <v-dialog v-model="conditionsDialog" activator="parent" style="width: 50%" no-gutter>
    <v-sheet class="my-2 mx-5" no-gutter>
      <v-sheet>
        <v-card class="small-title-label title pl-4 pt-2">{{
          $t("parts.dialog.title.reportDamageConditionsDialog")
        }}</v-card>
        <v-row class="mt-8" no-gutters>
          <p class="ml-4 mr-10 small-title-label">
            {{ $t("parts.dialog.label.reportDamageConditionsDialog.damageConditionItem") }}
          </p>

          <v-col class="ml-auto mb-5 mr-5">
            <p class="text-caption">{{ $t("parts.dialog.message.reportDamageConditionsDialog.explain") }}</p>
            <v-card variant="outlined">
              <ul class="py-5">
                <!-- APIで取得した配列を表示 -->
                <div v-for="item in damageConditionData" :key="item" class="small-button-label text-left ml-2">
                  {{ item }}
                </div>
              </ul>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-btn
            variant="outlined"
            class="small-button-label mx-auto mt-30 mb-5"
            color="primary"
            rounded
            width="25%"
            style="border-width: 2px"
            @click="conditionsDialog = false"
            >{{ $t("parts.dialog.button.close") }}</v-btn
          ></v-row
        >
      </v-sheet>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "ConditionsDialog",
  props: {
    damageConditionData: {
      type: Array[Object],
      default: [],
    },
  },

  emits: ["showDamageCondition"],
  data() {
    return {
      conditionsDialog: false,
    }
  },
  // methods: {
  /**
   * 確認ボタンを押下際のイベント
   */
  //showDamageCondition() {
  // this.$emit("showDamageCondition")
  //  },
  // },
}
</script>

<style lang="scss" scoped>
.title {
  color: rgb(var(--v-theme-primary));
  background-color: rgb(var(--v-theme-secondary));
}
</style>
