import "vuetify/styles"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import * as labs from "vuetify/labs/components"
// import "@mdi/font/css/materialdesignicons.css"
import { aliases, mdi } from "vuetify/iconsets/mdi-svg"
import BlueTheme from "@/themes/blue"

const vuetify = createVuetify({
  components: {
    ...components,
    ...labs,
  },
  directives,
  theme: {
    defaultTheme: "BlueTheme",
    themes: {
      BlueTheme,
    },
    // style内でthemeの変数を使えるようにする
    options: { customProperties: true },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
})

export default vuetify
