import { createRouter, createWebHistory } from "vue-router"

import SystemError from "@/components/errors/SystemError"
// import SessionTimeout from "@/components/errors/SessionTimeout"
import NotFound from "@/components/errors/NotFound"
import ForbiddenError from "@/components/errors/ForbiddenError"

// import Example from "@/components/pages/ExamplePage.vue"
// import Example2 from "@/components/pages/ExamplePage2.vue"
// import Home from "@/components/pages/ExampleTop.vue"
// import HelloWorld from "@/components/HelloWorld.vue"
import MailformReports from "@/components/pages/MailformReports.vue"
import MailformSummary from "@/components/pages/MailformSummary.vue"
import urlParser from "@/util/urlParser"

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   meta: { title: "ホーム" },
  // },
  // {
  //   path: "/example",
  //   name: "Example",
  //   components: { main: Example },
  //   sampleParam: { isSample: true },
  // },
  // {
  //   path: "/example2",
  //   name: "Example2",
  //   components: { main: Example2 },
  // },
  // {
  //   path: "/helloWorld",
  //   name: "HelloWorld",
  //   components: { main: HelloWorld },
  // },
  {
    path: "/reporting/summary",
    name: "MailformSummary",
    components: { main: MailformSummary },
  },

  {
    path: "/reporting/:reportAccountName",
    name: "MailformReports",
    components: { main: MailformReports },
  },

  {
    path: "/systemError",
    name: "SystemError",
    components: {
      main: SystemError,
    },
  },
  {
    path: "/sessionTimeout",
    name: "SessionTimeout",
    // components: {
    //   main: SessionTimeout,
    // },
    beforeEnter() {
      // 未認証の（APIが認証失敗を返す）場合、セッションタイムアウト画面ではなくv1ログイン画面へ遷移する（ph1時点仕様 不具合#730対応により）
      window.location = `${urlParser.v1BaseUrl}/mypage/members/login`
    },
  },
  {
    path: "/forbiddenError",
    name: "ForbiddenError",
    components: {
      main: ForbiddenError,
    },
  },
  {
    path: "/notFound",
    name: "NotFound",
    components: {
      main: NotFound,
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: "unknown",
    components: {
      main: NotFound,
    },
  },
]
routes.forEach(content => (content.path = "/" + urlParser.tenantKey + content.path))

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const DEFAULT_TITLE = "BCPortal"

router.afterEach(to => {
  // document.title = DEFAULT_TITLE
  document.title = to.meta.title ? to.meta.title + "｜" + DEFAULT_TITLE : DEFAULT_TITLE
})

export default router
