<template>
  <v-dialog v-model="activate" max-width="600px">
    <!-- <slot name="activater">
      <template #activator="{ props }">
        <v-btn color="primary" v-bind="props"> Open Dialog </v-btn>
      </template>
    </slot> -->
    <v-card class="dialog-content">
      <template v-if="title">
        <v-card-title class="dialog-header small-title-label pa-3">
          <div>
            <div class="pl-1">{{ title }}</div>
          </div>
        </v-card-title>
      </template>
      <!-- <v-progress-linear v-if="loading" absolute indeterminate color="primary"></v-progress-linear> -->
      <!-- TODO：ここにメッセージ表示領域を設けたい(エラーハンドリングタスク後に修正予定) -->
      <!-- <div v-if="notice" class="small-title-label pt-3 px-4">{{ notice }}</div> -->
      <!-- <v-card-text class="pt-3 px-4" :style="visibleScrollY ? 'overflow-y: auto;' : 'overflow-y: hidden;'"> -->
      <!-- <v-card-text class="pt-3 px-4" :scrollable="visibleScrollY"> -->
      <v-card-text class="pt-3 px-4">
        <slot>
          <div class="body-label"></div>
        </slot>
      </v-card-text>

      <template v-if="!hideBottom">
        <v-card-actions class="py-3 px-4 dialog-bottom">
          <slot name="bottom">
            <v-row>
              <v-col
                v-if="cancelBtnLabel !== false"
                cols="12"
                :sm="buttonCols"
                order="last"
                order-sm="first"
                align="center"
              >
                <v-btn
                  class="small-button-label dialog-button px-10"
                  color="primary"
                  variant="outlined"
                  rounded
                  @click="cancel()"
                >
                  <span>{{ cancelBtnLabel || $t("parts.dialog.button.close") }}</span>
                </v-btn>
              </v-col>
              <v-col v-if="okayBtnLabel !== false" cols="12" :sm="buttonCols" order="first" order-sm="0" align="center">
                <v-btn
                  class="small-button-label dialog-button px-10"
                  color="primary"
                  variant="flat"
                  rounded
                  @click="ok()"
                >
                  <span>{{ okayBtnLabel || $t("parts.dialog.button.ok") }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </slot>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    title: {
      type: String,
      default: null,
    },
    hideBottom: {
      type: Boolean,
      default: false,
    },
    visibleCancelBtn: {
      type: Boolean,
      default: true,
    },
    visibleOkayBtn: {
      type: Boolean,
      default: true,
    },
    // falseを指定することでボタン自体を非表示にする
    cancelBtnLabel: {
      type: String,
      default: null,
    },
    // falseを指定することでボタン自体を非表示にする
    okayBtnLabel: {
      type: String,
      default: null,
    },
  },

  /**
   * beforeOpenEvent: ダイアログが開かれるときに呼び出される処理
   * beforeCloseEvent: ダイアログが閉じるときに呼び出される処理
   * beforeOkayEvent: ダイアログの標準OKボタン押下時に呼び出される処理
   * beforeCancelEvent: ダイアログの標準キャンセルボタン押下時に呼び出される処理
   */
  emits: ["beforeOpenEvent", "beforeCloseEvent", "beforeOkayEvent", "beforeCancelEvent"],
  data() {
    return {
      activate: false,
    }
  },
  computed: {
    buttonCols() {
      // ボタンの数に応じたcols幅を指定する
      return this.cancelBtnLabel !== false && this.okayBtnLabel !== false ? 6 : 12
    },
  },
  watch: {
    activate() {
      if (this.activate) {
        this.$emit("beforeOpenEvent")
      } else {
        this.$emit("beforeCloseEvent")
      }
    },
  },
  methods: {
    /**
     * 閉じるボタンを親コンポーネントでオーバーライドする場合、ref属性を使って実現する
     * example:
     * <BaseDialog ref="dialog"
     * this.$refs.dialog.close();
     */
    close() {
      this.activate = false
    },
    /**
     * 本ダイアログを呼び出す場合activator="parent"を指定すれば良いが、
     * 親コンポーネントから本ダイアログ開く処理を直接呼び出したい場合、ref属性を使って実現する
     * example:
     * <BaseDialog ref="dialog"
     * this.$refs.dialog.open();
     */
    open() {
      this.activate = true
    },
    ok() {
      this.$emit("beforeOkayEvent")
      this.close()
    },
    cancel() {
      this.$emit("beforeCancelEvent")
      this.close()
    },
    beforeOpenEvent() {},
    beforeCloseEvent() {},
  },
}
</script>

<style lang="scss" scoped>
.dialog-content {
  border-radius: 12px !important;
  overflow-y: hidden !important;
}

.dialog-header {
  display: flex;
  align-items: center;

  height: 27pt;
  color: rgb(var(--v-theme-primary));
  background-color: rgb(var(--v-theme-secondary));
}
.dialog-bottom {
  border-radius: 12px !important;
}

.dialog-button {
  min-width: 200px;
}
</style>
