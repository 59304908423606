<template>
  <v-card class="contents mt-16 px-3 pt-2 pb-5">
    <v-card-title class="body-label pa-2">
      <BaseLabel>{{ title }}</BaseLabel>
    </v-card-title>
    <v-card-text class="body-label pa-0">
      <MessageLabel type="error" :message="message" hide-icon style="height: 120px" />
    </v-card-text>
    <v-row v-if="showButton" no-gutters justify="center" class="pa-2">
      <BaseButton class="button-label" @click-event="toV1Login">{{ $t("errorPage.button") }}</BaseButton>
    </v-row>
  </v-card>
  <!-- </div> -->
</template>

<script>
import { defineComponent } from "vue"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import BaseButton from "@/components/common/atoms/BaseButton"
import MessageLabel from "@/components/common/molecules/MessageLabel"
import urlParser from "@/util/urlParser"
import { useCommonStore } from "@/stores/common"

export default defineComponent({
  name: "ErrorContent",
  components: {
    BaseLabel,
    BaseButton,
    MessageLabel,
  },
  props: {
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup() {
    const commonStore = useCommonStore() // 共通storeを利用したpiniaの定義
    return {
      commonStore,
    }
  },
  created() {
    // TODO：今後ローディング等をpiniaを利用して実装したとき、ローディング中にエラーになった場合用に状態を強制変更（解除状態に）する必要あるかも
    this.commonStore.clearIsNecessaryOnError()
  },
  methods: {
    /**
     * v1のログイン画面へ遷移
     */
    toV1Login() {
      window.location.href = `${urlParser.v1BaseUrl}/mypage/members/login`
    },
  },
})
</script>

<style lang="scss" scoped>
.contents {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(var(--v-theme-backgroundNormal));
  border-radius: 4px;
}
</style>
