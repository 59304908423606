<template>
  <v-btn variant="text" color="fontLink" class="small-title-label">
    {{ $t("form.mailformReports.button.filtering") }}
    <Dialog
      ref="dialog"
      activator="parent"
      :cancel-btn-label="$t('parts.dialog.button.cancel')"
      :okay-btn-label="$t('form.mailformReports.dialog.filtering.button.filtering')"
      max-width="1121px"
      max-height="80%"
      scrollable
      @before-open-event="dialogOpenAction"
      @before-okay-event="executeFiltering"
      @before-close-event="dialogCloseAction"
    >
      <v-row dense class="mb-1">
        <v-col cols="12" class="pa-0" style="min-width: 691px; max-width: 1091px">
          <MessageArea :messages="messages"></MessageArea>
        </v-col>
        <v-col cols="auto" class="pa-2" style="min-width: 691px; max-width: 691px">
          <v-card variant="outlined" class="card-area py-1 px-2">
            <v-row class="ma-0 pa-2" align="center">
              <v-col class="ma-0 pa-0">
                <BaseLabel style="width: 5em">
                  {{ $t("form.mailformReports.dialog.filtering.label.item") }}
                </BaseLabel>
              </v-col>
              <v-col class="ma-0 pa-0">
                <v-autocomplete
                  v-model="inputFilteringItemValue"
                  :items="filteringItemsData"
                  item-value="value"
                  item-title="title"
                  density="compact"
                  variant="outlined"
                  color="primary"
                  style="width: 35em"
                  clearable
                  :clear-icon="mdiCloseCircle"
                  :no-data-text="$t('form.mailformReports.message.reports.empty')"
                  hide-details
                  @update:model-value="changeFilteringItem"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <template v-if="visibleMultiCheckField">
              <v-row class="ma-0 pa-2" align="center">
                <v-col class="ma-0 pa-0">
                  <BaseLabel style="width: 5em">
                    {{ $t("form.mailformReports.dialog.filtering.label.value") }}
                  </BaseLabel>
                </v-col>
                <v-col class="ma-0 pa-0">
                  <v-autocomplete
                    v-model="inputMultiCheckValues"
                    :items="multiCheckValuesData"
                    item-value="value"
                    item-title="title"
                    density="compact"
                    variant="outlined"
                    color="primary"
                    style="width: 35em"
                    clearable
                    :clear-icon="mdiCloseCircle"
                    :no-data-text="$t('form.mailformReports.message.reports.empty')"
                    hide-details
                    multiple
                    chips
                    closable-chips
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </template>
            <template v-if="visibleNumericRangeField">
              <v-row class="ma-0 pa-2" align="center">
                <v-col class="ma-0 pa-0">
                  <BaseLabel style="width: 5em">
                    {{ $t("form.mailformReports.dialog.filtering.label.value") }}
                  </BaseLabel>
                </v-col>
                <v-row class="ma-0 pa-0" style="width: 35em; align-items: center">
                  <v-text-field
                    v-model="inputNumericRangeFromValue"
                    type="number"
                    clearable
                    label=""
                    density="compact"
                    variant="outlined"
                    color="primary"
                    hide-details
                    style="width: 10em"
                  ></v-text-field>
                  <BaseLabel class="px-4">～</BaseLabel>
                  <v-text-field
                    v-model="inputNumericRangeToValue"
                    type="number"
                    clearable
                    label=""
                    density="compact"
                    variant="outlined"
                    color="primary"
                    hide-details
                    style="width: 10em"
                  ></v-text-field>
                </v-row>
              </v-row>
            </template>
            <template v-if="visiblePeriodRangeField">
              <v-row class="ma-0 pa-2" align="center">
                <v-col class="ma-0 pa-0">
                  <BaseLabel style="width: 5em">
                    {{ $t("form.mailformReports.dialog.filtering.label.value") }}
                  </BaseLabel>
                </v-col>
                <template v-if="visiblePeriodRangeAndTimeAbleSpecField">
                  <!-- 年月日 時分 指定フィールド -->
                  <v-row class="ma-0 pa-0 pb-2 pr-10" style="width: 35em; align-items: center">
                    <DatePicker
                      v-model="inputDateRangeFromValue"
                      variant="outlined"
                      input-field-style="max-width: 10em"
                    />
                    <v-select
                      v-model="inputHourRangeFromValue"
                      :items="hoursItems"
                      density="compact"
                      variant="outlined"
                      color="primary"
                      style="max-width: 6.5em"
                      hide-no-data
                      hide-details
                      class="pl-4"
                    >
                    </v-select>
                    <BaseLabel class="px-2">{{ $t("parts.common.label.hour") }}</BaseLabel>
                    <v-select
                      v-model="inputMinuteRangeFromValue"
                      :items="minutesItems"
                      density="compact"
                      variant="outlined"
                      color="primary"
                      style="max-width: 5.5em"
                      hide-no-data
                      hide-details
                    >
                    </v-select>
                    <BaseLabel class="pl-2">{{ $t("parts.common.label.minute") }}</BaseLabel>

                    <template v-if="inputtedPeriodFrom">
                      <span class="pl-1"
                        ><v-icon :icon="mdiCloseCircle" color="primary" @click.stop="clearInputPeriod('from')"
                      /></span>
                    </template>
                    <BaseLabel class="px-4">～</BaseLabel>
                  </v-row>
                  <v-row class="ma-0 pa-0" style="padding-left: 5em !important; align-items: center">
                    <DatePicker
                      v-model="inputDateRangeToValue"
                      variant="outlined"
                      input-field-style="max-width: 10em"
                    />
                    <v-select
                      v-model="inputHourRangeToValue"
                      :items="hoursItems"
                      density="compact"
                      variant="outlined"
                      color="primary"
                      style="max-width: 6.5em"
                      hide-no-data
                      hide-details
                      class="pl-4"
                    >
                    </v-select>
                    <BaseLabel class="px-2">{{ $t("parts.common.label.hour") }}</BaseLabel>
                    <v-select
                      v-model="inputMinuteRangeToValue"
                      :items="minutesItems"
                      density="compact"
                      variant="outlined"
                      color="primary"
                      style="max-width: 5.5em"
                      hide-no-data
                      hide-details
                    >
                    </v-select>
                    <BaseLabel class="pl-2">{{ $t("parts.common.label.minute") }}</BaseLabel>
                    <template v-if="inputtedPeriodTo">
                      <span class="pl-1"
                        ><v-icon :icon="mdiCloseCircle" color="primary" @click.stop="clearInputPeriod('to')"
                      /></span>
                    </template>
                  </v-row>
                </template>
                <template v-else>
                  <!-- 年月日 指定フィールド -->
                  <v-row class="ma-0 pa-0 pb-2 pr-10" style="width: 35em; align-items: center">
                    <DatePicker
                      v-model="inputDateRangeFromValue"
                      variant="outlined"
                      input-field-style="max-width: 10em"
                    />
                    <template v-if="inputtedPeriodFrom">
                      <span class="pl-1"
                        ><v-icon :icon="mdiCloseCircle" color="primary" @click.stop="clearInputPeriod('from')"
                      /></span>
                    </template>
                    <BaseLabel class="px-4">～</BaseLabel>
                    <DatePicker
                      v-model="inputDateRangeToValue"
                      variant="outlined"
                      input-field-style="max-width: 10em"
                    />
                    <template v-if="inputtedPeriodTo">
                      <span class="pl-1"
                        ><v-icon :icon="mdiCloseCircle" color="primary" @click.stop="clearInputPeriod('to')"
                      /></span>
                    </template>
                  </v-row>
                </template>
              </v-row>
            </template>
            <template v-if="visibleTextField">
              <v-row class="ma-0 pa-2" align="center">
                <v-col class="ma-0 pa-0">
                  <BaseLabel style="width: 5em">
                    {{ $t("form.mailformReports.dialog.filtering.label.value") }}
                  </BaseLabel>
                </v-col>
                <v-row class="ma-0 pa-0" style="width: 35em">
                  <v-text-field
                    v-model="inputTextValue"
                    clearable
                    label=""
                    density="compact"
                    variant="outlined"
                    color="primary"
                    hide-details
                    style="width: 23em"
                    class="pr-4"
                  ></v-text-field>
                  <v-select
                    v-model="inputTextOperatorValue"
                    :items="textOperatorsData"
                    density="compact"
                    variant="outlined"
                    color="primary"
                    style="width: 9em"
                    hide-no-data
                    hide-details
                  >
                  </v-select>
                </v-row>
              </v-row>
            </template>
            <template
              v-if="visibleMultiCheckField || visibleNumericRangeField || visiblePeriodRangeField || visibleTextField"
            >
              <v-row class="mx-0 mb-0 mt-n5" style="padding-left: 5em !important">
                <v-col cols="auto" class="ma-0 pa-0">
                  <v-checkbox
                    v-model="inputIncludeNullCheckValue"
                    :true-value="true"
                    :false-value="false"
                    color="primary"
                    class="include-null-checkbox"
                    :label="$t('form.mailformReports.dialog.filtering.label.notInput')"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </template>

            <v-row class="ma-0 pa-2" align="center">
              <v-col class="ma-0 pa-0"></v-col>
              <v-col class="ma-0 pa-0">
                <v-card-actions class="py-3 px-4" style="width: 35em">
                  <v-row>
                    <v-col cols="12" sm="12" order="first" order-sm="0" align="center">
                      <v-btn
                        class="small-button-label dialog-button px-10"
                        variant="flat"
                        color="primary"
                        rounded
                        @click="additionalFilter"
                      >
                        <span>{{ $t("form.mailformReports.dialog.filtering.button.add") }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="auto" class="pa-2" style="min-width: 400px; max-width: 400px">
          <v-card variant="outlined" class="card-area py-1 px-2">
            <FilteringChips
              ref="filteringChips"
              :filtering-condition="temporaryChooseFilteringData"
              :filtering-master-data="filteringMasterData"
              :empty-label="$t('form.mailformReports.dialog.filtering.message.noConditions')"
              @remove-chip="removeFilter"
              @bottom-link-action="allClearFilter"
            >
            </FilteringChips>
          </v-card>
        </v-col>
      </v-row>
    </Dialog>
  </v-btn>
</template>

<script>
import { defineComponent } from "vue"
import { mdiCloseCircle } from "@mdi/js"
import Dialog from "@/components/common/molecules/BaseDialog.vue"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import FilteringChips from "@/components/MailformReports/molecules/FilteringChips"
import Functions from "@/components/MailformReports/functions.js"
import DatePicker from "@/components/common/organisms/DatePicker.vue"
import MessageArea from "@/components/common/organisms/MessageArea.vue"
import { useMailformReportsStore } from "@/stores/mailform/reports"
export default defineComponent({
  name: "FilteringDialog",
  components: {
    Dialog,
    BaseLabel,
    FilteringChips,
    DatePicker,
    MessageArea,
  },
  props: {
    filteringMasterData: {
      type: Array[Object],
      default: [],
    },
  },
  emits: ["executeFiltering", "refreshFilteringMasterData"],
  setup() {
    const reportsStore = useMailformReportsStore() // レポート画面storeを利用したpiniaの定義

    return {
      reportsStore,
    }
  },
  data() {
    return {
      mdiCloseCircle: mdiCloseCircle,

      inputFilteringItemValue: null, // 選択値（絞り込み項目）

      inputMultiCheckValues: [], // 入力値（マルチチェック検索）

      inputNumericRangeFromValue: "", // 入力値（数値範囲from）
      inputNumericRangeToValue: "", // 入力値（数値範囲to）

      hoursItems: [...Array(24)].map((_, i) => `${i}`), // 時刻(時)選択リスト
      minutesItems: [...Array(60)].map((_, i) => `${i}`), // 時刻(分)選択リスト

      inputDateRangeFromValue: "", // 入力値（期間範囲(日付)from）
      inputHourRangeFromValue: "", // 入力値（期間範囲(時)from）
      inputMinuteRangeFromValue: "", // 入力値（期間範囲(分)from）
      inputDateRangeToValue: "", // 入力値（期間範囲(日付)to）
      inputHourRangeToValue: "", // 入力値（期間範囲(時)to）
      inputMinuteRangeToValue: "", // 入力値（期間範囲(分)to）

      inputTextValue: "", // 入力値（テキスト項目）
      inputTextOperatorValue: 0, // 選択値（テキスト項目 演算子（一致条件）選択値）

      inputIncludeNullCheckValue: false, // 入力値（未入力を含むチェックボックス値）

      temporaryChooseFilteringData: [], // 指定絞り込み条件のダイアログ一時情報

      messages: {},
    }
  },
  computed: {
    /**
     * 絞り込み項目のプルダウン選択肢情報
     */
    filteringItemsData() {
      return this.filteringMasterData.map(data => {
        return { value: data.fieldId, title: data.fieldName }
      })
    },
    /**
     * マルチチェック検索項目のプルダウン選択肢情報
     */
    multiCheckValuesData() {
      return this.filteringMasterData
        .find(data => {
          return this.inputFilteringItemValue === data.fieldId && Functions.isMultiCheckField(data.fieldType)
        })
        ?.multiCheckValues?.map(data => {
          return { value: data?.id, title: data?.value }
        })
    },
    /**
     * テキスト項目の演算子プルダウン選択肢情報
     */
    textOperatorsData() {
      // key/valueの変換テーブル（サーバーから返却されないので、ここで定義）
      const convertingTable = {
        0: this.$i18n.t("form.mailformReports.dialog.filtering.label.contains"),
        1: this.$i18n.t("form.mailformReports.dialog.filtering.label.beginningWith"),
      }

      return this.filteringMasterData
        .find(data => {
          return this.inputFilteringItemValue === data.fieldId && Functions.isTextField(data.fieldType)
        })
        ?.valueOperators?.map(data => {
          return { value: data, title: convertingTable[data] }
        })
    },
    /**
     * マルチチェック検索の入力フィールドを表示するか否か
     */
    visibleMultiCheckField() {
      return Functions.isMultiCheckField(
        this.filteringMasterData.find(data => {
          return this.inputFilteringItemValue === data.fieldId
        })?.fieldType
      )
    },
    /**
     * 数値範囲の入力フィールドを表示するか否か
     */
    visibleNumericRangeField() {
      return Functions.isNumericRangeField(
        this.filteringMasterData.find(data => {
          return this.inputFilteringItemValue === data.fieldId
        })?.fieldType
      )
    },
    /**
     * 期間範囲の入力フィールドを表示するか否か
     */
    visiblePeriodRangeField() {
      return Functions.isPeriodRangeField(
        this.filteringMasterData.find(data => {
          return this.inputFilteringItemValue === data.fieldId
        })?.fieldType
      )
    },
    /**
     * 期間範囲の入力フィールドの時分秒を表示するか否か
     */
    visiblePeriodRangeAndTimeAbleSpecField() {
      return Functions.isPeriodRangeAndTimeAbleSpecField(
        this.filteringMasterData.find(data => {
          return this.inputFilteringItemValue === data.fieldId
        })?.fieldType
      )
    },
    /**
     * テキストの入力フィールドを表示するか否か
     */
    visibleTextField() {
      return Functions.isTextField(
        this.filteringMasterData.find(data => {
          return this.inputFilteringItemValue === data.fieldId
        })?.fieldType
      )
    },
    /**
     * 期間範囲(from)の入力フィールドが入力済みか否か
     */
    inputtedPeriodFrom() {
      return this.inputDateRangeFromValue || this.inputHourRangeFromValue || this.inputMinuteRangeFromValue
    },
    /**
     * 期間範囲(to)の入力フィールドが入力済みか否か
     */
    inputtedPeriodTo() {
      return this.inputDateRangeToValue || this.inputHourRangeToValue || this.inputMinuteRangeToValue
    },
  },
  watch: {},
  methods: {
    /**
     * 絞り込み項目プルダウン変更時の処理。
     * 選択された絞り込み項目の値に応じた入力項目を構築（初期化・復元・表示）する。
     * @param {string} [fieldId] 選択された絞り込み項目の識別子
     */
    changeFilteringItem(fieldId) {
      // 入力項目を初期化する(絞り込み項目は選択値を利用するため初期化対象外とする)
      this.clearInputFields(false)

      // 選択された項目に紐づく絞込み条件が指定済みであれば、それを入力項目に復元する
      this.restoreInputField(fieldId)
    },
    /**
     * 期間範囲の入力項目の内容を初期化する。
     * @param {string} [target] クリアする対象
     */
    clearInputPeriod(target) {
      if ("from" === target) {
        // 期間範囲の入力値を初期化
        this.inputDateRangeFromValue = ""
        this.inputHourRangeFromValue = null
        this.inputMinuteRangeFromValue = null
      } else if ("to" === target) {
        this.inputDateRangeToValue = ""
        this.inputHourRangeToValue = null
        this.inputMinuteRangeToValue = null
      }
    },
    /**
     * 入力項目の内容を初期化する。
     * @param {boolean} [clearFilteringItemsField=true] 絞り込み項目の選択値をクリアするか
     * @param {boolean} [clearMultiCheckField=true] マルチチェック検索項目の選択値をクリアするか
     * @param {boolean} [clearNumericRangeField=true] 数値範囲項目の入力値をクリアするか
     * @param {boolean} [clearPeriodRangeField=true] 日付範囲項目の入力値をクリアするか
     * @param {boolean} [clearTextField=true] テキスト項目の入力値をクリアするか
     */
    clearInputFields(
      clearFilteringItemsField = true,
      clearMultiCheckField = true,
      clearNumericRangeField = true,
      clearPeriodRangeField = true,
      clearTextField = true
    ) {
      if (clearFilteringItemsField) {
        // 項目選択値を初期化
        this.inputFilteringItemValue = null
      }
      if (clearMultiCheckField) {
        // マルチチェック検索の選択値を初期化
        this.inputMultiCheckValues = []
        this.inputIncludeNullCheckValue = false
      }
      if (clearNumericRangeField) {
        // 数値範囲の入力値を初期化
        this.inputNumericRangeFromValue = null
        this.inputNumericRangeToValue = null
        this.inputIncludeNullCheckValue = false
      }
      if (clearPeriodRangeField) {
        // 期間範囲の入力値を初期化
        this.clearInputPeriod("from")
        this.clearInputPeriod("to")
        this.inputIncludeNullCheckValue = false
      }

      if (clearTextField) {
        // テキストの入力値を初期化
        this.inputTextValue = null
        this.inputTextOperatorValue = 0
        this.inputIncludeNullCheckValue = false
      }

      // エラー情報をクリアする
      this.messages = {}
    },

    /**
     * 絞り込み項目の値に応じた入力項目を復元する。
     * @param {string} [fieldId] 絞り込み項目の識別子
     */
    restoreInputField(fieldId) {
      let chooseData = this.temporaryChooseFilteringData.find(chooseData => {
        return chooseData.id === fieldId
      })

      // 該当fieldIdの絞り込み項目が既に指定済みである場合、指定済み条件を入力フィールドに反映する
      if (chooseData) {
        let chooseFieldType = this.filteringMasterData.find(data => {
          return fieldId === data.fieldId
        })?.fieldType

        if (Functions.isMultiCheckField(chooseFieldType)) {
          // 一時情報をマルチチェック検索の選択値へ反映する
          if (chooseData.values?.length > 0) {
            this.inputMultiCheckValues = [...chooseData.values]
          }
        } else if (Functions.isNumericRangeField(chooseFieldType)) {
          // 一時情報を数値範囲の入力値へ反映する
          if (chooseData.values?.[0]?.trim()) {
            this.inputNumericRangeFromValue = chooseData.values[0]
          }
          if (chooseData.values?.[1]?.trim()) {
            this.inputNumericRangeToValue = chooseData.values[1]
          }
        } else if (Functions.isPeriodRangeField(chooseFieldType)) {
          // 一時情報を期間範囲の入力値へ反映する
          if (chooseData.values?.fromDate) {
            this.inputDateRangeFromValue = chooseData.values.fromDate
          }
          if (chooseData.values?.fromHour) {
            this.inputHourRangeFromValue = chooseData.values.fromHour
          }
          if (chooseData.values?.fromMinute) {
            this.inputMinuteRangeFromValue = chooseData.values.fromMinute
          }
          if (chooseData.values?.toDate) {
            this.inputDateRangeToValue = chooseData.values.toDate
          }
          if (chooseData.values?.toHour) {
            this.inputHourRangeToValue = chooseData.values.toHour
          }
          if (chooseData.values?.toMinute) {
            this.inputMinuteRangeToValue = chooseData.values.toMinute
          }
        } else if (Functions.isTextField(chooseFieldType)) {
          // 一時情報をテキストの入力値へ反映する
          if (chooseData.values?.[0]?.trim()) {
            this.inputTextOperatorValue = chooseData.operators
            this.inputTextValue = chooseData.values[0]
          }
        }

        if (chooseData.isIncludeNull) {
          // 未入力チェックボックス値を反映する
          this.inputIncludeNullCheckValue = chooseData.isIncludeNull
        }
      }
    },
    /**
     * 一時情報からの絞り込み要素の除外
     * @param {*} indexOf 除外対象の要素位置
     * @param {*} countOf 除外対象の要素数（基本は1固定）
     * @param {*} chooseData 除外対象の要素数
     * @param {*} existingChooseFilter 除外対象の要素がデータ構成上、削除か/更新か
     */
    removeFilter(indexOf, countOf, chooseData, existingChooseFilter) {
      // 不要になったら(未入力も入力値も無い場合)、fieldIdをkeyに一時情報の要素自体を削除する。不要でない場合は更新する。
      if (existingChooseFilter) {
        // 該当の絞り込み項目データを更新する
        this.temporaryChooseFilteringData.splice(indexOf, 1, chooseData)
      } else {
        // 該当の絞り込み項目データ削除
        this.temporaryChooseFilteringData.splice(indexOf, 1)
      }
    },

    /**
     * ダイアログが開かれた時の処理。
     * 入力項目を初期化し、絞り込みマスター情報取得APIを呼び出す。
     */
    dialogOpenAction() {
      // 開いたときに入力項目を初期化する
      this.clearInputFields()
      // 開いたときに選択済みの絞り込み条件をダイアログ一時情報へ反映する
      this.temporaryChooseFilteringData = JSON.parse(JSON.stringify(this.reportsStore.getFilteringCondition))
      // 開いたときにダイアログで利用する情報をAPIで取得する
      this.$emit("refreshFilteringMasterData")
    },
    /**
     * ダイアログが閉じられた時の処理。
     * 入力項目及びダイアログ一時情報を初期化する。
     */
    dialogCloseAction() {
      // 閉じたときに入力項目を初期化する
      this.clearInputFields()
    },
    /**
     * 「追加ボタン」押下時の検証を行う
     * 検証結果に応じたメッセージを表示する。
     * 検証結果をreturnする
     * @param {*} chooseField
     * @returns 検証結果（true/false）
     */
    validateAddCondition(chooseField) {
      this.messages = {}
      // 絞り込み項目が50以下であること
      const maxFilterItemCount = 50
      if (
        this.temporaryChooseFilteringData.length >= maxFilterItemCount &&
        !this.temporaryChooseFilteringData.some(data => {
          return chooseField?.fieldId == data.id
        })
      ) {
        // 絞り込み条件は50項目以内で指定してください。
        this.messages = {
          error: [
            this.$i18n.t("form.mailformReports.dialog.filtering.validate.maxFilter", {
              maxCount: maxFilterItemCount,
            }),
          ],
        }
        return false
      }

      if (Functions.isNumericRangeField(chooseField?.fieldType)) {
        // 数値範囲の検証１． from <= to であること
        if (this.inputNumericRangeFromValue?.trim() && this.inputNumericRangeToValue?.trim()) {
          if (Number(this.inputNumericRangeFromValue?.trim()) > Number(this.inputNumericRangeToValue?.trim())) {
            // 数値範囲(From)は数値範囲(To)以下の値を入力してください。
            this.messages = {
              error: [
                this.$i18n.t("form.mailformReports.dialog.filtering.validate.numericRange", {
                  fieldName: chooseField?.fieldName,
                }),
              ],
            }
            return false
          }
        }
      } else if (Functions.isPeriodRangeField(chooseField?.fieldType)) {
        // 期間範囲の検証１． 日時として正しいこと（年月日 ⇒ 時 ⇒ 分 と上位条件が指定されている） であること
        // 期間範囲の検証２． from <= to であること
        if (
          this.inputDateRangeFromValue ||
          this.inputHourRangeFromValue ||
          this.inputMinuteRangeFromValue ||
          this.inputDateRangeToValue ||
          this.inputHourRangeToValue ||
          this.inputMinuteRangeToValue
        ) {
          //
          if (
            (this.inputMinuteRangeFromValue && !this.inputHourRangeFromValue) ||
            (this.inputHourRangeFromValue && !this.inputDateRangeFromValue)
          ) {
            // Fromの、分か指定されているのに時が未指定
            // Fromの、時か指定されているのに日付が未指定
            // 期間範囲(From)は、年から続けて入力してください。
            this.messages = {
              error: [
                this.$i18n.t("form.mailformReports.dialog.filtering.validate.periodFrom", {
                  fieldName: chooseField?.fieldName,
                }),
              ],
            }
            return false
          }
          if (
            (this.inputMinuteRangeToValue && !this.inputHourRangeToValue) ||
            (this.inputHourRangeToValue && !this.inputDateRangeToValue)
          ) {
            // Toの、分か指定されているのに時が未指定
            // Toの、時か指定されているのに日付が未指定
            // 期間範囲(To)は、年から続けて入力してください。
            this.messages = {
              error: [
                this.$i18n.t("form.mailformReports.dialog.filtering.validate.periodTo", {
                  fieldName: chooseField?.fieldName,
                }),
              ],
            }
            return false
          }

          // Todo: APIで処理実装までの間フロントの検証機能で下記条件を抑制する暫定対応とする
          if (!this.inputDateRangeFromValue && this.inputDateRangeToValue) {
            // to が入力されている場合、from を入力してください。
            this.messages = {
              error: [
                `${chooseField?.fieldName}(To)が入力されている場合、${chooseField?.fieldName}(From)を入力してください。`,
              ],
            }
            return false
          }

          if (this.inputDateRangeFromValue && this.inputDateRangeToValue) {
            // from to が共に入力されている場合、期間範囲はfrom <= to であること
            let fromPeriod = this.$dayjs(this.inputDateRangeFromValue)
              .add(this.inputHourRangeFromValue, "h")
              .add(this.inputMinuteRangeFromValue, "m")
            let toPeriod = this.$dayjs(this.inputDateRangeToValue)
              .add(this.inputHourRangeToValue, "h")
              .add(this.inputMinuteRangeToValue, "m")
            if (fromPeriod.isAfter(toPeriod)) {
              this.messages = {
                error: [
                  this.$i18n.t("form.mailformReports.dialog.filtering.validate.periodRange", {
                    fieldName: chooseField?.fieldName,
                  }),
                ],
              }
              return false
            }
          }
        }
      }
      return true
    },
    /**
     * ダイアログの追加ボタン押下時の処理。
     * 入力項目の値を一時情報へ反映する。
     */
    additionalFilter() {
      let chooseField = this.filteringMasterData.find(data => {
        return this.inputFilteringItemValue === data.fieldId
      })
      if (!this.validateAddCondition(chooseField)) {
        // バリデーションエラーの場合は処理中断
        return
      }
      let chooseData = {
        id: this.inputFilteringItemValue,
        operators: null,
        isIncludeNull: false,
        values: [],
      }
      let hasAdditions = false

      if (Functions.isMultiCheckField(chooseField?.fieldType)) {
        // マルチチェック検索の選択値を一時情報へ反映する
        if (this.inputMultiCheckValues?.length > 0) {
          chooseData.values = [...this.inputMultiCheckValues]
          hasAdditions = true
        }
      } else if (Functions.isNumericRangeField(chooseField?.fieldType)) {
        // 数値範囲の入力値を一時情報へ反映する
        if (this.inputNumericRangeFromValue?.trim() || this.inputNumericRangeToValue?.trim()) {
          chooseData.values = [this.inputNumericRangeFromValue?.trim(), this.inputNumericRangeToValue?.trim()]
          hasAdditions = true
        }
      } else if (Functions.isPeriodRangeField(chooseField?.fieldType)) {
        // 期間範囲の入力値を一時情報へ反映する
        if (
          this.inputDateRangeFromValue ||
          this.inputHourRangeFromValue ||
          this.inputMinuteRangeFromValue ||
          this.inputDateRangeToValue ||
          this.inputHourRangeToValue ||
          this.inputMinuteRangeToValue
        ) {
          chooseData.values = {
            fromDate: this.inputDateRangeFromValue,
            fromHour: this.inputHourRangeFromValue,
            fromMinute: this.inputMinuteRangeFromValue,
            toDate: this.inputDateRangeToValue,
            toHour: this.inputHourRangeToValue,
            toMinute: this.inputMinuteRangeToValue,
          }
          hasAdditions = true
        }
      } else if (Functions.isTextField(chooseField?.fieldType)) {
        // テキストの入力値を一時情報へ反映する
        if (this.inputTextValue?.trim()) {
          chooseData.operators = this.inputTextOperatorValue
          chooseData.values = [this.inputTextValue?.trim()]
          hasAdditions = true
        }
      }

      if (hasAdditions || this.inputIncludeNullCheckValue) {
        chooseData.isIncludeNull = this.inputIncludeNullCheckValue
        // 一時情報の該当fieldIdの情報を検索
        let indexOf = this.temporaryChooseFilteringData.indexOf(
          this.temporaryChooseFilteringData.find(data => {
            return data.id === this.inputFilteringItemValue
          })
        )
        if (indexOf < 0) {
          // 一時情報に該当fieldIdの情報がない場合、末尾に新規追加
          this.temporaryChooseFilteringData.push(chooseData)
        } else {
          // 一時情報に該当fieldIdの情報がある場合、それを更新
          this.temporaryChooseFilteringData.splice(indexOf, 1, chooseData)
        }
      }

      // 追加押下時に入力項目を初期化する
      // this.clearInputFields()
    },

    /**
     * ダイアログの絞り込みボタン押下時の処理。
     * ダイアログ一時情報を元画面に反映し、絞り込みマスター情報取得APIを呼び出す。
     */
    executeFiltering() {
      this.$emit("executeFiltering", this.temporaryChooseFilteringData)
    },

    /**
     * 「すべての条件をクリア」リンク押下時の処理。
     */
    allClearFilter() {
      this.temporaryChooseFilteringData = []
    },

    /**
     * 本ダイアログ（絞り込みダイアログ）を表示する。
     * ダイアログを直接開きたい場合に呼び出すメソッド
     */
    openDialogDirectly() {
      this.$refs.dialog.open()
    },
  },
})
</script>

<style lang="scss" scoped>
.card-area {
  background-clip: padding-box;
  border: 1px solid rgba(var(--v-theme-baseFontColor), 0.5);
}
.dialog-button {
  min-width: 200px;
}

.include-null-checkbox {
  :deep(.v-label--clickable) {
    height: 25px;
    color: rgb(var(--v-theme-baseFontColor));
    opacity: 1;
  }
  :deep(.v-selection-control__input) {
    height: 25px;
  }
}
// .time-label {
//   display: flex;
//   align-items: flex-end;
// }
</style>
