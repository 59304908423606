<template>
  <v-main>
    <MailformSummaryTemplate
      :auto-reload-flag="autoReloadFlag"
      :auto-reload-interval="autoReloadInterval"
      :base-datetime="baseDatetime"
      :category-infos="categoryInfos"
      :current-category-index="currentCategoryIndex"
      :current-category-name="currentCategoryName"
      :report-list="reportList"
      :has-reports="hasReports"
      :is-loading="isLoading"
      :message="message"
      :message-type="messageType"
      @reload="reload"
      @auto-reload="autoReload"
      @change-active-category="changeActiveCategory"
      @updated-base-datetime="fetchCategoryInfo(false)"
      @updated-before-base-datetime-renewed="updatedBeforeBaseDatetimeRenewed"
    />

    <!-- 基準日時更新検出確認ダイアログ  -->
    <Dialog
      ref="renewBaseDatetimeConfirmDialog"
      :title="$t('parts.dialog.title.confirmation')"
      :cancel-btn-label="$t('parts.dialog.button.cancel')"
      :okay-btn-label="$t('parts.dialog.button.ok')"
      @before-okay-event="reload(false)"
    >
      <BaseLabel>
        {{ $t("form.mailformReports.dialog.renewBaseDatetimeConfirmation.message.bodyToBaseDatetime") }}
      </BaseLabel>
      <BaseLabel>
        {{ $t("form.mailformReports.dialog.renewBaseDatetimeConfirmation.message.promptToBaseDatetime") }}
      </BaseLabel>
    </Dialog>

    <!-- 基準日時更新検出通知ダイアログ  -->
    <Dialog
      ref="renewBaseDatetimeInformationDialog"
      :title="$t('parts.dialog.title.announcement')"
      :okay-btn-label="false"
    >
      <BaseLabel>
        {{ $t("form.mailformReports.dialog.renewBaseDatetimeInformation.message.body") }}
      </BaseLabel>
    </Dialog>
  </v-main>
</template>

<script>
import MailformSummaryTemplate from "@/components/MailformSummary/templates/MailformSummaryTemplate.vue"
import client from "@/api/client"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import Dialog from "@/components/common/molecules/BaseDialog.vue"

export default {
  name: "MailformSummary",
  components: {
    MailformSummaryTemplate,
    BaseLabel,
    Dialog,
  },
  data() {
    return {
      baseDatetime: "",

      autoReloadFlag: false,
      autoReloadInterval: 1, // 単位(分)
      autoReloadTimer: 0, // ページを移動した際に削除するため更新タイマーを宣言

      categoryInfos: [],
      currentCategoryId: "",
      currentCategoryIndex: 0,
      currentCategoryName: "",
      reportList: [],

      /*
        サマリー画面右側(レポート被害判定状況一覧)に表示するレポート有無の判定フラグです。
        レポート表示するコンポーネントファイル内で引数(reportList)の要素数チェックを使わずに、このフラグを使用の理由は以下に記述します。
        API実行完了のタイミングの影響を避けたいため、引数(hasReports)を使用しました。
        画面表示の初期段階の時、引数(reportList)の要素数が0でした。
        MailformSummaryReports.vue内でreportListの要素数をチェックして使用の場合、
        API実行完了まで画面に”レポートが設定されていません”のメッセージが表示されてしまいます。
      */
      hasReports: true,

      isLoading: false,
      message: "",
      messageType: "",
    }
  },
  watch: {
    $route() {
      // 同画面(同path)遷移を検出し、再読み込みを行う
      location.reload()
    },
  },
  created() {
    this.fetchCategoryInfo(false)
  },
  unmounted() {
    // ページを移動した際（コンポーネントインスタンスがアンマウントされた際）に更新タイマーを削除
    clearInterval(this.autoReloadTimer)
  },
  methods: {
    /**
     * カテゴリ情報リスト取得
     */

    fetchCategoryInfo(isCheckRenewedBaseDatetime = true) {
      this.isLoading = true
      this.message = ""
      this.messageType = ""

      this.hasReports = true
      this.currentCategoryName = ""
      this.reportList = []

      client
        .getMailformSummary()
        .then(res => {
          // APIの結果を設定する
          let beforeBaseDatetime = this.baseDatetime // APIの結果を設定する前に、画面に表示されていた基準日時を退避する
          this.baseDatetime = res.data.baseDateTime
          this.categoryInfos = res.data.mailformSummaryContent.map(content => {
            let reportTotalCount = content.reports?.length
            // 被害レポート数（エラー(EMC安否連携エラー/集計エラー)以外で、被害判定条件が被害ありの場合、被害レポートとして計上する）
            // 現状のレスポンスボディのエラー判定状態は以下の通り
            //  集計エラーかつEMC安否エラー「"summaryErrorFlag": 1」（emcAnpiSummaryErrorFlagは未定義）※summaryErrorFlagが1の時にはemcAnpiSummaryErrorFlagは評価されていない
            //  集計エラーかつEMC安否正常「"summaryErrorFlag": 1」（emcAnpiSummaryErrorFlagは未定義）※summaryErrorFlagが1の時にはemcAnpiSummaryErrorFlagは評価されていない
            //  集計正常かつEMC安否エラー「"emcAnpiSummaryErrorFlag": 1」（summaryErrorFlagは未定義）※summaryErrorFlagが0であることが確定済み
            //  集計正常かつEMC安否正常「"emcAnpiSummaryErrorFlag": 0」（summaryErrorFlagは未定義）※summaryErrorFlagが0であることが確定済み
            let damagedCount = content.reports?.filter(report => {
              return (
                report.damageConditionBreakdown?.emcAnpiSummaryErrorFlag != 1 &&
                report.damageConditionBreakdown?.summaryErrorFlag != 1 &&
                report.damageConditionBreakdown?.damagedCount > 0
              )
            }).length
            // エラー(EMC安否連携エラー/集計エラー)のレポート数
            let emcErrorCount = content.reports?.filter(report => {
              return (
                report.damageConditionBreakdown?.emcAnpiSummaryErrorFlag == 1 ||
                report.damageConditionBreakdown?.summaryErrorFlag == 1
              )
            }).length

            return {
              categoryId: content.categoryId,
              categoryName: content.categoryName,
              reportTotalCount: reportTotalCount,
              damagedCount: damagedCount,
              emcErrorCount: emcErrorCount,
              src: this.getCategoryIconPath(damagedCount, reportTotalCount),
              description: this.getCategoryDescription(damagedCount, reportTotalCount),
              reports: content.reports,
              status: content.status,
              message: content.message,
            }
          })

          // アクティブカテゴリの情報を設定する（画面の右辺情報）
          this.currentCategoryIndex = this.categoryInfos.findIndex(content => {
            return this.currentCategoryId == content.categoryId
          })
          if (this.currentCategoryIndex == -1) {
            // 選択カテゴリがAPI取得結果の中に無い場合（最初の呼び出し時等）、選択カテゴリをAPI取得結果の１つ目のものとする
            this.currentCategoryIndex = 0
          }

          const currentCategoryInfo = this.categoryInfos[this.currentCategoryIndex]
          this.currentCategoryId = currentCategoryInfo.categoryId
          this.currentCategoryName = currentCategoryInfo.categoryName
          this.reportList = currentCategoryInfo.reports
          this.hasReports = this.checkReportNotEmpty(this.reportList)

          // 当APIではAPIレスポンス直下のメッセージは破棄する（利用しない）こと （202401時点のAPI仕様に基づき）
          // アクティブカテゴリ別のメッセージを表示対象として設定する
          this.message = currentCategoryInfo.status != "success" ? currentCategoryInfo.message : ""
          this.messageType = currentCategoryInfo.status

          // 基準日時変更を検出したときに通知ダイアログを表示する
          if (
            isCheckRenewedBaseDatetime &&
            !this.$dayjs(beforeBaseDatetime).isSame(this.$dayjs(res.data.baseDateTime))
          ) {
            this.$refs.renewBaseDatetimeInformationDialog.open()
          }
        })
        .catch(err => {
          this.isLoading = false
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getCategoryIconPath(damagedCount, totalCount) {
      if (damagedCount === 0) {
        return require("@/assets/images/damage_status_non.svg")
      } else {
        return require(damagedCount === totalCount
          ? "@/assets/images/damage_status_all.svg"
          : "@/assets/images/damage_status_segment.svg")
      }
    },

    getCategoryDescription(damagedCount, reportTotalCount) {
      if (damagedCount == 0) {
        return this.$i18n.t(`form.mailformSummary.message.categoryDamageInfo.noDamage`).split(",")
      } else {
        return this.$i18n
          .t(`form.mailformSummary.message.categoryDamageInfo.damaged`, {
            damagedCount: damagedCount,
            totalCount: reportTotalCount,
          })
          .split(",")
      }
    },

    /**
     * 自動更新の切替を行う.
     * @param newFlag 新しい自動更新状態
     *
     */
    autoReload(newFlag) {
      this.autoReloadFlag = newFlag
      if (this.autoReloadFlag) {
        // 更新タイマーON
        this.autoReloadTimer = setInterval(() => {
          this.reload()
        }, this.autoReloadInterval * 60 * 1000)
      } else {
        // 更新タイマー削除
        clearInterval(this.autoReloadTimer)
      }
    },

    /**
     * 更新を行う.
     *
     */
    reload(isCheckRenewedBaseDatetime = true) {
      // サマリー画面更新または自動更新時の処理
      this.fetchCategoryInfo(isCheckRenewedBaseDatetime)
    },

    /**
     * アクティブカテゴリ変更際のイベント処理
     * @param {String} newCategoryId
     */
    changeActiveCategory(newCategoryId) {
      this.categoryInfos = []
      this.reportList = []
      this.currentCategoryName = ""
      this.currentCategoryId = newCategoryId
      this.fetchCategoryInfo()
    },

    /**
     * レポートリストが空かどうかを判定する
     * @param {Array[Object]} reportList
     */
    checkReportNotEmpty(reportList) {
      for (let i in reportList) {
        return true
      }
      return false
    },

    /**
     * 基準日時変更ダイアログを開いたときに基準日時が更新されていた場合の処理
     */
    updatedBeforeBaseDatetimeRenewed() {
      this.$refs.renewBaseDatetimeConfirmDialog.open()
    },
  },
}
</script>

<style lang="scss">
.content-area {
  // background-color: rgb(var(--v-theme-primary));
  padding: 10px;
}
</style>
