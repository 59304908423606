import { defineStore } from "pinia"
import { storageKey } from "@/stores/storageKey"
//別のストアをインポート
import { useMailformReportsStore } from "@/stores/mailform/reports"

const id = "common" // idはpinia(システム全体)で一意にする
const defaultPerPage = 100
//defineStore() を使ってストアを定義し、名前を付けてエクスポートすることで.vueから利用可能
//名前は use{Xxxx}Store が慣例 ※{Xxxx}はidと同一にすると管理が楽
export const useCommonStore = defineStore(id, {
  id: id,
  // stateでデータ定義する（初期値もここで設定している）
  state: () => ({
    paginate: {
      perPage: defaultPerPage, // 一覧画面の表示件数
    },
  }),
  // gettersはstateの値を算出した値を取得するゲッター（算出プロパティ）
  getters: {
    getPerPage: state => state.paginate.perPage,
  },
  // actionsはstateの値にデータを設定するセッターとして利用する
  // 実態はAPIのmethods オプションに相当するためビジネスロジック的な処理を記述可能
  actions: {
    changePerPage(value) {
      this.paginate.perPage = value
    },
    initPerPage() {
      this.changePerPage(defaultPerPage)
    },
    /**
     * エラー発生時に要削除のpiniaデータをまとめて削除する処理
     */
    clearIsNecessaryOnError() {
      // TODO：今後ローディング等をpiniaを利用して実装したとき、ローディング中にエラーになった場合の状態を強制変更（解除状態に）する等。必要に応じて追加
      useMailformReportsStore().clearTransitionParams() // レポート画面への遷移時パラメータの初期化
    },
  },
  // 永続化
  persist: {
    storage: sessionStorage,
    key: `${storageKey}-${id}`,
  },
})
