<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        class="category-btn small-button-label text-none px-12 py-6 mx-5 my-1"
        style="align-content: center"
      >
        <span class="monospaced-font" :style="'width:' + categoryWidth(category.title) + 'em'">
          {{ category.title }}
        </span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(report, idx) in category.reports" :key="idx" :value="idx">
        <v-list-item-title class="small-title-label" @click="$emit('chooseReport', report.account)">{{
          report.title
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  emits: ["chooseReport"],

  data() {
    return {}
  },

  methods: {
    categoryWidth(str) {
      const maxWidth = 15 // 横幅の最大文字数
      const fullCharWidth = 1 // 全角文字の横幅
      const halfCharWidth = 0.64 // 半角文字の横幅
      let len = 0 // 全体の横幅の計
      let currentLineLen = 0 // 現在の行の横幅
      let longestLineLen = 0 // 横幅が最も長い行

      for (let i = 0; i < str.length; i++) {
        let additionalLen = str[i].match(/[ -~]/) ? halfCharWidth : fullCharWidth
        currentLineLen += additionalLen
        len += additionalLen

        if ((i + 1) % maxWidth === 0) {
          // 文字数が１行の最大文字数を超えたタイミングでその行の最大文字を退避
          longestLineLen = currentLineLen > longestLineLen ? currentLineLen : longestLineLen
          currentLineLen = 0
        }
      }
      longestLineLen = currentLineLen > longestLineLen ? currentLineLen : longestLineLen
      return str.length <= maxWidth ? len + 1.5 : longestLineLen * 1.1
    },
  },
})
</script>

<style lang="scss" scoped>
.category-btn {
  color: rgb(var(--v-theme-primary));

  :deep(.v-btn__content) {
    grid-area: content;
    justify-content: center;
    white-space: normal;
    word-break: break-all;
  }
}
</style>
