// import VueDayjs from "vue3-dayjs-plugin"

import dayjs from "dayjs"
import "dayjs/locale/ja"
// import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

// ロケール設定
dayjs.locale("ja")

// 使用するプラグインを追加
// dayjs.extend(require("dayjs/plugin/isBetween"))
// dayjs.extend(require("dayjs/plugin/isSameOrAfter"))
// dayjs.extend(require("dayjs/plugin/isAfter"))
// dayjs.extend(require("dayjs/plugin/isSameOrBefore"))
// dayjs.extend(isSameOrBefore)

export default dayjs
