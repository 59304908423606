<template>
  <v-menu v-model="isMenuOpen" :close-on-content-click="false" density="compact">
    <template #activator="{ props: activatorProps }">
      <v-text-field
        :model-value="formattedDate"
        readonly
        v-bind="activatorProps"
        variant="outlined"
        hide-details
        :append-inner-icon="mdiCalendar"
        density="compact"
        :style="inputFieldStyle"
        :class="inputFieldClass"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedDate"
      title=""
      :input-text="formattedDate"
      format="yyyy-MM-dd"
      locale="jp-ja"
      @click:cancel="isMenuOpen = false"
    >
      <template #header></template>
    </v-date-picker>
  </v-menu>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from "vue"
import { mdiCalendar } from "@mdi/js"

const props = defineProps({
  modelValue: { type: String, default: "" },
  inputFieldClass: { type: String, default: "" },
  inputFieldStyle: { type: String, default: "" },
})
const selectedDate = ref()

let isMenuOpen = ref(false)
const emit = defineEmits("update:modelValue")

const formattedDate = computed(() => {
  return getFormatDate(props.modelValue)
})

watch(selectedDate, newDate => {
  isMenuOpen = ref(false)
  emit("update:modelValue", getFormatDate(newDate))
})

function getFormatDate(date) {
  if (date == "") {
    return ""
  } else {
    let dateObj = new Date(date)
    let year = dateObj.getFullYear()
    let month = dateObj.getMonth() + 1
    let day = dateObj.getDate()
    return year + "/" + ("00" + month.toString()).slice(-2) + "/" + ("00" + day.toString()).slice(-2)
  }
}
</script>
