<template>
  <div class="content-area">
    <!-- 被害判定項目領域 [ レポートタイトル / 被害判定グラフ  / 被害判定項目表示ダイアログ ] -->
    <ReportDamageCondition
      :report-title="title"
      :report-account-name="$route.params.reportAccountName"
      :damaged-count="damagedCount"
      :no-damage-count="noDamageCount"
      :no-report-count="noReportCount"
      :message="message"
      :message-type="messageType"
      :has-graph-error="hasGraphError"
      class="ml-3"
      @click-damage-condition="graphClick"
      @show-damage-condition="showDamageCondition"
    />
    <!-- 被害判定グラフ押下時、確認ダイアログ  -->
    <Dialog
      ref="confirmDialog"
      :title="$t('parts.dialog.title.confirmation')"
      :cancel-btn-label="$t('parts.dialog.button.cancel')"
      :okay-btn-label="$t('parts.dialog.button.show')"
      @before-okay-event="searchByDamageCondition"
    >
      <BaseLabel>
        {{ $t("form.mailformReports.dialog.filteringConfirmation.message.prefix") }}
        <font class="damage-condition-label" :class="chooseDamageConditionClass">{{ chooseDamageConditionTitle }}</font>
        {{ $t("form.mailformReports.dialog.filteringConfirmation.message.suffix") }}
      </BaseLabel>
      <BaseLabel>
        {{ $t("form.mailformReports.dialog.filteringConfirmation.message.last") }}
      </BaseLabel>
    </Dialog>

    <v-row dense class="mb-1">
      <v-col cols="5" class="pr-4" style="min-width: 350px">
        <v-card variant="outlined" class="card-area py-1 px-2">
          <v-row justify="start" align="center">
            <v-col cols="1" style="min-width: 100px">
              <FilteringDialog
                ref="filteringDialog"
                :filtering-master-data="filteringMasterData"
                @execute-filtering="executeFiltering"
                @refresh-filtering-master-data="$emit('refreshFilteringMasterData')"
              />
            </v-col>
            <v-col>
              <v-card-text class="body-label pa-0">
                <FilteringChips
                  :filtering-condition="reportsStore.getFilteringCondition"
                  :filtering-master-data="filteringMasterData"
                  omit-exceeds="3"
                  @remove-chip="removeFilter"
                  @bottom-link-action="openFilteringDialog"
                >
                </FilteringChips>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="5" class="pr-4" style="min-width: 350px">
        <v-card variant="outlined" class="card-area py-1 px-2">
          <v-row justify="start" align="center">
            <v-col cols="1" style="min-width: 100px">
              <SelectItemDialog />
            </v-col>
            <v-col>
              <v-card-text class="body-label pa-0">
                <template v-if="reportsStore.isShowAll">
                  {{ $t("form.mailformReports.label.showItems.all") }}
                </template>
                <template v-else>
                  {{
                    $t("form.mailformReports.label.showItems.selected", {
                      selectedCount: reportsStore.getSelectedShowItems.length,
                      totalCount: reportsStore.getShowMasterItemList.length + reportsStore.getShowFixedItemList.length,
                    })
                  }}
                </template>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import BaseLabel from "@/components/common/atoms/BaseLabel"
import ReportDamageCondition from "@/components/common/organisms/ReportDamageCondition.vue"
import FilteringDialog from "@/components/MailformReports/organisms/FilteringDialog"
import FilteringChips from "@/components/MailformReports/molecules/FilteringChips"
import Dialog from "@/components/common/molecules/BaseDialog.vue"
import SelectItemDialog from "@/components/MailformReports/organisms/SelectShowItemDialog.vue"
import { useMailformReportsStore } from "@/stores/mailform/reports"

export default defineComponent({
  name: "MailformReportsSearchArea",
  components: {
    BaseLabel,
    ReportDamageCondition,
    FilteringDialog,
    FilteringChips,
    Dialog,
    SelectItemDialog,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    damagedCount: {
      //被害ありの件数
      type: Number,
      default: 0,
    },
    noDamageCount: {
      //被害なしの件数
      type: Number,
      default: 0,
    },
    noReportCount: {
      //未報告の件数
      type: Number,
      default: 0,
    },
    filteringMasterData: {
      type: Array[Object],
      default: [],
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String,
      default: "",
    },
    hasGraphError: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "showDamageCondition",
    "executeFiltering",
    "removeFilter",
    "refreshFilteringMasterData",
    "searchByDamageCondition",
  ],
  setup() {
    const reportsStore = useMailformReportsStore() // レポート画面storeを利用したpiniaの定義

    return {
      reportsStore,
    }
  },
  data() {
    return {
      chooseDamageCondition: "", // 被害判定グラフの選択値「noDamage/Damaged/noReport」
    }
  },
  computed: {
    chooseDamageConditionClass() {
      switch (this.chooseDamageCondition) {
        case "1": // TODO：コード体系は定数化などで定義したい「1:被害あり,2:被害なし,3:確認中」
          return "damaged"
        case "2":
          return "no-damage"
        case "3":
          return "no-report"
        default:
          return ""
      }
    },
    chooseDamageConditionTitle() {
      switch (this.chooseDamageCondition) {
        case "1": // TODO：コード体系は定数化などで定義したい「1:被害あり,2:被害なし,3:確認中」
          return this.$i18n.t("parts.common.label.damageCondition.damaged")
        case "2":
          return this.$i18n.t("parts.common.label.damageCondition.noDamage")
        case "3":
          return this.$i18n.t("parts.common.label.damageCondition.noReport")
        default:
          return ""
      }
    },
  },
  methods: {
    openFilteringDialog() {
      this.$refs.filteringDialog.openDialogDirectly()
    },
    /**
     * 被害判定グラフをクリックされた際のイベント
     * 被害判定棒グラフをクリックされた際絞り込みデータを取得する
     * @param {String} key
     */
    graphClick(key) {
      this.chooseDamageCondition = key // 被害判定グラフの選択値「1:被害あり,2:被害なし,3:確認中」
      if (this.reportsStore.getFilteringCondition?.length > 0) {
        // 絞り込み条件が指定されていた場合にダイアログを表示し、OKの時のみ後続の処理を実行する
        this.$refs.confirmDialog.open()
      } else {
        // 絞り込み条件が指定されていない場合、後続の処理を実行する
        this.searchByDamageCondition()
      }
    },
    /**
     * 確認ダイアログの「OK」ボタン押下時に呼ばれる処理。
     * グラフ押下時の絞り込みリセットの確認ダイアログ。
     */
    searchByDamageCondition() {
      this.$emit("searchByDamageCondition", this.chooseDamageCondition)
    },
    // 被害判定項目表示イベント
    showDamageCondition() {
      this.$emit("showDamageCondition")
    },
    // 絞り込み実施イベント
    executeFiltering(value) {
      this.$emit("executeFiltering", value)
    },
    // チップ閉じた時の処理（検索条件を除外する）
    removeFilter(indexOf, countOf, chooseData, existingChooseFilter) {
      this.$emit("removeFilter", indexOf, countOf, chooseData, existingChooseFilter)
    },
  },
})
</script>

<style lang="scss" scoped>
.content-area {
  padding: 10px 20px 10px 20px;
  z-index: -1;
}
.card-area {
  background-clip: padding-box;
  border: 1px solid rgba(var(--v-theme-baseFontColor), 0.5);
}
.damage-graph-area {
  border: 1px solid;
  border-color: rgb(var(--v-theme-primary));
  width: fit-content;
  padding-right: 5px;
  border-radius: 9999px;
  flex-wrap: nowrap;
}

.damage-condition-label.damaged {
  color: rgb(var(--v-theme-damageConditionDamaged));
}
.damage-condition-label.no-damage {
  color: rgb(var(--v-theme-damageConditionNoDamage));
}
.damage-condition-label.no-report {
  color: rgb(var(--v-theme-damageConditionNoReport));
}
</style>
