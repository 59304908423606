<template>
  <div v-if="isLoading">
    <v-progress-linear :absolute="absolute" indeterminate :color="color" height="5" />
    <v-overlay v-model="showOverlay" persistent></v-overlay>
  </div>
</template>

<script>
export default {
  name: "LoadingOverlay",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      showOverlay: this.overlay,
    }
  },
  computed: {},
  watch: {},
}
</script>

<style leng="scss" scoped>
.v-overlay {
  opacity: 0;
}
</style>
