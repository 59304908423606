<template>
  <template v-if="emptyLabel && filteringChipsData?.length <= 0">
    <BaseLabel class="pa-2" style="opacity: 0.5">
      {{ emptyLabel }}
    </BaseLabel>
  </template>
  <template v-else>
    <template v-for="(chipData, index) in filteringChipsData" :key="index">
      <template v-if="!omitExceeds || index < omitExceeds">
        <BaseChip :text="chipData.itemTitle + ' / ' + chipData.valueTitle" variant="outlined" :item-key="index">
          <template #append>
            <span @click="closeChip(chipData.fieldId, chipData.valueId, chipData.fieldType, chipData.isIncludeNull)">
              <v-icon :icon="mdiCloseCircle" />
            </span>
          </template>
        </BaseChip>
      </template>
    </template>

    <div style="text-align: right; align-items: center">
      <template v-if="!omitExceeds">
        <v-btn variant="text" color="fontLink" class="link-btn body-label" @click.stop="$emit('bottomLinkAction')">
          {{ $t("form.mailformReports.dialog.filtering.button.allClear") }}
        </v-btn>
      </template>
      <template v-else-if="filteringChipsData.length > omitExceeds">
        <v-btn
          variant="text"
          color="fontLinkSecondary"
          class="link-btn x-small-body-label"
          @click.stop="$emit('bottomLinkAction')"
        >
          <v-icon :icon="mdiTriangle" class="rotate-icon pr-1" size="small" />
          {{ $t("form.mailformReports.button.Others", { count: filteringChipsData.length - omitExceeds }) }}
        </v-btn>
      </template>
    </div>
  </template>
</template>

<script>
import { mdiCloseCircle, mdiTriangle } from "@mdi/js"
import BaseChip from "@/components/common/atoms/BaseChip"
import Functions from "@/components/MailformReports/functions.js"
export default {
  name: "FilteringChips",
  components: {
    BaseChip,
  },
  props: {
    filteringCondition: {
      type: Array[Object],
      default: [],
    },
    filteringMasterData: {
      type: Array[Object],
      default: [],
    },
    omitExceeds: {
      // true(数字)：本数値を超過する場合、省略表示とする
      // false：全てのchipを表示する
      type: [Boolean, Number],
      default: false,
    },
    emptyLabel: {
      // true(文字列)：chipが空の場合に本文字列を表示する
      // false：chipの場合何も表示しない
      type: [Boolean, String],
      default: false,
    },
  },
  emits: ["bottomLinkAction", "removeChip"],
  data() {
    return {
      mdiCloseCircle: mdiCloseCircle,
      mdiTriangle: mdiTriangle,
      // isShow: true,
    }
  },
  computed: {
    /**
     * 選択絞り込み条件のチップ表示情報
     */
    filteringChipsData() {
      let chipsData = []
      this.filteringCondition.forEach(chooseData => {
        let fieldData = this.filteringMasterData.find(data => {
          return chooseData.id === data.fieldId
        })

        if (Functions.isMultiCheckField(fieldData?.fieldType)) {
          chooseData.values.forEach(value => {
            let chipData = {
              itemTitle: fieldData?.fieldName,
              valueTitle: fieldData?.multiCheckValues?.find(el => el.id === value)?.value,
              fieldId: fieldData?.fieldId,
              valueId: value,
              fieldType: fieldData?.fieldType,
              isIncludeNull: false,
            }
            chipsData.push(chipData)
          })
        } else if (Functions.isNumericRangeField(fieldData?.fieldType)) {
          // 数値範囲は一つのチップでまとめて表示「from ～ to」
          if (chooseData.values?.[0]?.trim() || chooseData.values?.[1]?.trim()) {
            // 数値範囲入力項目が全て空の場合、chip表示対象外
            let valueTitle = (chooseData.values[0]?.trim() || "") + " ～ " + (chooseData.values[1]?.trim() || "")
            let chipData = {
              itemTitle: fieldData?.fieldName,
              valueTitle: valueTitle,
              fieldId: fieldData?.fieldId,
              valueId: null,
              fieldType: fieldData?.fieldType,
              isIncludeNull: false,
            }
            chipsData.push(chipData)
          }
        } else if (Functions.isPeriodRangeField(fieldData?.fieldType)) {
          // 期間範囲は一つのチップでまとめて表示「from ～ to」
          if (chooseData.values != null && Object.keys(chooseData.values).length > 0) {
            // 期間範囲入力項目が全て空の場合、chip表示対象外
            let valueTitle =
              (this.$dayjs(chooseData.values.fromDate).isValid()
                ? this.$dayjs(chooseData.values.fromDate).format("YYYY/MM/DD")
                : "") +
              (chooseData.values.fromHour ? " " + ("00" + chooseData.values.fromHour.toString()).slice(-2) : "") +
              (chooseData.values.fromMinute ? ":" + ("00" + chooseData.values.fromMinute.toString()).slice(-2) : "") +
              " ～ " +
              (this.$dayjs(chooseData.values.toDate).isValid()
                ? this.$dayjs(chooseData.values.toDate).format("YYYY/MM/DD")
                : "") +
              (chooseData.values.toHour ? " " + ("00" + chooseData.values.toHour.toString()).slice(-2) : "") +
              (chooseData.values.toMinute ? ":" + ("00" + chooseData.values.toMinute.toString()).slice(-2) : "")
            let chipData = {
              itemTitle: fieldData?.fieldName,
              valueTitle: valueTitle,
              fieldId: fieldData?.fieldId,
              valueId: null,
              fieldType: fieldData?.fieldType,
              isIncludeNull: false,
            }
            chipsData.push(chipData)
          }
        } else if (Functions.isTextField(fieldData?.fieldType)) {
          if (chooseData.values?.[0]?.trim()) {
            // テキスト入力項目が空の場合、chip表示対象外
            const convertingTable = {
              0: this.$i18n.t("form.mailformReports.dialog.filtering.label.contains"),
              1: this.$i18n.t("form.mailformReports.dialog.filtering.label.beginningWith"),
            }
            let valueTitle = chooseData.values[0] + " " + convertingTable[chooseData.operators]
            let chipData = {
              itemTitle: fieldData?.fieldName,
              valueTitle: valueTitle,
              fieldId: fieldData?.fieldId,
              valueId: null,
              fieldType: fieldData?.fieldType,
              isIncludeNull: false,
            }
            chipsData.push(chipData)
          }
        }
        if (chooseData.isIncludeNull) {
          // 未入力は個別のチップで表示
          let valueTitle = this.$i18n.t("form.mailformReports.dialog.filtering.label.notInput")
          let chipData = {
            itemTitle: fieldData?.fieldName,
            valueTitle: valueTitle,
            fieldId: fieldData?.fieldId,
            valueId: valueTitle,
            fieldType: fieldData?.fieldType,
            isIncludeNull: chooseData.isIncludeNull,
          }
          chipsData.push(chipData)
        }
      })
      return chipsData
    },
  },
  methods: {
    /**
     * 絞り込み情報チップを閉じた時の処理。
     * 一時情報から該当情報を削除する。
     * @param {string} [fieldId] 該当チップの絞り込み項目の識別子
     * @param {string} [value] 該当チップの絞り込み入力情報
     * @param {string} [fieldType] 該当チップの絞り込み項目のフィールドタイプ
     * @param {string} [isIncludeNull] 該当チップの入力情報（未入力）
     */
    closeChip(fieldId, value, fieldType, isIncludeNull) {
      let orgChooseData = this.filteringCondition.find(chooseData => {
        return chooseData.id === fieldId
      })
      let indexOf = this.filteringCondition.indexOf(orgChooseData)
      if (indexOf < 0) {
        // 一時情報がなければ（削除済み）何もしない
        return
      }
      let chooseData = JSON.parse(JSON.stringify(orgChooseData))
      if (isIncludeNull) {
        // 未入力は個別チップなので個別処理
        chooseData.isIncludeNull = false
      } else {
        if (Functions.isMultiCheckField(fieldType)) {
          // マルチチェック検索項目の場合、削除対象を除外する
          chooseData.values.splice(chooseData.values.indexOf(value), 1)
        } else if (Functions.isNumericRangeField(fieldType)) {
          chooseData.values = null
        } else if (Functions.isPeriodRangeField(fieldType)) {
          chooseData.values = null
        } else if (Functions.isTextField(fieldType)) {
          chooseData.values = null
          chooseData.operators = "0"
        }
      }

      // 不要になったら(未入力も入力値も無い場合)、fieldIdをkeyに一時情報の要素自体を削除する。不要でない場合は更新する。
      let existingChooseFilter =
        chooseData.isIncludeNull || (chooseData.values != null ? Object.keys(chooseData.values).length > 0 : false)
      this.$emit("removeChip", indexOf, 1, chooseData, existingChooseFilter)
    },
  },
}
</script>
<style lang="scss" scoped>
.rotate-icon {
  -moz-transform: rotate(90deg); /* Firefox用 */
  -webkit-transform: rotate(90deg); /* Chrome,Safari,新しいOpera用 */
  -o-transform: rotate(90deg); /* 古いOpera用 */
  -ms-transform: rotate(90deg); /* IE9用 */
  transform: rotate(90deg); /* CSS3の書き方 */
}

.link-btn {
  padding: 0 !important;
  min-width: 0;
}
</style>
